import { Icon } from '@wix/answers-ui-libby';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';


export interface DisabledHelpcenterNotificationProps extends WithTranslation {
  settingsUrl: string;
}

export const AGENT_DISABLED_NOTIFICATION_HEIGHT = 46;

const notificationIcon = `<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8.5" cy="8.5" r="8.5" fill="#FB7D33"/>
<circle cx="8.5" cy="8.5" r="8" stroke="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7 4C7 3.44772 7.44772 3 8 3H9C9.55228 3 10 3.44772 10 4V8.5C10 9.32843 9.32843 10 8.5 10C7.67157 10 7 9.32843 7 8.5V4ZM7 12.5C7 11.6716 7.67157 11 8.5 11C9.32843 11 10 11.6716 10 12.5C10 13.3284 9.32843 14 8.5 14C7.67157 14 7 13.3284 7 12.5Z" fill="white"/>
</svg>
`

const arrowIcon = `<svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.50009 4.5H10V4.99965C10 4.99942 10 4.99988 10 4.99965V9.5C10 9.77614 9.77614 10 9.5 10C9.22386 10 9 9.77614 9 9.5V6.20711L3.85355 11.3536C3.65829 11.5488 3.34171 11.5488 3.14645 11.3536C2.95118 11.1583 2.95118 10.8417 3.14645 10.6464L8.29289 5.5H5C4.72386 5.5 4.5 5.27614 4.5 5C4.5 4.72386 4.72386 4.5 5 4.5H9.50009C9.50014 4.5 9.50003 4.5 9.50009 4.5Z" fill="#3899EC"/>
</svg>
`

export const _DisabledHelpcenterNotification: React.FC<DisabledHelpcenterNotificationProps> = (
  props: DisabledHelpcenterNotificationProps,
) => {
  const helpcenterLink = (
    <a target='_blank' className='helpcenter-link' href={`https://${props.settingsUrl}/app/settings/helpcenter`}>
      {props.t('helpcenter-meneger-link')}
      <Icon icon={arrowIcon}/>
    </a>
  )
 
  return (
    <div className='disabled-helpcneter-agent-notification'>
      <Icon className='notification-icon' icon={notificationIcon} />
      <div className='notification-message'>
        {props.t('disabled-helpcenter-agent-notification')}
        {helpcenterLink}
      </div>
    </div>
  );
};

export const DisabledHelpcenterNotification = withTranslation()(_DisabledHelpcenterNotification);
