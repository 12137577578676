import {
  SectionBlueprints,
  ComponentTypes,
  TemplateFromBlueprint,
  BlueprintsExtraTypes,
} from '../../../../common/helpcenter-types';
import * as ejs from 'ejs';
import {
  HelpcenterStructure,
  HelpcenterBrandingSettings,
  HelpcenterSection,
  BaseSectionData,
  HelpcenterSectionType,
  helpcenterSectionBuilder,
  PageStructure,
  HelpcenterPageSectionTypes,
  baseSegmentBuilder,
} from '@wix/answers-api';
import { HelpcenterComponent } from '../../../../server/style-resolver/utils';

const _ENCODE_HTML_RULES = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&#34;',
  "'": '&#39;',
};
const _MATCH_HTML = /[&<>'"]/g;

const encode_char = (c: string) => {
  return _ENCODE_HTML_RULES[c] || c;
};
const escapeXML = (markup: string) => {
  return !markup ? '' : String(markup).replace(_MATCH_HTML, encode_char);
};

export function templateCreator(
  segment: BaseSectionData<any> | HelpcenterComponent,
  blueprints: SectionBlueprints,
  branding: HelpcenterBrandingSettings,
): TemplateFromBlueprint {
  try {
    const templateFunc = ejs.compile(blueprints[segment.type].template, {
      client: true,
    });

    const clientIncludeResolver: ejs.IncludeCallback = (path, data) => {
      return ejs.render(blueprints[path].template, {
        config: segment.config[data?.config],
        branding,
      });
    };

    let config = {
      ...segment.config,
    };

    try {
      config = {
        ...helpcenterSectionBuilder(segment.type as any).config,
        ...config,
      };
    } catch (e) {}
    const model = { config, branding };

    const template = templateFunc(model, escapeXML, clientIncludeResolver);
    return { type: 'rendered-blueprint', template };
  } catch (error) {
    console.error(
      'Error compiling blueprint to template:',
      segment.type,
      error,
    );
    return {
      type: 'rendered-blueprint',
      template: '<div>Error creating template from blueprint</div>',
    };
  }
}

export function resolvePageTemplate<T extends keyof HelpcenterPageSectionTypes>(
  page: PageStructure<T>,
  blueprints: SectionBlueprints,
  branding: HelpcenterBrandingSettings,
): { [key: string]: TemplateFromBlueprint } {
  return page.sections.reduce<Record<string, TemplateFromBlueprint>>(
    (acc, section) => {
      acc[section.id] = templateCreator(section, blueprints, branding);
      return acc;
    },
    {},
  );
}

export function resolveAllTemplates(
  helpcenterStructure: HelpcenterStructure,
  blueprints: SectionBlueprints,
  branding: HelpcenterBrandingSettings,
): {
  [key in
    | HelpcenterSectionType
    | ComponentTypes
    | BlueprintsExtraTypes]: TemplateFromBlueprint;
} {
  const templates: {
    [key in
      | HelpcenterSectionType
      | ComponentTypes
      | BlueprintsExtraTypes]?: TemplateFromBlueprint;
  } = {};

  Object.values(helpcenterStructure.pages).forEach((page) => {
    Object.assign(templates, resolvePageTemplate(page, blueprints, branding));
  });

  const footer: HelpcenterComponent = {
    ...helpcenterStructure.footer,
    type: ComponentTypes.Footer,
  };
  const header: HelpcenterComponent = {
    ...helpcenterStructure.header,
    type: ComponentTypes.Header,
  };

  const navSideBar: HelpcenterComponent = {
    ...baseSegmentBuilder(),
    config: {},
    type: ComponentTypes.NavSidebar,
  };

  [header, footer, navSideBar].forEach((component) => {
    templates[component.type] = templateCreator(
      component,
      blueprints,
      branding,
    );
  });

  return templates as {
    [key in
      | HelpcenterSectionType
      | ComponentTypes
      | BlueprintsExtraTypes]: TemplateFromBlueprint;
  };
}
