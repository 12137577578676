import { CategoryArticles } from './../../../../server/rpc/types/response-dto';
import { deepSearch } from '../../../../common/utils';
import { Category, Article, CoreErrorSectionConfig, CompanyDisplayInfo, CustomerPortalFieldType, ExtendedTicket, CustomField } from '@wix/answers-api';
import { prettyTimestamp, TranslateFn } from '@wix/answers-lib';
import { getContactOptions } from '../../../../common/contact-utils';

export function utils(model: Record<string, any>, parser: any) {
  return {
    getCategoryForArticleId: (category: string | Category): Category => {
      const categoryId = typeof category === 'string' ? category : category.id;
      return deepSearch<any, any>(
        model.categoryTree,
        'children',
        (item) => item.id === categoryId,
      );
    },
    calcReadTime: (article: Article): string => {
      try {
        const div = document.createElement('div');
        div.innerHTML = article.content;
        const textContent = div.innerText;
        const words = countWords(textContent);
        return wordNumToMinutes(words).toString();
      } catch (err) {
        const textContent = (parser as CheerioAPI)
          .load(`<html>${article.content}</html>`)('html')
          .text();
        const words = countWords(textContent);
        return wordNumToMinutes(words).toString();
      }
    },
    escapeSearchTermEvaluation: (str: string) => {
      return str.replace(/[{}<>]/g, (i) => { return '&#' + i.charCodeAt(0) + ';' });
    },
    getFormattedDate: (date: number) => formatDate(date, model.locale),
    getFormattedDateFromNow: (date: number) => prettyTimestamp(date, true),
    getLastTicketUpdateDate: (ticket: ExtendedTicket, showPrefix: boolean, t: TranslateFn) => {
      const lastUpdateData = Object.entries({
        lastOpenedDate: ticket.lastOpenedDate,
        lastSolvedDate: ticket.lastSolvedDate || 0,
        lastAgentReplyDate: ticket.lastAgentReplyDate || 0,
      }).sort(([_, valA], [__, valB]) => valB - valA)[0];

      const getPrefix = (title: string) => {
        switch (title) {
          case 'lastOpenedDate':
            return t('time-format.last-opened-date');
          case 'lastSolvedDate':
            return t('time-format.last-solved-date');
          case 'lastAgentReplyDate':
            return t('time-format.last-agent-reply-date');
          default:
            return '';
        }
      };
      const updateType = lastUpdateData[0];
      const updateDate = lastUpdateData[1];
      return `${showPrefix ? getPrefix(updateType) : ''}${prettyTimestamp(updateDate, true)}`;
    },
    getContactOptions: () => getContactOptions(model.tenant),
    getTopArticles: (categoryId: string) =>
      (model.categoryArticles as CategoryArticles[])?.find(
        (category: { categoryId: string; articles: any[] }) =>
          category.categoryId === categoryId,
      )?.articles || [],
    getRootCategoriesArticles: (categoryId: string) =>
      (model.rootCategoriesArticles as CategoryArticles[])?.find(
        (category: { categoryId: string; articles: any[] }) =>
          category.categoryId === categoryId,
      )?.articles || [],
    getSubcategoryArticles: (subcategoryId: string) =>
      ((model.subcategoryArticles || []) as CategoryArticles[])?.find(
        (category: { categoryId: string; articles: any[] }) =>
          category.categoryId === subcategoryId,
      )?.articles || [],

    getNextArticleUri: (article: Article): string | false => {
      const categoryArticles: Article[] = model.parentCategoryArticles.sort(
        (a, b) => a.type - b.type,
      );
      const index = categoryArticles.findIndex(({ id }) => id === article.id);

      if (
        categoryArticles &&
        index !== undefined &&
        index < categoryArticles.length - 1
      ) {
        return categoryArticles[index + 1].uri;
      }
      return false;
    },
    getPreviousArticleUri: (article: Article): string | false => {
      const categoryArticles: Article[] = model.parentCategoryArticles.sort(
        (a, b) => a.type - b.type,
      );
      const index = categoryArticles.findIndex(({ id }) => id === article.id);

      if (categoryArticles && index !== undefined && index > 0) {
        return categoryArticles[index - 1].uri;
      }
      return false;
    },
    getErrorPageDefaults: (): CoreErrorSectionConfig =>
      getErrorPageDefaults(model.errorType, '', model.tenant && !model.tenant.helpCenterSettings.enabled, model.tenant && !model.tenant.helpCenterSettings.ticketsPageEnabled, model.customerPortalEnabled),
    getCompanyName: (companyId: string): CompanyDisplayInfo | undefined =>
      model.companies.find(company => company.id === companyId)?.name,
    CustomerPortalFieldType,
    getShownCustomFields: (ticketInfoCustomFields: string[]) => {
      if (!model.customFields || !model.ticket.customFields) {
        return [];
      }
      const getCustomFieldEntry = (id: string) => {
        const customField = model.customFields.find((cf: CustomField) => cf.id === id) as CustomField;
        return [customField.displayName, model.ticket.customFields[customField.name]]
      }
      const hasCustomFieldValue = (id: string) => {
        const customField = model.customFields.find((cf: CustomField) => cf.id === id);
        return !!customField && Object.keys(model.ticket.customFields).includes(customField.name)
      }
      return ticketInfoCustomFields
        .filter(hasCustomFieldValue)
        .map(getCustomFieldEntry);
    },
	bob: (val: string) => val
  };
}

export function getErrorPageDefaults(
  errorType: number,
  locale: string = '',
  isDisabledBrand?: boolean,
  isTicketingDisabled: boolean = false,
  customerPortalEnabled: boolean = false,
): CoreErrorSectionConfig {
  const title = isDisabledBrand ? 'brand-disabled-error-title' : `error-${errorType}.title`;
  const subtitle = isDisabledBrand && isTicketingDisabled && customerPortalEnabled ? 'error-404.subtitle-no-hc' :
    isDisabledBrand ? 'brand-disabled-error-subtitle' : `error-${errorType}.subtitle`;
  const ctaTitle = isDisabledBrand ? '' : `error-${errorType}.cta-title`;
  const uri = isDisabledBrand ? '' : `/${locale}`;
  return {
    title,
    showSubtitle: true,
    subtitle,
    callToAction: {
      title: ctaTitle,
      uri,
    },
  };
}

function countWords(str: string): number {
  return str.trim().split(/\s+/).length;
}

function wordNumToMinutes(numberOfWords: number): number {
  const WORDS_PER_MINUTE = 200;
  return Math.ceil(numberOfWords / WORDS_PER_MINUTE) || 1;
}
export function formatDate(date: number, locale: string): string {
  return new Date(date).toLocaleDateString(locale, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
}
