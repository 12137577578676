import {
  create,
  IStartInteractionOptions,
  IEndInteractionOptions,
} from '@wix/fedops-logger';
import { HelpcenterPageType } from '@wix/answers-api';

const logger = create('answers-helpcenter-v2');

export default logger;

export const useFedopsLogger = (() => {
  let runningInteraction: null | string = null;

  return () => {
    return {
      startInteraction: (
        interaction:
          | FedopsInteractions
          | {
              interactionType: FedopsInteractions;
              pageType: HelpcenterPageType;
            },
        opts?: Partial<IStartInteractionOptions>,
      ) => {
        const interactionName =
          typeof interaction === 'string'
            ? interaction
            : `${interaction.pageType}_${interaction.interactionType}`;
        // console.log('starting interaction', interactionName);
        logger.interactionStarted(interactionName, opts);
        runningInteraction = interactionName;
      },
      endInteraction: (
        interaction: FedopsInteractions,
        opts?: Partial<IEndInteractionOptions>,
      ) => logger.interactionEnded(interaction, opts),
      maybeEndRunningInteraction: () => {
        if (runningInteraction) {
          // console.log('ending interaction', runningInteraction);
          logger.interactionEnded(runningInteraction);
          runningInteraction = null;
        }
      },
      hasRunningInteraction: () => !!runningInteraction,
    };
  };
})();

export type FedopsInteractions =
  | 'article_search'
  | 'ticket_submit'
  | 'category_navigation'
  | 'article_navigation'
  | 'page_load';
