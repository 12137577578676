import { Article, BaseSegment, HelpcenterSectionType } from '@wix/answers-api';
import { TemplateCompiler } from '../../run-time/template-compiler';
import { HelpcenterContext } from '../../../helpcenter-context';
import {
  ComponentTypes,
  SectionModel,
  TemplateFromBlueprint,
} from '../../../../common/helpcenter-types';
import { helpcenterApi } from '../../../../api';
import { Pagination as PaginationComp } from '../../../components-for-sections';
import * as React from 'react';

export interface SearchResultsTemplateCompilerProps {
  sectionTemplate: TemplateFromBlueprint;
  model: SectionModel;
  baseSegment: BaseSegment;
  segmentConfig: any;
  segmentType: HelpcenterSectionType | ComponentTypes;
}

export interface SearchResultsTemplateCompilerState {
  articles?: Article[];
  numPages: number;
  currentPage: number;
  loading: boolean;
  itemsCount: number;
  searchTerm: string;
  brandId: string;
  locale: string;
}

export const SEARCH_RES_PAGE_SIZE = 20;
export class SearchResultsTemplateCompiler extends React.PureComponent<
  SearchResultsTemplateCompilerProps,
  SearchResultsTemplateCompilerState
> {
  static contextType = HelpcenterContext;
  context!: React.ContextType<typeof HelpcenterContext>;

  numPages = Math.ceil((this.props.model?.itemsCount || 1) / SEARCH_RES_PAGE_SIZE);

  state: SearchResultsTemplateCompilerState = {
    articles: this.props.model?.articles,
    numPages: this.numPages,
    currentPage: (this.props.model as any).search.page || 1,
    loading: false,
    itemsCount: this.props.model?.itemsCount || 0,
    searchTerm: (this.props.model as any).search.term || '',
    brandId: this.props.model?.tenant?.brandId || '',
    locale: this.props.model?.tenant?.currentLocale || '',
  };

  onPageChange = (page: number) => {
    const { searchTerm, brandId, locale } = this.state;
    const url = new URL(window.location.href);
    url.searchParams.set('page', page.toString());
    (window as any).history.pushState(null, null, url);
    window.scrollTo({ top: 0 });
    this.setState({ loading: true }, () => {
      return helpcenterApi
        .searchArticles(
          {
            brandId,
            locale,
            text: searchTerm,
            page: page,
            pageSize: SEARCH_RES_PAGE_SIZE,
            spellcheck: true
          },
        )
        .then((articleList) => {
          this.setState({
            articles: articleList.data,
            currentPage: page,
            loading: false
          })
        });
    });
  };

  render() {
    const Pagination = () => {
      return (
        <PaginationComp
          numPages={this.state.numPages}
          page={this.state.currentPage}
          onClick={this.onPageChange}
        />
      );
    };
    return (
      <TemplateCompiler
        sectionTemplate={this.props.sectionTemplate}
        baseSegment={this.props.baseSegment}
        model={{
          ...this.props.model,
          articles: this.state.articles,
          loading: this.state.loading,
          itemsCount: this.state.itemsCount,
          numPages: this.state.numPages,
        }}
        components={{
          Pagination,
        }}
        segmentConfig={this.props.segmentConfig}
        segmentType={this.props.segmentType}
      />
    );
  }
}
