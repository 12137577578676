import {
  Article,
  ArticleType,
  VoteFeedbackMode,
  HelpcenterPageType,
  ContactMethodType,
} from '@wix/answers-api';
import * as React from 'react';
import * as serdes from '@wix/answers-article-html-serdes';
import { BiActivityType, ComponentsViewer, UserEngagementPinger } from '@wix/answers-common-components';
import { HelpcenterContext } from '../../helpcenter-context';
import { isInternalLink, deepSearch, getArticleTextContent, getUrlWithKb } from '../../../common/utils';
import { AnchorNavigation } from './anchor-navigation';
import { ArticleFeedback } from './article-feedback';
import { WithTranslation, withTranslation } from 'react-i18next';
import { helpcenterApi } from '../../../api';
import { History } from 'history';
import Url from 'url-parse';
import { Helmet } from 'react-helmet';
import { contactUrl } from '../nav-links/contact-link';
import { getContactOptions } from '../../../common/contact-utils';
import { seoManager } from '../../utils/seo-manager';
import { AGENT_DISABLED_NOTIFICATION_HEIGHT } from '../../utils/disabled-helpcenter-notification';
import { BiLogger } from '../../routes/bi';
import { publicUserArticleEngagementPing, publicVoteForArticle, publicVoteForFeatureRequest, publicVoteForKnownIssue } from '@wix/bi-logger-wix-answers/v2';

export const articleViewKey = 'article-view-container';
// tslint:disable-next-line: deprecation
const AnswersComponentViewer: any = ComponentsViewer;
const FEEDBACK_STORAGE_KEY = 'articleFeedback';
const scrollSpaceFromTop = 15; // was randomly chosen

export const PING_INTERVAL = 15000;
const URL_TABS_SEPARATOR = '?tabs=';
const URL_HEADING_SEPARATOR = ',';
const isSSR = () => typeof window === 'undefined';

export enum HelpfulState {
  NOT_HELPFUL = -1,
  NONE = 0,
  HELPFUL = 1,
}

export interface Feedback {
  allowFeedback: boolean;
  feedbackMessage: string;
  voteFeedbackMode: VoteFeedbackMode;
  contactMessage: string;
  contactActionButtonText: string;
}

export interface ArticleViewProps {
  article: Article;
  tenant: any;
  history: History<any>;
  feedbackConfig: Feedback;
  biLogger: BiLogger;
  submitTicketsEnabled: boolean;
  contactOptionDisabled: boolean;
}

interface ArticleViewState {
  selectedAnchorId: string;
  components?: any;
  feedback: HelpfulState;
}
class ArticleView extends React.PureComponent<
  ArticleViewProps & WithTranslation,
  ArticleViewState
> {
  // static whyDidYouRender = true;
  static contextType = HelpcenterContext;
  context!: React.ContextType<typeof HelpcenterContext>;
  articleView: React.RefObject<HTMLDivElement>;

  constructor(props: ArticleViewProps & WithTranslation, context: any) {
    super(props);

    const components = serdes.deserialize(
      this.props.article.content,
      this.props.article.locale,
      context?.Parser,
    );

    this.state = {
      selectedAnchorId: '',
      components,
      feedback: HelpfulState.NONE,
    };

    this.onWindowScroll = this.onWindowScroll.bind(this);

    this.articleView = React.createRef<HTMLDivElement>();
  }

  contentElem: any = null;

  getStickyElementsHeight = () => {
    const header =
      document.querySelector('.header') ||
      document.querySelector('.ans-header');
    const headerHeight = header ? header.clientHeight : 0;
    const breadcrumbs = document.querySelector('.breadcrumbs');
    const disabledAgentNotification = document.querySelector('.disabled-helpcneter-agent-notification') ? AGENT_DISABLED_NOTIFICATION_HEIGHT : 0;
    const breadcrumbsHeight = breadcrumbs ? breadcrumbs.clientHeight : 0;
    return headerHeight + breadcrumbsHeight + disabledAgentNotification;
  };

  componentDidMount() {
    const { id } = this.props.article;

    const components = serdes.deserialize(
      this.props.article.content,
      this.props.article.locale,
      this.context.Parser,
    );
    this.setState({ components });

    window.addEventListener('scroll', this.onWindowScroll, true);
    let feedbackString: string | undefined;
    try {
      feedbackString = localStorage.getItem(FEEDBACK_STORAGE_KEY) || undefined;
    } catch (err) { }
    const feedbackFromStorage = feedbackString && JSON.parse(feedbackString);
    const feedback =
      feedbackFromStorage && feedbackFromStorage[id]
        ? feedbackFromStorage[id] === 1
          ? HelpfulState.HELPFUL
          : HelpfulState.NOT_HELPFUL
        : HelpfulState.NONE;

    this.setArticleViewWidth();
    this.setState({ feedback });
    setTimeout(() => {
      const stickyHeight = this.getStickyElementsHeight();
      const AnchorOnView = window.location.href.split('#');

      if (AnchorOnView[1] && document.getElementById(AnchorOnView[1])) {
        const anchorTop: number =
          document.getElementById(AnchorOnView[1])?.getBoundingClientRect()
            .top || 0;
        window.scrollTo({
          top:
            anchorTop +
            window.pageYOffset -
            (stickyHeight + scrollSpaceFromTop),
          behavior: 'smooth',
        });
      }
    }, 400);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onWindowScroll, true);
  }

  onChangeAnchor = (selectedAnchorId: string) => {
    this.setState({ selectedAnchorId });

    const { pathname, search } = document.location;
    const stickyHeights = this.getStickyElementsHeight();
    window.history.pushState({}, '', pathname + search + `#${selectedAnchorId}`);
    const selectedAnchorTop =
      document.getElementById(selectedAnchorId)?.getBoundingClientRect().top ||
      0;
    window.scrollTo({
      top:
        selectedAnchorTop +
        window.pageYOffset -
        (stickyHeights + scrollSpaceFromTop),
      behavior: 'smooth',
    });
  };

  setLocalStorage = (feedback: HelpfulState) => {
    const { id } = this.props.article;
    let feedbackFromStorage: string | undefined;
    try {
      feedbackFromStorage =
        localStorage.getItem(FEEDBACK_STORAGE_KEY) || undefined;
    } catch (err) {}

    const currentStorage =
      feedbackFromStorage && JSON.parse(feedbackFromStorage);
    const newFeedback = currentStorage ? currentStorage : {};
    newFeedback[id] = feedback;
    try {
      localStorage.setItem(FEEDBACK_STORAGE_KEY, JSON.stringify(newFeedback));
    } catch (err) {}
  };
  handleArticleVoteLogging = (
    type: ArticleType,
    feedback: HelpfulState,
  ): Promise<void> => {
    const { biLogger, article } = this.props;
    const biEventData = {
      article_id: article.id,
      category_id: article.categoryId,
      thumbs_up: feedback === HelpfulState.HELPFUL,
      answers_brand_id: this.props.tenant.brandId
    };
    switch (type) {
      case ArticleType.ARTICLE:
        return biLogger.report(publicVoteForArticle({ ...biEventData }));
      case ArticleType.FEATURE_REQUEST:
        return biLogger.report(publicVoteForFeatureRequest({ ...biEventData }));
      case ArticleType.KNOWN_ISSUE:
        return biLogger.report(publicVoteForKnownIssue({ ...biEventData }));
      default:
        return Promise.resolve();
    }
  };
  onChangeFeedback = async (feedback: HelpfulState) => {
    const { article, tenant } = this.props;
    if (tenant && feedback !== this.state.feedback) {
      this.setLocalStorage(feedback);
      await this.handleArticleVoteLogging(article.type, feedback);
      switch (article.type) {
        case ArticleType.KNOWN_ISSUE:
        case ArticleType.FEATURE_REQUEST:
          const data = {
            locale: article.locale,
            sourceType: 0,
            sourceId: null,
          };
          this.setState({ feedback });
          return feedback !== HelpfulState.NONE
            ? helpcenterApi.articleVoteForFeature(article.id, data)
            : helpcenterApi.articleUnvote(article.id, data);
        case ArticleType.ARTICLE:
          const params = {
            newState: feedback,
            state: this.state.feedback,
            locale: article.locale,
            sourceType: 0,
            sourceId: null,
          };
          this.setState({ feedback });
          return helpcenterApi.sendArticleFeedback(article.id, params);
        default:
          this.setState({ feedback });
      }
    }
  };

  onImageLoad(imgElement: HTMLImageElement) {
    const imgWidth = imgElement.naturalWidth;
    const widgetWidht = window.innerWidth;
    if (imgWidth > widgetWidht) {
      imgElement.onclick = (e: any) => {
        const src = imgElement.getAttribute('src') || '';
        if (parent) {
          e.stopPropagation();
          parent.postMessage({ imageSrc: src }, '*');
        }
      };
    }
  }

  imageManipulation() {
    const contentElem = document.getElementById('article-content');
    const imgs = contentElem
      ? Array.prototype.slice.call(contentElem.getElementsByTagName('img'))
      : [];
    if (imgs && imgs.forEach) {
      imgs.forEach((imgElement: HTMLImageElement) => {
        imgElement.onload = () => this.onImageLoad(imgElement);
      });
    }
  }

  onWindowScroll = () => {
    const { components } = this.state;
    const anchors = components
      .filter(
        (comp) => comp.type === 'heading' && comp.value && comp.value.anchorId,
      )
      .map((comp) => document.querySelector(`[id="${comp.value.anchorId}"]`));
    const anchorsOnView = anchors.filter((curr) => {
      return curr.offsetTop - window.scrollY <= -120;
    });
    if (anchorsOnView.length) {
      const currentAnchorId = anchorsOnView[
        anchorsOnView.length - 1
      ].getAttribute('id');
      if (currentAnchorId && currentAnchorId !== this.state.selectedAnchorId) {
        this.setState({ selectedAnchorId: currentAnchorId });
      }
    }
  };

  onLinkClick = (e: MouseEvent, path: string) => {
    const { tenant } = this.props;
    if (
      (e.currentTarget as HTMLAnchorElement).target === '_blank' ||
      e.ctrlKey ||
      e.metaKey
    ) {
      return;
    }
    if (isInternalLink(path, tenant.helpCenterSettings.domain.name)) {
      e.preventDefault();
      const url = new Url(path);
      const hash = url.hash || '';
      const cleanPath = url.pathname.replace(/^\/kb/, '');
      this.props.history.push(cleanPath + hash);
    }
  };
  getAllOtherTabSlugs = (search: string, currentTabSlugs: string[]) => {
	const maybeTabsSlugs = search.split(URL_TABS_SEPARATOR);
	if (maybeTabsSlugs[1]) {
		const unrelatedSlugs = maybeTabsSlugs[1].split(URL_HEADING_SEPARATOR)
			.map(slug => decodeURI(slug))
			.filter(slug =>
				!currentTabSlugs.includes(slug));
		return unrelatedSlugs.length ? unrelatedSlugs.join(URL_HEADING_SEPARATOR) + URL_HEADING_SEPARATOR : '';
	}
	return '';
};
  onTabClick = (selectedTab: number, currentTabSlugs: string[]) => {
    if (!isSSR()) {
      const { pathname, search, hash } = document.location;
      if (currentTabSlugs.length) {
        const allUnrelatedSlugsInURL = this.getAllOtherTabSlugs(search, currentTabSlugs);
        const selectedTabURLSlug = currentTabSlugs[selectedTab];

        const newURLState = pathname + URL_TABS_SEPARATOR + allUnrelatedSlugsInURL +
          selectedTabURLSlug + hash;
        window.history.pushState({}, '', newURLState);
      }
    }
  };

  maybeGetSelectedTabsFromURL = () => {
    if (!isSSR()) {
      const { search } = document.location;
      const maybeURLTabs = search.split(URL_TABS_SEPARATOR);
      if (maybeURLTabs.length > 1) {
        return maybeURLTabs[1].split(URL_HEADING_SEPARATOR).map(slug => decodeURI(slug));
      }
      else return [];
    }
    return [];
  };

  setArticleViewWidth = () => {
    const elem = this.articleView.current;
    const title = document.querySelector('.article-page-title');
    if (title && elem) {
      elem.style.minWidth = `${title.clientWidth}px`;
    }
  };
  getContactUrl = () => {
    const contactOption = getContactOptions(this.props.tenant);
    return contactUrl(contactOption);
  };
  isContactMethodInternal = getContactOptions(this.props.tenant).contactMethod.type === ContactMethodType.INTERNAL;
  getCategoryById = (categoryId: string) =>
    deepSearch<any, any>(this.context.helpcenter.categoryTree, 'children', (cat) => cat.id === categoryId);
  seoArticlePageTags = () => {
    const { article } = this.props;
    const articleCategory = this.getCategoryById(article.categoryId);
    const articleParentCategory = articleCategory?.parentId && this.getCategoryById(articleCategory.parentId);
    const articlePreview = getArticleTextContent(article);
    const placeholders = {
      CANONICAL_URL: getUrlWithKb(article.url, article.locale),
      ARTICLE_TITLE: article.title,
      ARTICLE_PREVIEW: articlePreview,
      CATEGORY_TITLE: articleCategory?.name || '',
      PARENT_CATEGORY_TITLE: articleParentCategory?.name || this.props.t('breadcrumb.all-topics'),
    };
    seoManager.setPage(HelpcenterPageType.ARTICLE).setPlaceholders(placeholders).setDefaultTitle(article.title).setDefaultDescription(articlePreview);
    const seo = seoManager.getTags();
    return (
      <Helmet>
          <title>{seo.metaTitle ? seo.metaTitle : article.title}</title>
          {seo.metaDescription && <meta name="description" content={seo.metaDescription} />}
          {seo.metaRobots && <meta name="robots" content={seo.metaRobots} />}
          <meta name='og:title' content={article.title}/>
          <link rel="canonical" href={placeholders.CANONICAL_URL} />
      </Helmet>
    );
  }
  onPing = (loggedActivities: BiActivityType[]) => {
    const params = {
      activity_type: loggedActivities.length ? loggedActivities.sort().join() : '',
      article_id: this.props.article.id,
      article_name: this.props.article.title,
      answers_brand_id: this.props.tenant.brandId,
    }
    return loggedActivities.length && this.props.biLogger.report(publicUserArticleEngagementPing(params));
  }
  render() {
    const { id } = this.props.article;
    const anchors = this.state.components?.filter(
      (comp) => comp.type === 'heading' && comp.value && comp.value.anchorId,
    );
    return (
      <div className={articleViewKey}>
        {this.seoArticlePageTags()}
        <div ref={this.articleView} className="article-view">
          <div
            className="content"
            id="article-content"
            ref={(ref) => {
              this.contentElem = ref;
            }}
          >
            <AnswersComponentViewer
              components={this.state.components}
              locale={this.props.article.locale}
              onLinkClick={this.onLinkClick}
              tabsProps={{
                onTabClick: this.onTabClick,
                selectedTabsOnLoad: this.maybeGetSelectedTabsFromURL()
              }}
              fontConfig={{ bold: 600 }}
            />
          </div>
          {this.props.feedbackConfig.allowFeedback ? (
            <>
              <div className="divider" />
              <div className="feedback-section-container">
                <ArticleFeedback
                  articleId={this.props.article.id}
                  onChange={this.onChangeFeedback}
                  t={this.props.t}
                  articleType={this.props.article.type}
                  value={this.state.feedback}
                  config={this.props.feedbackConfig}
                  contactUrl={this.getContactUrl()}
                  submitTicketsEnabled={this.props.submitTicketsEnabled}
                  contactOptionDisabled={this.props.contactOptionDisabled}
                  isContactMethodInternal={this.isContactMethodInternal}
                />
              </div>
            </>
          ) : null}
        </div>
        {anchors.length ? (
          <AnchorNavigation
            anchors={anchors}
            currentAnchorId={this.state.selectedAnchorId}
            onChangeAnchor={this.onChangeAnchor}
            t={this.props.t}
          />
        ) : null}
        <UserEngagementPinger onPing={this.onPing} pingInterval={PING_INTERVAL} t={this.props.t} />
      </div>
    );
  }
}
ArticleView.contextType = HelpcenterContext;
export const ArticleViewComp = withTranslation()(ArticleView);
