import { ResolvedCustomField, CustomFieldNameToValueMap, CustomFieldType, ContactFormInitialFieldData } from '@wix/answers-api';

export const isObjectEmpty = (obj: unknown) => JSON.stringify(obj || {}) === '{}';

export const tryParseBool = (raw: string) => {
	return raw === 'true' ? true : raw === 'false' ? false : null;
};

export const tryParseNumber = (raw: string) => {
	const maybeInt = parseInt(raw, 10);
	return !isNaN(maybeInt) ? maybeInt : null;
};

export const parseCustomFieldValue = (rawValue: string, fieldType: CustomFieldType, fieldName: string, customFields: CustomFieldNameToValueMap): CustomFieldNameToValueMap => {
	switch (fieldType) {
		case CustomFieldType.BOOLEAN:
			const maybeBool = tryParseBool(rawValue);
			return maybeBool !== null ? {...customFields, [fieldName]: maybeBool} : customFields;
		case CustomFieldType.NUMBER:
			const maybeNumber = tryParseNumber(rawValue);
			return maybeNumber !== null ? {...customFields, [fieldName]: maybeNumber} : customFields;
		case CustomFieldType.MULTILINE_TEXT:
		case CustomFieldType.TEXT:
		case CustomFieldType.SINGLE_SELECT:
			return {...customFields, [fieldName]: rawValue};
		default:
			return customFields;
	}
};

export const getInitalFieldsFromUrlParams = (query: URLSearchParams, customFields: ResolvedCustomField[]): Partial<ContactFormInitialFieldData> => {
	const customFieldsNameToValueMap: CustomFieldNameToValueMap = customFields.reduce((res, currentField) => {
		const rawValue = query.get(currentField.name);
		return rawValue ? parseCustomFieldValue(rawValue, currentField.type, currentField.name, res) : res;
	}, {});
	const fullname = query.get('fullname') || '';
	const email = query.get('email') || '';
	const title = query.get('title') || '';
	const description = query.get('description') || '';
	const resolvedData = {
		...(fullname ? { fullname } : {}),
		...(email ? { email } : {}),
		...(title ? { title } : {}),
		...(description ? { description } : {}),
		...(!isObjectEmpty(customFieldsNameToValueMap) ? {customFields: customFieldsNameToValueMap} : {}),
	}
	return resolvedData;
}
