import {
  ArticleType,
  BaseSectionData,
  Category,
  HelpcenterBrandingSettings,
  HelpcenterPageNames,
  HelpcenterPageType,
  HelpcenterSectionType,
  HelpcenterStructure,
  PageStructure,
} from '@wix/answers-api';
import { classNames } from '@wix/answers-lib';
import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteConfigComponentProps } from 'react-router-config';
import { helpcenterApi } from '../../api';
import { createCategoryUri } from '../../api/utils';
import { RecoverableError } from '../../common/errors/recoverable-error';
import { ErrorType } from '../../common/errors/error-type';
import {
  PROFILE_PAGE_TYPE,
  HARDCODED_SECTION_TYPES,
  SectionRenderedBlueprints,
  segmentTypeMap,
  TICKET_PAGE_TYPE,
  ExtraPageType,
  ComponentTypes,
  SectionModel,
} from '../../common/helpcenter-types';
import { deepSearch, getBreadCrumbs, getRequestListFilters, getUrlWithKb } from '../../common/utils';
import { HelpcenterContext } from '../helpcenter-context';
import { Breadcrumbs } from '../page-components/breadcrumbs';
import { TemplateCompiler } from '../section-rendering/run-time/template-compiler';
import { FollowedArticlesTemplateCompiler, SearchResultsTemplateCompiler } from '../section-rendering/specific-section-renderer/index';
import { useFedopsLogger } from '../utils/fedops-logger';
import {
  publishOnPageLoadSdkEvent,
  sdkSetContentFiltersEmitter,
  setCurrentUserSdk,
} from '../utils/sdk/index';
import { SdkOnPageLoadEventData, ContentFilters } from '../utils/sdk/domain';
import { seoManager } from '../utils/seo-manager';
import {
  reportCategoryPageView,
  reportClientPageView,
  reportClientPageViewError,
  reportCustomerPortalPageView,
  reportPageViewArticle,
} from './bi';
import { ToolbarActions } from './root-composer';
import {
  articleListSectionDataLoader,
  profileSectionDataLoader,
  ticketListSectionDataLoader,
  ticketSectionDataLoader,
  categoriesSectionsDataLoader,
  customerPortalTicketSectionDataLoader,
  followedArticlesSectionDataLoader
} from './section-data-loaders/client';
import { useCustomCompareEffect, isStructureDataEqual } from './utils';
import { getFilteredContentModel } from './utils/content-filters';
import { BreadcrumbList } from 'schema-dts';
import { helmetJsonLdProp } from 'react-schemaorg';
import { NavSidebar } from '../page-components/sidebar-navigation';
import {
  InjectedExperimentsProps,
  withExperiments,
} from '@wix/wix-experiments-react';
import { CoreRequestListTemplateCompiler } from '../section-rendering/specific-section-renderer/core-request-list/core-request-list-template-compiler';
import { HelpcenterData } from '../../helpcenter-data';
import { SpinnerLoader } from '@wix/answers-ui-libby';
import { Buffer } from 'buffer';

export const SCREENSIZE_TABLET = 1170;

export const encodeServerSearchTerm = (term: string) => Buffer.from(encodeURIComponent(term) || '').toString('base64');
export const decodeServerSearchTerm = (term: string) => decodeURIComponent(Buffer.from(term || '', 'base64').toString());
export const ArticlePageRenderer = ({ route, ...props }: any) => {
  const HCContext = useContext(HelpcenterContext);
  const [articleType, setArticleType] = useState<ArticleType | undefined>(
    HCContext.pageModelData?.article?.type, // for initial page load
  );
  const [loadArticleError, setLoadArticleError] = useState<ErrorType | false>(
    false,
  );

  const { locale, articleUri } = props.match.params;
  const isFirstRun = useRef<boolean>(true);
  const redirectToLogin = () => {
    if (typeof window !== 'undefined' && props.match.params.locale) {
      const tenantUrl = props.initialData.tenant.helpCenterSettings.domain.name;
      return (window.location.href = `https://${tenantUrl}/account/signin?redirectUrl=${encodeURIComponent(
        window?.location?.href,
      )}&locale=${props.match.params.locale}`);
    }
  };
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    setArticleType(undefined);
  }, [articleUri]);

  useEffect(() => {
    if (!articleType) {
      // tslint:disable-next-line: no-floating-promises
      helpcenterApi
        .getArticleByUri(articleUri, {
          params: { locale, brandId: props.initialData.tenant.brandId },
        })
        .then(async (article) => {
          setArticleType(article.type);
        })
        .catch((e) => {
          if (Object.values(ErrorType).includes(e.response?.status)) {
            if (
              e.response?.status === ErrorType.PERMISSION_ERROR ||
              e.response.status === ErrorType.UNAUTHORIZED
            ) {
              redirectToLogin();
            }
            setLoadArticleError(e.response?.status as ErrorType);
          } else {
            setLoadArticleError(ErrorType.SERVER_ERROR);
          }
          console.error(e);
        });
    }
  }, [articleType]);

  let resolvedId;
  let resolvedType;
  let resolvedErrorType;

  if (loadArticleError) {
    resolvedId = HelpcenterPageNames.ERROR;
    resolvedType = HelpcenterPageType.ERROR;
    resolvedErrorType = { errorType: loadArticleError };
  } else {
    switch (articleType) {
      case ArticleType.ARTICLE:
        resolvedId = HelpcenterPageNames.ARTICLE;
        resolvedType = HelpcenterPageType.ARTICLE;
        break;
      case ArticleType.FEATURE_REQUEST:
        resolvedId = HelpcenterPageNames.ARTICLE_FEATURE_REQUEST;
        resolvedType = HelpcenterPageType.ARTICLE_FEATURE_REQUEST;
        break;
      case ArticleType.KNOWN_ISSUE:
        resolvedType = HelpcenterPageType.ARTICLE_KNOWN_ISSUE;
        resolvedId = HelpcenterPageNames.ARTICLE_KNOWN_ISSUE;
        break;
      default:
    }
  }

  return (
    <>
      {articleType || loadArticleError ? (
        <PageContent
          {...props}
          route={{
            ...route,
            id: resolvedId,
            PageType: resolvedType,
          }}
          {...resolvedErrorType}
        />
      ) : (
        <div className="loading-container">
          <div className="loading"><SpinnerLoader color={props.branding.actionsColor}/></div>
        </div>
      )}
    </>
  );
};

const _CategoryPagesRenderer = ({ route, ...props }: any) => {
  const redirectToLogin = () => {
    if (typeof window !== 'undefined' && props.match.params.locale) {
      const tenantUrl = props.initialData.tenant.helpCenterSettings.domain.name;
      window.location.href = `https://${tenantUrl}/account/signin?redirectUrl=${encodeURIComponent(
        window?.location?.href,
      )}&locale=${props.match.params.locale}`;
    }
  };
  const [loadCategoryError, setLoadCategoryError] = useState<ErrorType | false>(
    false,
  );

  const chosenCategory = createCategoryUri(props.match.params);
  const categoryTree = props.initialData.helpcenter.categoryTree;
  const category = deepSearch<Category, any>(
    categoryTree,
    'children',
    (item) => (item.uri as string) === `/${chosenCategory}`,
  );

  let resolved:
    | false
    | HelpcenterPageNames.CATEGORY
    | HelpcenterPageNames.SUB_CATEGORIES = false;
  if (category && !resolved) {
    if (category.children && category.children.length) {
      resolved = HelpcenterPageNames.SUB_CATEGORIES;
    } else {
      resolved = HelpcenterPageNames.CATEGORY;
    }
  }
  useEffect(() => {
    const fetchCategory = async () => {
      const _category = await helpcenterApi.getCategoryByUri(
        props.initialData.tenant.brandId,
        props.match.params,
      );
      if (_category.uri !== `/${createCategoryUri(props.match.params)}`) {
        props.history.replace(`/${props.match.params.locale}${_category.uri}`);
      }
    };
    if (!resolved) {
      fetchCategory().catch((e) => {
        if (Object.values(ErrorType).includes(e.response?.status)) {
          if (
            e.response?.status === ErrorType.PERMISSION_ERROR ||
            e.response.status === ErrorType.UNAUTHORIZED
          ) {
            redirectToLogin();
          }
          setLoadCategoryError(e.response?.status as ErrorType);
        } else {
          setLoadCategoryError(ErrorType.SERVER_ERROR);
        }
        console.error(e);
      });
    }
  }, []);
  // BI category page view
  useEffect(() => {
    if (!loadCategoryError) {
      reportCategoryPageView(
        props.initialData.tenant.id,
        props.match.params.locale,
        category,
        props.initialData.helpcenter.categoryTree,
        props.initialData.helpcenter.sessionUser?.id,
        props.initialData.tenant.brandId
      );
    }
  }, []);
  const categoryPagesSeoTags = () => {
    if (resolved && category) {
      const parentCategory =
        category.parentId &&
        deepSearch<any, any>(
          categoryTree,
          'children',
          (cat) => cat.id === category.parentId,
        );
      const placeholders = {
        CANONICAL_URL: getUrlWithKb(category.url, category.locale),
        CATEGORY_TITLE: category.name,
        PARENT_CATEGORY_TITLE:
          parentCategory?.name || props.t('breadcrumb.all-topics'),
      };
      seoManager
        .setPage(HelpcenterPageType.CATEGORY)
        .setPlaceholders(placeholders)
        .setDefaultTitle(category.name)
        .setDefaultDescription('');
      const seo = seoManager.getTags();
      return (
        <Helmet>
          {<title>{seo.metaTitle ? seo.metaTitle : category?.name}</title>}
          {seo.metaDescription && (
            <meta name="description" content={seo.metaDescription} />
          )}
          {seo.metaRobots && <meta name="robots" content={seo.metaRobots} />}
          <meta name="og:title" content={category?.name} />
          <link rel="canonical" href={placeholders.CANONICAL_URL} />
        </Helmet>
      );
    }
  };
  const errorPageId = HelpcenterPageNames.ERROR;
  const resolvedError = { errorType: loadCategoryError };
  return (
    <>
      {(resolved || loadCategoryError) && (
        <>
          {categoryPagesSeoTags()}
          <PageContent
            {...props}
            route={{
              ...route,
              id: !loadCategoryError ? resolved : errorPageId,
            }}
            {...resolvedError}
          />
        </>
      )}
    </>
  );
};
export const CategoryPagesRenderer = withTranslation()(_CategoryPagesRenderer);
// (CategoryPagesRenderer as any).whyDidYouRender = true;

const locationDataKey = (location: Location) =>
  `${location.pathname}${location.search}`;

interface PageContentProps
  extends RouteConfigComponentProps,
    WithTranslation,
    InjectedExperimentsProps {
  route: any;
  initialData: HelpcenterData;
  match: any;
  templates: SectionRenderedBlueprints;
  location: any;
  baseModel: any;
  branding: HelpcenterBrandingSettings,
  history: any;
  helpcenterStructure: HelpcenterStructure;
  toolbarActions: ToolbarActions;
  key: string;
  modifiedModel: any;
  errorType?: ErrorType;
}

const _PageContent = memo<PageContentProps>(
  ({
    route,
    initialData,
    match,
    templates,
    helpcenterStructure,
    location,
    baseModel,
    toolbarActions,
    history,
    key,
    t,
    modifiedModel,
    branding,
    errorType,
    experiments,
  }) => {
    const initialPageStructure: PageStructure<any> =
      helpcenterStructure.pages[route.id as HelpcenterPageType];
    const [pageStructure, setPageStructure] = useState(initialPageStructure);

    const isErrorPage = pageStructure.type === HelpcenterPageType.ERROR;
    const fedopsLogger = useFedopsLogger();
    console.assert(pageStructure, 'page structure is not defined');
    const [isLoading, setIsLoading] = useState(true);
    const [loadDataError, setLoadDataError] = useState<ErrorType | false>(
      (isErrorPage && errorType) || false,
    );
    const hcContext = useContext(HelpcenterContext);
    const [data, setData] = useState(hcContext[locationDataKey(location)]);
    const [isSidebarOpen, setSidebarOpen] = React.useState<boolean>(false);
    const [isPageReadyForSDK, setPageReadyForSDK] = React.useState<boolean>(
      false,
    );
    const [isMobile, setIsMobile] = useState(false);
    const [sdkContentFilters, setSdkContentFilters] = useState<ContentFilters>()
    const { baseURL } = baseModel;
    const isFirstPageLoad = !!hcContext.pageModelData;
    const notSsr = typeof window !== 'undefined';
    const hasSessionUser = !!hcContext?.helpcenter?.sessionUser;
    const isNotServerLoadedPage = (pageType: HelpcenterPageType): boolean => {
      switch (pageType) {
        case PROFILE_PAGE_TYPE:
        case TICKET_PAGE_TYPE:
        case HelpcenterPageType.REQUEST_PAGE:
        case HelpcenterPageType.REQUEST_LIST:
        case HelpcenterPageType.FOLLOWED_ARTICLES:
        case HelpcenterPageType.SEARCH_RESULTS:
          return true;
        default:
          return false;
      }
    };

    const redirectToLogin = () => {
      if (notSsr && match.params.locale) {
        const tenantUrl = initialData.tenant.helpCenterSettings.domain.name;
        window.location.href = `https://${tenantUrl}/account/signin?redirectUrl=${encodeURIComponent(
          window?.location?.href,
        )}&locale=${match.params.locale}`;
      }
    };
    useEffect(() => {
      setPageStructure(
        helpcenterStructure.pages[route.id as HelpcenterPageType],
      );
    }, [route]);

    const isNotSearchResultsPage = pageStructure.type !== HelpcenterPageType.SEARCH_RESULTS;
    if (isNotServerLoadedPage(pageStructure.type) && isNotSearchResultsPage && !hasSessionUser) {
      redirectToLogin();
    }

    const enableContactPage = initialData?.tenant?.helpCenterSettings?.authentication.enableGuestTickets || !!hcContext?.sessionUser;
    if (pageStructure.type === HelpcenterPageNames.CONTACT && !enableContactPage) {
      redirectToLogin();
    }

    if (isFirstPageLoad) {
      if (!isNotServerLoadedPage(pageStructure.type)) {
        hcContext[locationDataKey(location)] = {
          ...hcContext.helpcenter,
          ...hcContext.pageModelData,
        };
        delete hcContext.pageModelData;
        setData(hcContext[locationDataKey(location)]);
        setIsLoading(false);
      } else {
        delete hcContext.pageModelData;
      }
    }

    if (
      !hcContext[locationDataKey(location)] &&
      isLoading === false &&
      !isErrorPage
    ) {
      setIsLoading(true);
    }

    useCustomCompareEffect(
      () => {
        hcContext[locationDataKey(location)] = undefined;
      },
      pageStructure,
      isStructureDataEqual,
    );
    const cleanSdkContentFiltersListener = sdkSetContentFiltersEmitter.addListener((contentFilters) => setSdkContentFilters(contentFilters));
    useEffect(() => {
      const contentFilters = sdkSetContentFiltersEmitter.getLast();
      if (contentFilters) {
        setSdkContentFilters(contentFilters);
      }
      return cleanSdkContentFiltersListener && cleanSdkContentFiltersListener();
    });

    const customerPortalEnabled = experiments.enabled('specs.EnableCustomerPortal');
    useEffect(() => {
      if (!fedopsLogger.hasRunningInteraction()) {
        fedopsLogger.startInteraction({
          interactionType: 'page_load',
          pageType: pageStructure.type,
        });
      }

      const fetchData = () => {
        if (isErrorPage) {
          if (!loadDataError) {
            setLoadDataError(ErrorType.PAGE_NOT_FOUND);
          }
          setIsLoading(false);
          return Promise.resolve();
        }

        return new Promise<void>(async (res, rej) => {
          if (
            hcContext[locationDataKey(location)] &&
            !isNotServerLoadedPage(pageStructure.type)
          ) {
            setData(hcContext[locationDataKey(location)]);
            setIsLoading(false);
            res();
          } else if (route.loadDataClient) {
            setIsLoading(true);
            setLoadDataError(false);
            try {
              const result = await route.loadDataClient(
                match.params,
                hcContext.tenant,
                location,
                hcContext,
                baseURL,
                sdkContentFilters
              );
              //if first render when route id is updated in category/article page renderer - pageStructure is not updated yet by use effect
              const currentPage =
                helpcenterStructure.pages[route.id as HelpcenterPageType];
              const isCategoriesOrArticlePageFirstRender =
                pageStructure.type !== currentPage.type &&
                pageStructure.type !== HelpcenterPageType.ERROR;
              const { sections } = !isCategoriesOrArticlePageFirstRender
                ? pageStructure
                : currentPage;
              const sectionDataPromises: Promise<any>[] = [];
              const query = new URLSearchParams(window.location.search);
              const isPreview = query.has('preview');
              sections.forEach((section) => {
                switch (section.type) {
                  case HelpcenterSectionType.ARTICLE_LIST:
                    sectionDataPromises.push(
                      articleListSectionDataLoader({
                        section,
                        locale: match.params.locale,
                      }),
                    );
                    break;
                  case HelpcenterSectionType.CATEGORIES:
                  case HelpcenterSectionType.CORE_CATEGORIES:
                    sectionDataPromises.push(
                      categoriesSectionsDataLoader({
                        locale: match.params.locale,
                        categoryTree: hcContext.helpcenter.categoryTree,
                        section,
                      }),
                    );
                    break;
                  case HelpcenterSectionType.CORE_REQUEST_LIST:
                    const maybePage = query.get('page');
                    sectionDataPromises.push(
                      ticketListSectionDataLoader({
                        locale: match.params.locale,
                        page: maybePage ? parseInt(maybePage, 10) : 1,
                        preview: isPreview
                      }),
                    );
                    break;
                  case HARDCODED_SECTION_TYPES.PROFILE:
                    sectionDataPromises.push(
                      profileSectionDataLoader({ locale: match.params.locale }),
                    );
                    break;
                  case HelpcenterSectionType.FOLLOWED_ARTICLES:
                    sectionDataPromises.push(
                      followedArticlesSectionDataLoader({
                        locale: match.params.locale,
                        preview: isPreview,
                      }),
                    );
                    break;
                  case HelpcenterSectionType.CORE_REQUEST_PAGE:
                    sectionDataPromises.push(
                      customerPortalTicketSectionDataLoader({
                        id: match.params.ticketId,
                      }),
                    );
                    break;
                  case HARDCODED_SECTION_TYPES.TICKET:
                    sectionDataPromises.push(
                      ticketSectionDataLoader({ id: match.params.ticketId }),
                    );
                    break;
                  default:
                }
              });
              const sectionData = await Promise.all(sectionDataPromises);

              hcContext[locationDataKey(location)] = Object.assign(
                {},
                ...(result || []),
                ...(sectionData || []),
              );
              setData(hcContext[locationDataKey(location)]);
              res();
            } catch (err: any) {
              if (err instanceof RecoverableError) {
                switch (err.recoverType) {
                  case 'REDIRECT':
                    return history.push(`/${match.params.locale}${err.data}`);
                  default:
                    rej();
                }
              } else {
                if (Object.values(ErrorType).includes(err.response?.status)) {
                  if (
                    err.response?.status === ErrorType.PERMISSION_ERROR ||
                    err.response.status === ErrorType.UNAUTHORIZED
                  ) {
                    redirectToLogin();
                  }
                  setLoadDataError(err.response?.status as ErrorType);
                } else {
                  setLoadDataError(ErrorType.SERVER_ERROR);
                }
                setPageStructure(
                  helpcenterStructure.pages[HelpcenterPageNames.ERROR],
                );
                await reportClientPageViewError(
                  pageStructure.type,
                  hcContext.tenant.id,
                  match.params.locale,
                  `${err.status}`,
                  hcContext.helpcenter.sessionUser?.id,
                  customerPortalEnabled,
                  initialData.tenant.brandId,
                );
                res();
              }
            }
            setIsLoading(false);
          }
          res();
        });
      };

      // tslint:disable-next-line: no-floating-promises
      fetchData()
        .then(() => fedopsLogger.maybeEndRunningInteraction())
        .then(() => {
          // send context to agent toolbar
          toolbarActions.onContextDataUpdate({
            article: hcContext[locationDataKey(location)]?.article,
            category: hcContext[locationDataKey(location)]?.category,
            pageType: pageStructure.type,
          });
        });
      return () => {
        // saveScrollPosition(pageStructure.type, window); // DISABLED UNTIL FIX FOR AUTOSCROLL
      };
    }, [location, templates]);

    const modelEnhancer = { ...data };
    if (
      hcContext[locationDataKey(location)] &&
      Object.keys(hcContext[locationDataKey(location)]).length > 0
    ) {
      Object.assign(modelEnhancer, hcContext[locationDataKey(location)] || {});
    }

    const breadcrumbsList = [{ name: t('breadcrumb.home'), uri: '' }].concat(
      getBreadCrumbs(
        pageStructure.type,
        match.params,
        hcContext.helpcenter.categoryTree,
        modelEnhancer,
        t,
      ),
    );

    const breadcrumbsListForSeo = [
      { name: t('breadcrumb.home'), uri: '' },
    ].concat(
      getBreadCrumbs(
        pageStructure.type,
        match.params,
        hcContext.helpcenter.categoryTree,
        modelEnhancer,
        t,
        true,
      ),
    );

    // BI page view
    useEffect(() => {
      if (
        pageStructure.type === HelpcenterPageType.FOLLOWED_ARTICLES ||
        pageStructure.type === HelpcenterPageType.REQUEST_LIST ||
        pageStructure.type === HelpcenterPageType.REQUEST_PAGE
      ) {
        reportCustomerPortalPageView(
          pageStructure.type,
          hcContext.tenant.id,
          match.params.locale,
          hcContext.helpcenter.sessionUser?.id,
          initialData.tenant.brandId,
        );
      }
      reportClientPageView(
        pageStructure.type,
        hcContext.tenant.id,
        match.params.locale,
        hcContext.helpcenter.sessionUser?.id,
        customerPortalEnabled,
        initialData.tenant.brandId,
      );
    }, [location.pathname]); // trigger new BI event only if URL has changed

    // BI article page view
    useEffect(() => {
      const isArticlePage =
        pageStructure.type === HelpcenterPageType.ARTICLE ||
        pageStructure.type === HelpcenterPageType.ARTICLE_FEATURE_REQUEST ||
        pageStructure.type === HelpcenterPageType.ARTICLE_KNOWN_ISSUE;

      if (isArticlePage && data?.article) {
        reportPageViewArticle(
          hcContext.tenant.id,
          match.params.locale,
          hcContext.helpcenter.sessionUser?.id,
          hcContext.helpcenter.categoryTree,
          data?.article,
          customerPortalEnabled,
          initialData.tenant.brandId,
        );
      }
    }, [data?.article]);

    useEffect(() => {
      if (isMobile) {
        setSidebarOpen(false);
      }
    }, [location.pathname]);
    // DISABLED UNTIL FIX FOR AUTOSCROLL
    // Restore page scroll position
    // useEffect(() => {
    //   if (!isLoading) {
    //     restoreScrollPosition(pageStructure.type, window);
    //   }
    // }, [isLoading]);

    const getSdkEventData = (
      pageType: HelpcenterPageType | ExtraPageType,
    ): SdkOnPageLoadEventData => {
      const locale = match.params.locale;
      switch (pageType) {
        case HelpcenterPageType.ARTICLE_FEATURE_REQUEST:
        case HelpcenterPageType.ARTICLE_KNOWN_ISSUE:
        case HelpcenterPageType.ARTICLE:
          return {
            pageType,
            locale,
            article: data?.article || {},
            relatedArticles: data?.relatedArticles || {},
          };
        case HelpcenterPageType.CATEGORIES:
          return {
            pageType,
            locale,
            categoryTree: data?.categoryTree || {},
          };
        case HelpcenterPageType.CATEGORY:
          return {
            pageType,
            locale,
            category: data?.category || {},
            articles: data?.articles || [],
          };
        case HelpcenterPageType.SUB_CATEGORIES:
          return {
            pageType,
            locale,
            category: data?.category || {},
            subcategories: data?.subcategories || [],
          };
        case HelpcenterPageType.SEARCH_RESULTS:
          return {
            pageType,
            locale,
            itemsCount: data?.itemsCount,
            results: data?.articles || {},
            searchTerm: data?.search?.term || 'N/A',
          };
        case ExtraPageType.TICKET:
        case HelpcenterPageType.REQUEST_PAGE:
          return {
            pageType,
            locale,
            ticket: data?.ticket || {},
          };
        case HelpcenterPageType.REQUEST_LIST:
        case HelpcenterPageType.FOLLOWED_ARTICLES:
        case ExtraPageType.PROFILE:
          return {
            pageType,
            locale,
            user: hcContext?.helpcenter?.sessionUser || {},
          };
        case HelpcenterPageType.ERROR:
          return { pageType, locale, errorCode: Number(loadDataError) };
        case HelpcenterPageType.HOMEPAGE:
        case HelpcenterPageType.CONTACT:
        default:
          return { pageType, locale };
      }
    };

    useEffect(() => {
      if (window.innerWidth >= SCREENSIZE_TABLET && !isSidebarOpen) {
        setSidebarOpen(true);
      } else if (window.innerWidth < SCREENSIZE_TABLET && isSidebarOpen) {
        setSidebarOpen(false);
      }
      setPageReadyForSDK(true);
    }, []);

    useEffect(() => {
      const handleResize = () => setIsMobile(window.innerWidth <= 600);
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    // SDK page view
    useEffect(() => {
      if (!isLoading && isPageReadyForSDK) {
        setCurrentUserSdk(hcContext?.helpcenter?.sessionUser);
        publishOnPageLoadSdkEvent(getSdkEventData(pageStructure.type));
      }
    }, [isLoading, isPageReadyForSDK]); // trigger sdk event when finished loading page

    const maybePageSeoTags = () => {
      if (
        [
          HelpcenterPageType.HOMEPAGE,
          HelpcenterPageType.SEARCH_RESULTS,
          HelpcenterPageType.CATEGORIES,
          HelpcenterPageType.CONTACT,
        ].includes(pageStructure.type)
      ) {
        const url = `https://${
          hcContext.tenant.helpCenterSettings.domain.name
        }/kb${locationDataKey(location)}`;
        const placeholders = {
          SEARCH_TERM:
            pageStructure.type === HelpcenterPageType.SEARCH_RESULTS
              ? decodeServerSearchTerm(location.search.split('?term=')[1])
              : '',
          CANONICAL_URL: getUrlWithKb(url, match.params.locale),
          CATEGORY_TITLE:
            pageStructure.type === HelpcenterPageType.CATEGORIES
              ? t('breadcrumb.all-topics')
              : '',
          PARENT_CATEGORY_TITLE:
            pageStructure.type === HelpcenterPageType.CATEGORIES
              ? t('breadcrumb.home')
              : '',
        };
        seoManager
          .setPage(pageStructure.type as HelpcenterPageType)
          .setPlaceholders(placeholders)
          .setDefaultTitle(hcContext?.tenant?.brandName || hcContext.tenantName)
          .setDefaultDescription('');
        const seo = seoManager.getTags();
        return (
          <Helmet>
            {seo.metaTitle && <title>{seo.metaTitle}</title>}
            {seo.metaDescription && (
              <meta name="description" content={seo.metaDescription} />
            )}
            {seo.metaRobots && <meta name="robots" content={seo.metaRobots} />}
            <link rel="canonical" href={placeholders.CANONICAL_URL} />
          </Helmet>
        );
      }
    };

    const maybeRenderHeroSection = () => {
      const heroSection = pageStructure.sections.find(
        (section) => section.type === HelpcenterSectionType.HERO,
      );
      if (heroSection) {
        return renderSection(heroSection);
      }
      return null;
    };

    const renderNonHeroSections = () => {
      let filterKbSections = _ => true;
      if (pageStructure.type === HelpcenterPageType.REQUEST_LIST || pageStructure.type === HelpcenterPageType.REQUEST_PAGE) {
      const isCustomerPortalEnabled = experiments.enabled('specs.EnableCustomerPortal');
      const query = new URLSearchParams(window.location.search);
      const isPreview = query.has('preview');
      const isHelpcenterEnabled = !initialData.tenant || initialData.tenant.helpCenterSettings.enabled;
      filterKbSections = (section) => !isCustomerPortalEnabled ||
        isHelpcenterEnabled ||
        isPreview ||
        (section.type !== HelpcenterSectionType.CATEGORIES && section.type !== HelpcenterSectionType.ARTICLE_LIST)
      }
      return pageStructure.sections
        .filter((section) => section.type !== HelpcenterSectionType.HERO)
        .filter(filterKbSections)
        .map(renderSectionMap);
    };

    const maybeRenderBreadcrumbs = () => {
      const isArticlePage =
        pageStructure.type === HelpcenterPageType.ARTICLE ||
        pageStructure.type === HelpcenterPageType.ARTICLE_FEATURE_REQUEST ||
        pageStructure.type === HelpcenterPageType.ARTICLE_KNOWN_ISSUE;

      const articleSectionConfig =
        (isArticlePage &&
          pageStructure.sections.find(
            (section) =>
              ([
                HelpcenterSectionType.CORE_ARTICLE,
                HelpcenterSectionType.CORE_ARTICLE_FEATURE_REQUEST,
                HelpcenterSectionType.CORE_ARTICLE_KNOWN_ISSUE,
              ].includes(section.type) as unknown) as BaseSectionData<
                | HelpcenterSectionType.CORE_ARTICLE
                | HelpcenterSectionType.CORE_ARTICLE
                | HelpcenterSectionType.CORE_ARTICLE_KNOWN_ISSUE
              >,
          )) ||
        undefined;

      const isPageWithBreadcrumbs = (): boolean => {
        switch (pageStructure.type as HelpcenterPageType | ExtraPageType) {
          case HelpcenterPageType.HOMEPAGE:
          case HelpcenterPageType.ERROR:
          case HelpcenterPageType.REQUEST_PAGE:
          case HelpcenterPageType.FOLLOWED_ARTICLES:
          case HelpcenterPageType.REQUEST_LIST:
            return false;
          case HelpcenterPageType.ARTICLE:
          case HelpcenterPageType.ARTICLE_FEATURE_REQUEST:
          case HelpcenterPageType.ARTICLE_KNOWN_ISSUE:
            return articleSectionConfig?.config.showBreadcrumbs;
          default:
            return true;
        }
      };

      const baseUrl = `https://${initialData.tenant.helpCenterSettings.domain.name}`;

      return (
        <>
          <Helmet
            script={[
              helmetJsonLdProp<BreadcrumbList>({
                '@context': 'https://schema.org',
                '@type': 'BreadcrumbList',
                itemListElement: breadcrumbsListForSeo.map((crumb, index) => ({
                  '@type': 'ListItem',
                  position: index + 1,
                  name: crumb.name,
                  item: `${baseUrl}/${match.params.locale}${crumb.uri}`,
                })),
              }),
            ]}
          />
          {isPageWithBreadcrumbs() && (
            <Breadcrumbs
              t={() => ''}
              breadcrumbsList={breadcrumbsList}
              isToolbarExpanded={toolbarActions.isExpanded}
              hasToolbar={toolbarActions.hasToolbar}
              onToolbarUpdate={toolbarActions.onToolbarUpdate}
              isArticlePage={isArticlePage}
              locale={match.params.locale}
              hasSidebarButton={isSidebarEnabled()}
              onSidebarButtonClick={() => setSidebarOpen(true)}
            />
          )}
        </>
      );
    };

    const renderTemplateCompiler = (section: BaseSectionData<any>, model: SectionModel) => {
      switch (section.type) {
        case HelpcenterSectionType.FOLLOWED_ARTICLES:
          return (
            <FollowedArticlesTemplateCompiler
              sectionTemplate={templates[section.id]}
              baseSegment={section}
              segmentConfig={section.config}
              segmentType={section.type}
              model={model}
            />
          );
        case HelpcenterSectionType.CORE_REQUEST_LIST:
          return (
            <CoreRequestListTemplateCompiler
              sectionTemplate={templates[section.id]}
              baseSegment={section}
              model={model}
              segmentConfig={section.config}
              segmentType={section.type}
              savedFilters={getRequestListFilters()}
            />
          );

        default:
          return (
            <TemplateCompiler
              sectionTemplate={templates[section.id]}
              baseSegment={section}
              model={model}
              segmentConfig={section.config}
              segmentType={section.type}
            />
          );
      }
    };


    const renderTemplateCompilerHCPagination = (section: BaseSectionData<any>, model: SectionModel) => {
      switch (section.type) {
        case HelpcenterSectionType.FOLLOWED_ARTICLES:
          return (
            <FollowedArticlesTemplateCompiler
              sectionTemplate={templates[section.id]}
              baseSegment={section}
              segmentConfig={section.config}
              segmentType={section.type}
              model={model}
            />
          );
        case HelpcenterSectionType.CORE_REQUEST_LIST:
          return (
            <CoreRequestListTemplateCompiler
              sectionTemplate={templates[section.id]}
              baseSegment={section}
              model={model}
              segmentConfig={section.config}
              segmentType={section.type}
              savedFilters={getRequestListFilters()}
            />
          );

          case HelpcenterSectionType.CORE_SEARCH_RESULTS:
          return (
            <SearchResultsTemplateCompiler
              sectionTemplate={templates[section.id]}
              baseSegment={section}
              segmentConfig={section.config}
              segmentType={section.type}
              model={model}
            />
          );
        default:
          return (
            <TemplateCompiler
              sectionTemplate={templates[section.id]}
              baseSegment={section}
              model={model}
              segmentConfig={section.config}
              segmentType={section.type}
            />
          );
      }
    };

    const renderSection = (
      section: BaseSectionData<any>,
      addClassNames: string[] = [],
    ) => {
      const knownIssues = modelEnhancer.knownIssues ? {} : { knownIssues: [] };
      const featureRequests = modelEnhancer.featureRequests
        ? {}
        : { featureRequests: [] };
        //TODO: check that the model doesnt contain redundant things
      const model = {
        ...baseModel,
        ...initialData.helpcenter,
        ...modelEnhancer,
        ...knownIssues,
        ...featureRequests,
        ...modifiedModel,
        errorType: loadDataError,
        tenant: hcContext.tenant,
        locale: match.params.locale,
        currentPage: pageStructure.type,
        isAgent: initialData.isAgent,
        premium: initialData.premium,
        customerPortalEnabled: experiments.enabled('specs.EnableCustomerPortal'),
      };
      const maybeFilteredContentModel = sdkContentFilters ? getFilteredContentModel(model, sdkContentFilters) : model;
      const sectionElement = (
        <div
          className={classNames([
            `page-container`,
            `${segmentTypeMap[section.type]}`,
            // todo: consolidate this with syle class name creation
            `${segmentTypeMap[section.type]}-${section.id}`,
            `override-indicator css-${!!(
              section.codeOverrides && section.codeOverrides.style
            )} ejs-${!!(
              section.codeOverrides && section.codeOverrides.template
            )}`,
            ...addClassNames,
          ])}
          key={section.id}
        >
          {renderTemplateCompilerHCPagination(section, maybeFilteredContentModel)}
        </div>
      );

      return <React.Fragment key={section.id}>{sectionElement}</React.Fragment>;
    };

    const renderSectionMap = (
      section: BaseSectionData<any>,
      idx: number,
      arr: BaseSectionData<any>[],
    ) => {
      const classNamesForSection =
        idx === arr.length - 1 ? ['last-section'] : [];
      return renderSection(section, classNamesForSection);
    };

    const isSidebarEnabled = () =>
      helpcenterStructure.sidebar.config.enabledInPages.includes(
        pageStructure.type,
      );

    const closeSidebar = () => setSidebarOpen(false);
    const maybeRenderSidebarNavigation = () => {
      if (isSidebarEnabled()) {
        const model = {
          ...baseModel,
          ...initialData.helpcenter,
          ...modelEnhancer,
          ...modifiedModel,
          // errorType: loadDataError,
          // tenant: hcContext.tenant,
          locale: match.params.locale,
          currentPage: pageStructure.type,
          customerPortalEnabled: experiments.enabled('specs.EnableCustomerPortal')

        };
        const maybeFilteredContentModel = sdkContentFilters ? getFilteredContentModel(model, sdkContentFilters) : model;

        return (
          <NavSidebar
            template={templates[ComponentTypes.NavSidebar]}
            helpcenterStructure={helpcenterStructure}
            toolbarActions={toolbarActions}
            isOpen={isSidebarOpen}
            isMobile={isMobile}
            onCloseClick={closeSidebar}
            model={maybeFilteredContentModel}
          />
        );
      }
      return null;
    };

    const renderOtherSections = () => {
      return (
        <div className="content-wrapper">
          {!isMobile ? maybeRenderSidebarNavigation() : null}
          <div className={'page-content-sections'}>
            {renderNonHeroSections()}
          </div>
        </div>
      );
    };

    return (
      <div
        key={key}
        className={classNames(`page-${pageStructure.type}`, 'page-content')}
      >
        {maybePageSeoTags()}
        {isMobile ? maybeRenderSidebarNavigation() : null}
        {maybeRenderHeroSection()}
        {maybeRenderBreadcrumbs()}
        {!isLoading && renderOtherSections()}

        {isLoading && !loadDataError && (
          <div
            className={classNames({
              'loading-container': isLoading,
            })}
          >
            <div className="loading"><SpinnerLoader color={branding.actionColor}/></div>
          </div>
        )}
      </div>
    );
  },
  (prev, next) =>
    locationDataKey(prev.location) === locationDataKey(location) &&
    prev.templates === next.templates,
);

export const PageContent = withExperiments(withTranslation()(_PageContent));
