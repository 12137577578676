import * as React from 'react';
import { useHistory } from 'react-router';
import { HeaderMobileMenuComp } from './header-mobile-menu';
import { contactUrl } from '../nav-links/contact-link';
import {
  getContactOptions,
  contactOptionDisabled,
} from '../../../common/contact-utils';

export const HeaderMobileMenu = ({ model, config, biLogger }) => (props) => {
  // tslint:disable-next-line:react-hooks-nesting
  const history = useHistory();
  const locales =
    model.tenant && model.tenant.helpCenterSettings.enabledLocales;
  const ctaEnabled =
    (config.showLanguagePicker && locales.length > 1) ||
    config.showContactButton ||
    config.showLoginButton;
  const contactOption = getContactOptions(model.tenant);
  const submitTicketsEnabled =
    !!model.sessionUser ||
    model.tenant.helpCenterSettings.authentication.enableGuestTickets;
  return (
    <div className="header-mobile-menu-wrapper">
      {model.tenant && ctaEnabled && (
        <HeaderMobileMenuComp
          config={config}
          locales={locales}
          history={history}
          locale={model.tenant.currentLocale}
          sessionUser={model.sessionUser}
          contactOptionDisabled={contactOptionDisabled(contactOption)}
          contactButtonUrl={contactUrl(contactOption)}
          submitTicketsEnabled={submitTicketsEnabled}
          biLogger={biLogger}
          customerPortalEnabled={model.customerPortalEnabled}
          companies={model.myCompanies || []}
          tenant={model.tenant}
        />
      )}
    </div>
  );
};
