import { publicCustomerPortalViewerClientEngagement } from '@wix/bi-logger-wix-answers/v2';
import * as React from 'react';
import { BiLogger } from '../../routes/bi';
import { useFedopsLogger as getFedopsLogger } from '../../utils/fedops-logger';
import { NavLinkJsx } from './nav-link-jsx';

export const ArticleNavLink = ({ model, biLogger }: {model: any, biLogger: BiLogger}) => (props) => {
  const { id: articleId, children, articleUri, isCustomerPortal } = props;
  const {
    articles = [],
    knownIssues = [],
    featureRequests = [],
    relatedArticles = [],
    featuredArticles = [],
  } = model;
  const article =
    articles.find((e) => e.id === articleId) ||
    knownIssues.find((e) => e.id === articleId) ||
    featureRequests.find((e) => e.id === articleId) ||
    relatedArticles.find((e) => e.id === articleId) ||
    featuredArticles.find((e) => e.id === articleId);
  const uri = articleUri || article?.uri || '';
  const fedopsLogger = getFedopsLogger();
  const startNavigateToArticleInteraction = () => {
    fedopsLogger.startInteraction('article_navigation');
    if (isCustomerPortal) {
      biLogger.report(publicCustomerPortalViewerClientEngagement({
        data: articleId,
        engaged_component: 'followed_articles_list',
        engagement_name: 'click_on_view_an_article',
        answers_brand_id: model.tenant.brandId,
      }));
    }
  };
  return (
    <NavLinkJsx uri={uri} onClick={startNavigateToArticleInteraction}>
      {children}
    </NavLinkJsx>
  );
};
