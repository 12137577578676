import { HelpcenterSectionType, PageStructure } from '@wix/answers-api';
import differenceBy from 'lodash.differenceby';
import keyBy from 'lodash.keyby';
export * from './use-custom-compare-effect';

const configurableDataLoaderSectionTypes = {
  [HelpcenterSectionType.ARTICLE_LIST]: ['articleIds'],
};

// checks a page structure change for sections that require new data to be fetched from server
export const isStructureDataEqual = (
  curr: PageStructure<any>,
  prev: PageStructure<any>,
): boolean => {
  // filter only sections with configurable data loaders
  const prevSections = prev.sections.filter(({ type }) =>
    Object.keys(configurableDataLoaderSectionTypes).some(
      (a) => a === String(type),
    ),
  );
  const currSections = curr.sections.filter(({ type }) =>
    Object.keys(configurableDataLoaderSectionTypes).some(
      (a) => a === String(type),
    ),
  );

  // current page has a new section with data needs
  if (differenceBy(currSections, prevSections, 'id').length > 0) {
    return false;
  }

  // check sections by type and it's type's needs;
  const prevSectionsByIds = keyBy(prevSections, 'id');
  const hasRelevantSectionChanges = currSections.some((currSection) => {
    const prevSection = prevSectionsByIds[currSection.id];
    switch (currSection.type) {
      case HelpcenterSectionType.ARTICLE_LIST:
        return configurableDataLoaderSectionTypes[currSection.type].some(
          (field: string) => {
            return (
              (currSection.config[field] as string[])?.toString() !==
              (prevSection.config[field] as string[])?.toString()
            );
          },
        );
      default:
        return false;
    }
  });

  return !hasRelevantSectionChanges;
};
