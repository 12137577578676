import * as React from 'react';
import { ContactForm as CF, getRuntimeCustomFields, ContactFormSubmitData } from '@wix/answers-common-components';
import { WithTranslation, withTranslation } from 'react-i18next';
import { sanitizePII } from '@wix/bi-logger-sanitizer';
import { useFedopsLogger as getFedopsLogger } from '../../utils/fedops-logger';
import { HelpcenterContext } from '../../helpcenter-context';
import {
  FileUploadResponse,
  SourceType,
  ResolvedCustomField,
  Article,
  ContactLogger,
  ContactEventData,
  InvalidFormFields,
  VisibilityMode,
  ContactFormInitialFieldData,
  CustomFieldNameToValueMap,
  HelpcenterPageType,
} from '@wix/answers-api';
import { NavLink } from 'react-router-dom';
import { helpcenterApi } from '../../../api';
import { CreateTicketDto } from '../../../api/dto';
import { TenantInfo } from '../../../common/tenant-info.i';
import {
  publicOnContactFormChangeSdkEvent,
  sdkContactFormFieldsDataEmitter,
  sdkSetShowCCFieldEmitter,
  sdkCustomFieldsConfigurationChangedEmitter,
  sdkSetShowPartnerCompanyFieldEmitter,
  sdkSetContentFiltersEmitter,
} from '../../utils/sdk';
import { getInitalFieldsFromUrlParams, isObjectEmpty } from './utils';
import { HelpcenterUser } from '../../../common/helpcenter-types';
import {
  ContentFilters,
  ShowPartnerCompanyFieldData,
} from '../../utils/sdk/domain';
import { BiLogger } from '../../routes/bi';
import { publicSubmitTicketAttempt, publicSubmitTicketCancel, publicSubmitTicketSuccess, publicSubmitTicketValidationError, publicSuggestedArticlesClick } from '@wix/bi-logger-wix-answers/v2';

export const contactFormKey = 'contact-form-comp';
export const linkTemplate = (link: string) => `<a target="_blank" href="${link}">${link}</a>`;
const LINK_REGEX = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;

export const getBaseTicketData = (data: ContactEventData) => ({
  subject: sanitizePII(data.title),
  description: sanitizePII(data.description),
  form_source: 'contact',
});

export const getFullTicketData = (
  data: ContactEventData & { sourceArticle?: Article },
) => ({
  ...getBaseTicketData(data),
  is_attached: !!data.attachments.length,
  is_after_suggestion: data.isAfterSuggestion,
  article_id: data.sourceArticle?.id,
});

export interface ContactFormProps extends WithTranslation {
  value: string;
  onChange(value: string): void;
  customFields: ResolvedCustomField[];
  biLogger: BiLogger;
  tenant: TenantInfo;
  user?: HelpcenterUser;
  enableEmailConfirmation?: boolean;
}

export interface ContactFormState {
  submitted: boolean;
  sdkMergedCustomFields: ResolvedCustomField[];
  formData: ContactFormInitialFieldData;
  shouldShowEmailCCField: boolean;
  partnerCompanyField: ShowPartnerCompanyFieldData;
  contentFilters?: ContentFilters;
}

const emptyInitialFieldData = {
  fullname: '',
  email: '',
  title: '',
  description: '',
  customFields: {},
  attachments: [],
};

export class ContactForm extends React.PureComponent<
  ContactFormProps,
  ContactFormState
> {
  static contextType = HelpcenterContext;
  // static whyDidYouRender = true;
  context!: React.ContextType<typeof HelpcenterContext>;

  state: ContactFormState = {
    submitted: false,
    sdkMergedCustomFields: this.props.customFields,
    formData: emptyInitialFieldData,
    shouldShowEmailCCField: false,
    partnerCompanyField: {
      shouldShow: false,
    },
  };

  fedopsLogger = getFedopsLogger();

  contactLogger: ContactLogger = {
    submit: (data: ContactEventData) =>
      this.props.biLogger.report(publicSubmitTicketAttempt({
        ...getFullTicketData(data),
        answers_brand_id: this.props.tenant.brandId
      })),
    validationError: (
      data: ContactEventData,
      invalidFields: InvalidFormFields,
    ) => {
      const validationErrorData = {
        ...getFullTicketData(data),
        error_in_subject: invalidFields.title,
        error_in_description: invalidFields.description,
        error_in_name: invalidFields.name,
        error_in_email: invalidFields.email,
        error_in_custom_field: invalidFields.customFields,
      };
      return this.props.biLogger.report(publicSubmitTicketValidationError({
        ...validationErrorData,
        answers_brand_id: this.props.tenant.brandId
      }));
    },
    submitSuccess: async (data: ContactEventData, ticketId) => {
      const ticketSuccessData = {
        ...getFullTicketData(data),
        ticket_id: ticketId,
      };
      await this.props.biLogger.report(publicSubmitTicketSuccess({...ticketSuccessData, answers_brand_id: this.props.tenant.brandId}))
    },
    submitFailure: () => {},
    cancel: (data: ContactEventData) =>
      this.props.biLogger.report(publicSubmitTicketCancel({
        ...getBaseTicketData(data),
        answers_brand_id: this.props.tenant.brandId
      })),
    suggestedArticleSelected: (data: ContactEventData, article: Article) => {
      const articleSelectedData = {
        ...getBaseTicketData(data),
        selected_article_id: article.id,
        selected_article_name: article.title,
      };
      return this.props.biLogger.report(publicSuggestedArticlesClick({
        ...articleSelectedData,
        answers_brand_id: this.props.tenant.brandId
      }));
    },
  };
  getInitialDataFromUrl = (customFields: ResolvedCustomField[]) => {
    const query = new URLSearchParams(window.location.search);
    return getInitalFieldsFromUrlParams(query, customFields);
  }
  getLinkedArticle = () => {
    const query = new URLSearchParams(window.location.search);
    return query.get('linkedArticleId') || '';
  }
  sendTicket = (data: CreateTicketDto) => {
    const { authentication } = this.props.tenant.helpCenterSettings;
    const isNotRestricted =
      authentication.visibility === VisibilityMode.EVERYONE;
    const isGuest = !this.props.user;
    return isNotRestricted && isGuest
      ? helpcenterApi.createTicketAsGuest(data)
      : helpcenterApi.createTicketAsUser(data);
  };

  uploadBase64File = (
    base64file: any,
    captcha: string,
    uploadProgress?: (progress: number) => void,
    useCaptchaV3 = false
  ): Promise<FileUploadResponse> => {
    const onUploadProgress = ({
      loaded,
      total,
    }: {
      loaded: number;
      total: number;
    }) => {
      const percent = Math.ceil((loaded / total) * 100);
      if (uploadProgress) {
        uploadProgress(percent);
      }
    };
    return helpcenterApi.uploadFile(
      { base64file, captcha, useInvisible: true, secured: true, useCaptchaV3 },
      onUploadProgress,
    );
  };

  onAttachmentUpload = (
    file: any,
    captchaToken: string = '',
    progressCb?: (precentage: number) => void,
    useCaptchaV3 = false
  ) => {
    return this.uploadBase64File(file, captchaToken, progressCb, useCaptchaV3).then(
      (data: any) => ({
        name: data.filename,
        url: data.filelink,
      }),
    );
  };

  contentToHtml = (content: string): string => {
    return content
      .replace(LINK_REGEX, (link) => {
        let l = link;
        if (link.startsWith('www')) {
          l = `https://${link}`;
        }
        return linkTemplate(l);
      })
      .split('\n')
      .map((sentence) => `<p>${sentence}</p>`)
      .join('\n');
  };

  submitTicket = (submitData: ContactFormSubmitData): Promise<string> => {
    this.fedopsLogger.startInteraction('ticket_submit');
    const htmlContent = this.contentToHtml(submitData.description);

    const { attachments } = submitData;
    const content = htmlContent;
    const companyId = submitData.companyId
      ? { companyId: submitData.companyId }
      : {};
    const maybeLinkedArticle = this.getLinkedArticle();
    const relatedArticleIds = maybeLinkedArticle ?
      { relatedArticleIds: [maybeLinkedArticle] }
      : {};

    const alteredSubmitData = {
      subject: submitData.title,
      customFields: submitData.customFieldsValuesMap,
      captcha: submitData.captchaToken,
      content,
      useInvisible: true,
      private: true,
      userFirstName: submitData.firstName,
      userLastName: submitData.lastName,
      userEmail: submitData.email,
      ccRecipients: submitData.ccRecipients,
      locale: this.props.tenant.currentLocale,
      sourceType: SourceType.HELP_CENTER,
      sourceId: null,
      labelIds: [],
      attachments,
      brandId: this.props.tenant.brandId,
      useCaptchaV3: submitData.useCaptchaV3,
      ...companyId,
      ...relatedArticleIds
    };

    return this.sendTicket(alteredSubmitData)
      .then((ticket) => {
        this.setState({ submitted: true });
        this.fedopsLogger.endInteraction('ticket_submit');
        return ticket.id;
      })
      .catch(() => {
        // TODO - handle error
        this.setState({ submitted: false });
        return '';
      });
  };

  scrollSubmittedToView = (elem: HTMLDivElement) => {
    const title: HTMLElement | null = document.querySelector(
      '.contact-us .title-container',
    );
    if (title) {
      title.style.display = 'none';
    }
    if (elem) {
      window.scroll({ top: elem.offsetTop, behavior: 'smooth' });
    }
  };

  submittedState = () => {
    return (
      <div ref={this.scrollSubmittedToView} className="submitted-view">
        <div className="submitted-title">
          {this.props.t('contact-form-submitted-title')}
        </div>
        <div className="submitted-subtitle">
          {this.props.t('contact-form-submitted-subtitle')}
        </div>
        <NavLink to="/en">
          <div className="visit-helpcenter">
            {this.props.t('contact-form-submitted-button')}
          </div>
        </NavLink>
      </div>
    );
  };

  handleSearchArticles = async (val: string) => {
    const maybeContentFilters = this.state.contentFilters || {};
    return (
      await helpcenterApi.searchArticles({
        brandId: this.props.tenant.brandId,
        locale: this.props.tenant.currentLocale,
        text: val,
        page: 1,
        pageSize: 3,
        spellcheck: true,
        ...maybeContentFilters,
      })
    ).data;
  };
  onGetSuggestedArticles = (title: string): Promise<Article[]> =>
    this.handleSearchArticles(title);

  onFieldsChanged = (fieldsData: ContactFormInitialFieldData) => {
    publicOnContactFormChangeSdkEvent({
      pageType: HelpcenterPageType.CONTACT,
      fieldsData,
    });
    this.updateCustomFieldsVisibility(fieldsData.customFields);
  };

  cleanSdkCustomFieldsListener: any = null;
  cleanSdkContactFormDataListener: any = null;
  cleanSdkShowEmailCCListener: any = null;
  cleanSdkShowPartnerCompanyListener: any = null;
  cleanSdkContentFiltersListener: any = null;

  private updateCustomFieldsVisibility(
    customFieldsData: CustomFieldNameToValueMap,
  ) {
    const sdkCustomFieldsConf = sdkCustomFieldsConfigurationChangedEmitter.getLast();
    const sdkMergedCustomFields = getRuntimeCustomFields(
      this.props.customFields,
      customFieldsData,
      sdkCustomFieldsConf,
    );
    this.setState({ sdkMergedCustomFields });
  }

  componentDidMount() {
    this.updateCustomFieldsVisibility(this.state.formData.customFields);

    this.cleanSdkCustomFieldsListener = sdkCustomFieldsConfigurationChangedEmitter.addListener(
      (customFieldsConf) => {
        this.setState({
          sdkMergedCustomFields: getRuntimeCustomFields(
            this.props.customFields,
            this.state.formData.customFields,
            customFieldsConf,
          ),
        });
      },
    );

    this.setState({
      shouldShowEmailCCField:
        !!sdkSetShowCCFieldEmitter.getLast() && !!this.props.user,
    });

    this.setState({ contentFilters: sdkSetContentFiltersEmitter.getLast()});

    this.cleanSdkShowEmailCCListener = sdkSetShowCCFieldEmitter.addListener(
      (shouldShowEmailCCField) => {
        this.setState({
          shouldShowEmailCCField: shouldShowEmailCCField && !!this.props.user,
        });
      },
    );
    const initDataFromUrl = this.getInitialDataFromUrl(this.state.sdkMergedCustomFields);
    const initDataFromSdkContactFormData = sdkContactFormFieldsDataEmitter.getLast();

    if (isObjectEmpty(initDataFromSdkContactFormData) || isObjectEmpty(initDataFromUrl)) {
      const initialFormData = { ...emptyInitialFieldData, ...(initDataFromSdkContactFormData || {}), ...initDataFromUrl };
      this.setState({formData: initialFormData});
    }

    this.cleanSdkContactFormDataListener = sdkContactFormFieldsDataEmitter.addListener(
      (formData) => {
        this.setState({ formData });
      },
    );

    const showPartnerCompanyFieldData = sdkSetShowPartnerCompanyFieldEmitter.getLast();
    if (showPartnerCompanyFieldData) {
      this.setState({
        partnerCompanyField: showPartnerCompanyFieldData,
      });
    }

    this.cleanSdkShowPartnerCompanyListener = sdkSetShowPartnerCompanyFieldEmitter.addListener(
      (partnerCompanyField) => {
        this.setState({ partnerCompanyField });
      },
    );

    this.cleanSdkContentFiltersListener = sdkSetContentFiltersEmitter.addListener(
      (contentFilters) => {
        this.setState({ contentFilters });
      },
    );
  }

  getPartnerCompanyFieldData() {
    const isPartner =
      this.props.user?.companyIds && this.props.user.companyIds.length > 1;
    if (
      this.state.partnerCompanyField?.shouldShow &&
      this.props.user?.companies &&
      isPartner
    ) {
      return {
        companies: this.props.user?.companies,
        mandatory: this.state.partnerCompanyField.mandatory,
      };
    }
  }

  componentWillUnmount() {

    if (this.cleanSdkCustomFieldsListener) {
      this.cleanSdkCustomFieldsListener();
    }

    if (this.cleanSdkShowEmailCCListener) {
      this.cleanSdkShowEmailCCListener();
    }

    if (this.cleanSdkShowPartnerCompanyListener) {
      this.cleanSdkShowPartnerCompanyListener();
    }
    if (this.cleanSdkContentFiltersListener) {
      this.cleanSdkContentFiltersListener();
    }
  }

  render() {
    const { props, state } = this;
    const conditionalFieldsConfig = !!sdkCustomFieldsConfigurationChangedEmitter.getLast();
	const reCaptchaData = {
		isRequired: false,
		key: this.context.captchaKey,
		keyV3: this.context.reCaptchaV3Key
	};

    const contactForm = (
      <div>
        <CF
          translate={props.t}
          initialFieldData={this.state.formData}
          customFields={this.state.sdkMergedCustomFields}
          onGetSuggestedArticles={this.onGetSuggestedArticles}
          suggestedArticlesUnderSubject={true}
          reCaptchaData={reCaptchaData}
          hideRecaptchaBadge={true}
          onAttachmentUpload={this.onAttachmentUpload}
          onSubmit={this.submitTicket}
          logger={this.contactLogger as any}
          locale={props.tenant.currentLocale}
          primaryLocale={props.tenant.primaryLocale}
          user={props.user}
          onFieldsChange={this.onFieldsChanged}
          addCCEmail={state.shouldShowEmailCCField}
          conditionalCustomFields={conditionalFieldsConfig}
          partnerCompanyField={this.getPartnerCompanyFieldData()}
          allowedFileTypes={props.tenant.allowedFileTypes}
          enableEmailConfirmation={props.enableEmailConfirmation}
        />
      </div>
    );

    return (
      <div className={contactFormKey}>
        {state.submitted ? this.submittedState() : contactForm}
      </div>
    );
  }
}

ContactForm.contextType = HelpcenterContext;
export const ContactFormComp = withTranslation()(ContactForm);
