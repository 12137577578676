import { getApiUrl } from '../../../common/utils';
import * as ans from '@wix/answers-api';
import { httpClientPost } from '../../../api';

const apiPath = getApiUrl;
const sourceType: ans.SourceType = ans.SourceType.HELP_CENTER;
const sourceId = null;

export const resultClickMore = (text: string = '', locale: string) => {
  ans.AnalyticsLogger.logSearchResultClickMore(
    httpClientPost,
    apiPath,
    locale,
    sourceType,
    sourceId,
    text,
  );
};

export const resultIgnored = (text: string, locale: string) => {
  ans.AnalyticsLogger.logSearchResultIgnored(
    httpClientPost,
    apiPath,
    locale,
    sourceType,
    sourceId,
    text,
  );
};

export const resultNone = (text: string = '', locale: string) => {
  ans.AnalyticsLogger.logSearchResultNone(
    httpClientPost,
    apiPath,
    locale,
    sourceType,
    sourceId,
    text,
  );
};

export const resultArticleClicked = (
  text: string,
  articleId: string,
  position: number,
  locale: string,
) => {
  ans.AnalyticsLogger.logSearchResultArticleClicked(
    httpClientPost,
    apiPath,
    locale,
    sourceType,
    sourceId,
    text,
    articleId,
    position,
  );
};
