import { Category, Article } from "@wix/answers-api";
import produce from 'immer';
import { deepSearch } from "../../../common/utils";
import { LimitedArticle, CategoryArticles } from "../../../server/rpc/types/response-dto";
import { ContentFilters } from "../../utils/sdk/domain";

export interface ContentModel {
    categoryTree?: Category[],
    subcategories?: Category[],
    categories?: Category[]//used in nav sidebar
    categoryArticles?: CategoryArticles[],
    rootCategoriesArticles?: CategoryArticles[],
    subcategoryArticles?: CategoryArticles[],
    parentCategoryArticles: Article[],
    articles?: Article[],
    featureRequests?: Article[],
    knownIssues?: Article[],
    featuredArticles?: Article[],
    relatedArticles?: Article[],
}

const getParentCategoryId = (categoryId: string, categoryTree: Category[]) => {
    const cat = deepSearch(
      categoryTree,
      'children',
      (item) => item.id === categoryId,
    );
    return cat?.parentId;
  }
  
  const isCategoryIncluded = (cat: Category, contentFilters: ContentFilters) => {
    if (contentFilters.categoryIds?.length) {
      const maybeChildrenIcluded = cat.children?.some(subcat => contentFilters.categoryIds?.some(id => subcat.id === id));
      const isIncluded = contentFilters.categoryIds.some(catId => catId === cat.id || catId === cat.parentId || maybeChildrenIcluded);
      if (!isIncluded) {
        return false;
      }
    }
    if(contentFilters.excludedCategoryIds?.length) {
      const isIncluded = contentFilters.excludedCategoryIds.every(catId => catId !== cat.id && catId !== cat.parentId);
      if (!isIncluded) {
        return false;
      }
    }
    return true;
  }
  const isCategoryIncludedById = (categoryId: string, contentFilters: ContentFilters, parentCategoryId?: string) => {
    if (contentFilters.categoryIds?.length) {
      const isIncluded = contentFilters.categoryIds.some(catId => catId === categoryId || (parentCategoryId && catId === parentCategoryId));
      if(!isIncluded) {
        return false;
      }
    }
    if(contentFilters.excludedCategoryIds?.length) {
      const isIncluded = contentFilters.excludedCategoryIds.every(catId => catId !== categoryId && !(parentCategoryId && catId === parentCategoryId));
      if(!isIncluded) {
        return false;
      }
    }
    return true;
  }
  
  const isCategoryArticleIncluded = (article: LimitedArticle, contentFilters: ContentFilters) => {
  
    if(contentFilters.hasAllOfLabelIds?.length) {
      const isIncluded = contentFilters.hasAllOfLabelIds.every(labelId => article.labels?.some((label) => labelId === label.id));
      if(!isIncluded) {
        return false;
      }
    }
    if(contentFilters.hasAnyOfLabelIds) {
      const isIncluded = contentFilters.hasAnyOfLabelIds.some(labelId => article.labels?.some((label) => labelId === label.id));
      if(!isIncluded) {
        return false;
      }
    }
    if(contentFilters.notHasAnyOfLabelIds) {
      const isIncluded = contentFilters.notHasAnyOfLabelIds.every(labelId => !article.labels?.length || article.labels.every((label) => labelId !== label.id));
      if(!isIncluded) {
        return false;
      }
    }
    return true;
  }
  
  const isArticleIncluded = (article: Article, categoryTree: Category[], contentFilters: ContentFilters) => {
    const maybeParentCategoryId = getParentCategoryId(article.categoryId, categoryTree);
    if(contentFilters.categoryIds?.length) {
      const isIncluded = contentFilters.categoryIds.some(categoryId => 
          article.categoryId === categoryId || maybeParentCategoryId && maybeParentCategoryId === categoryId);
      if(!isIncluded) {
        return false;
      }
    }
    if(contentFilters.excludedCategoryIds?.length) {
      const isIncluded = contentFilters.excludedCategoryIds.every(categoryId =>
        article.categoryId !== categoryId && !(maybeParentCategoryId && maybeParentCategoryId === categoryId));
      if(!isIncluded) {
        return false;
      }
    }
    if(contentFilters.hasAnyOfLabelIds?.length) {
      const isIncluded = contentFilters.hasAnyOfLabelIds.some(labelId => article.labels?.some((label) => labelId === label.id));
      if(!isIncluded) {
        return false;
      }
    }
    if(contentFilters.hasAllOfLabelIds?.length) {
      const isIncluded = contentFilters.hasAllOfLabelIds.every(labelId => article.labels?.some((label) => labelId === label.id));
      if(!isIncluded) {
        return false;
      }
    }
    if(contentFilters.notHasAnyOfLabelIds?.length) {
      const isIncluded = contentFilters.notHasAnyOfLabelIds.every(labelId => !article.labels || article.labels?.every((label) => labelId !== label.id));
      if(!isIncluded) {
        return false;
      }
    }
    return true;
  }
  
  const getFilteredCategoryArticles = (categoryArticles: CategoryArticles[], categoryTree: Category[], contentFilters: ContentFilters) => 
    categoryArticles.filter(cat => {
      const parentCatId = getParentCategoryId(cat.categoryId, categoryTree);
      return isCategoryIncludedById(cat.categoryId, contentFilters, parentCatId)
    })
    .map(cat => {
      return produce(cat, (draft) => {
        draft.articles = cat.articles.filter(article => isCategoryArticleIncluded(article, contentFilters));
      });
  });
  
  const getFilteredArticles = (articles: Article[], categoryTree: Category[], contentFilters: ContentFilters) => {
    return articles.filter(art => isArticleIncluded(art, categoryTree, contentFilters));
  }
  const getFilteredCategories = (categories: Category[], contentFilters: ContentFilters) => {
    return categories.reduce((filteredCategories: Category[], cat: Category) => {
      if(isCategoryIncluded(cat, contentFilters)) {
        const categoryWithFilteredChildren = cat.children?.length ?
          {...cat, children: cat.children?.filter(subcategory => isCategoryIncluded(subcategory, contentFilters))} : cat;
        return [ ...filteredCategories, categoryWithFilteredChildren ];
      }
      return filteredCategories;
    }, [])
  }
  
  export const getFilteredContentModel = (model: any, sdkContentFilters: ContentFilters): ContentModel => {
    const filteredContentModel = produce(model, (draft) => {
      if(model.categoryTree?.length) {
        draft.categoryTree = getFilteredCategories(model.categoryTree, sdkContentFilters);
      }
      if(model.subcategories?.length) {
        draft.subcategories = getFilteredCategories(model.subcategories, sdkContentFilters);
      }
      if(model.categories?.length) {
        model.categories = getFilteredCategories(model.categories, sdkContentFilters);
      }
      if(model.categoryArticles?.length) {
        draft.categoryArticles = getFilteredCategoryArticles(model.categoryArticles, model.categoryTree, sdkContentFilters);
      }
      if(model.rootCategoriesArticles?.length) {
        draft.rootCategoriesArticles = getFilteredCategoryArticles(model.rootCategoriesArticles, model.categoryTree, sdkContentFilters);
      }
      if(model.subcategoryArticles?.length) {
        draft.subcategoryArticles = getFilteredCategoryArticles(model.subcategoryArticles, model.categoryTree, sdkContentFilters);
      }
      if(model.parentCategoryArticles?.length) {
        draft.parentCategoryArticles = getFilteredArticles(model.parentCategoryArticles, model.categoryTree, sdkContentFilters);
      }
      if(model.articles?.length) {
        draft.articles = getFilteredArticles(model.articles, model.categoryTree, sdkContentFilters);
      }
      if(model.featureRequests?.length) {
        draft.featureRequests = getFilteredArticles(model.featureRequests, model.categoryTree, sdkContentFilters);
      }
      if(model.knownIssues?.length) {
        draft.knownIssues = getFilteredArticles(model.knownIssues, model.categoryTree, sdkContentFilters);
      }
      if(model.featuredArticles?.length) {
        draft.featuredArticles = getFilteredArticles(model.featuredArticles, model.categoryTree, sdkContentFilters);
      }
      if(model.relatedArticles?.length) {
        draft.relatedArticles = getFilteredArticles(model.relatedArticles, model.categoryTree, sdkContentFilters);
      }
    })

    return {
      ...model,
      ...filteredContentModel
    }
  }