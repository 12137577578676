import * as React from 'react';
import { classNames } from '@wix/answers-lib';
import { WithTranslation, withTranslation } from 'react-i18next';
import { HelpcenterContext } from '../../helpcenter-context';
import { Icon, IconType } from '../icons';
import { History } from 'history';
import { TenantInfo } from '../../../common/tenant-info.i';
import { BiLogger } from '../../routes/bi';
import { publicCustomerPortalViewerClientEngagement } from '@wix/bi-logger-wix-answers/v2';

export const languageSelectKey = 'language-select';

export interface LanguageSelectProps {
  locales: string[];
  history: History<any>;
  mobile?: boolean;
  biLogger: BiLogger;
  component: 'header' | 'footer';
  sessionUserId?: string;
  tenant: TenantInfo;
}

export interface LanguageSelectState {
  isMenuOpen: boolean;
}

export class LanguageSelect extends React.PureComponent<
  LanguageSelectProps & WithTranslation,
  LanguageSelectState
> {
  state: LanguageSelectState = {
    isMenuOpen: false,
  };
  buttonRef!: HTMLButtonElement;

  componentDidMount() {
    if (this.buttonRef) {
      this.buttonRef.addEventListener('click', this.removeFocusFromButton);
    }
    document.addEventListener('click', this.handleOuterClick);
  }
  componentWillUnmount = () => {
    if (this.buttonRef) {
      this.buttonRef.removeEventListener('click', this.removeFocusFromButton);
    }
    document.removeEventListener('click', this.handleOuterClick);
  };

  handleOuterClick = (event: any) => {
    if (
      event.target &&
      this.buttonRef &&
      !this.buttonRef.contains(event.target)
    ) {
      this.closeLocaleMenu();
    }
  };

  removeFocusFromButton = () => {
    if (this.buttonRef && this.buttonRef.blur) {
      this.buttonRef.blur();
    }
  };

  onMobileChange = (currentLocal: string) => (event: any) => {
    window.location.href = window.location.href.replace(`/${currentLocal}`, `/${event.target.value}`);
  };

  renderMobileSelect = (currentLocal: string) => {
    const props = this.props;
    const mobileOptions = props.locales.map((locale, idx) => {
      return (
        <option className="mobile-picker-option" key={idx} value={locale}>
          {props.t(`language-${locale}`)}
        </option>
      );
    });

    return (
      <select value={currentLocal} onChange={this.onMobileChange(currentLocal)}>
        {mobileOptions}
      </select>
    );
  };

  closeLocaleMenu = () => {
    this.setState({ isMenuOpen: false });
  };

  toggleLocaleMenu = () => {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  };

  setButtonRef = (elem: HTMLButtonElement) => {
    this.buttonRef = elem;
  };

  sendBiEvent = async (locale: string) => {
    return this.props.biLogger.report(publicCustomerPortalViewerClientEngagement({
      engaged_component: this.props.component,
      engaged_sub_component: 'language_picker',
      engagement_name: 'select_a_language',
      data: locale,
      user_id: this.props.sessionUserId,
      answers_brand_id: this.props.tenant.brandId,
    }));
  };

  onSelectLocale = (locale: string, currentLocal: string) => async () => {
    this.closeLocaleMenu();
    var reg = new RegExp(`/${currentLocal}`, 'gi');
    const newPath = this.props.history.location.pathname.replace(reg, `/${locale}`);
    this.props.history.push(newPath);
    window.location.reload();
    await this.sendBiEvent(locale);
  };

  localeMenuItems = (currentLocal: string) => {
    const { props } = this;
    return props.locales.map((locale: string) => {
      return (
        <a
          className={classNames(`locale-item`, {
            chosen: currentLocal === locale,
          })}
          key={locale}
          onClick={this.onSelectLocale(locale, currentLocal)}
        >
          {props.t(`language-${locale}`)}
          {currentLocal === locale ? (
            <Icon className="chosen-icon" type={IconType.V_Icon} />
          ) : null}
        </a>
      );
    });
  };

  render() {
    const { props, state } = this;
    const currentLocale = props.tenant.currentLocale;
    const localeMenu = (
      <ul className={'locale-menu-wrapper'}>
        {this.localeMenuItems(currentLocale)}
      </ul>
    );

    const renderNormalPicker = (
      <div className={languageSelectKey}>
        <button
          ref={this.setButtonRef}
          className={classNames('language-select-button', {
            open: state.isMenuOpen,
          })}
          onClick={this.toggleLocaleMenu}
        >
          <span className="button-title">
            {this.props.t(`language-${currentLocale}`)}
          </span>
          <Icon
            type={IconType.SMALL_ARROW}
            className={classNames('select-icon', {
              open: state.isMenuOpen,
            })}
          />
        </button>
        {state.isMenuOpen ? localeMenu : null}
      </div>
    );

    return (
      <div>
        {props.mobile
          ? this.renderMobileSelect(currentLocale)
          : renderNormalPicker}
      </div>
    );
  }
}

LanguageSelect.contextType = HelpcenterContext;
export const LanguageSelectComp = withTranslation()(LanguageSelect);
