import { CompanyDisplayInfo, CompanyBuilder, ExtendedTicketBuilder, HelpcenterBrandingSettings, PageStructure, ReplyBuilder, TicketPriority, TicketStatus, TicketTimeline, TimelineItemType, TimelineReply, UserBuilder, ArticleType, Article, CustomField, ArticleResolution } from '@wix/answers-api';

export enum PreviewEventType {
  'UPDATE_PAGE_STRUCTURE',
  'UPDATE_BRANDING',
}

interface PreviewEventBase<T extends keyof PreviewPayload>
  extends MessageEvent {
  data: {
    type: 'helpcenter-preview';
    eventType: T;
    payload: PreviewPayload[T];
  };
}

type UpdateBrandingEvent = PreviewEventBase<PreviewEventType.UPDATE_BRANDING>;
type UpdatePageEvent = PreviewEventBase<PreviewEventType.UPDATE_PAGE_STRUCTURE>;

export type PreviewEvent = UpdateBrandingEvent | UpdatePageEvent;

export interface PreviewPayload {
  [PreviewEventType.UPDATE_BRANDING]: UpdateBrandingPayload;
  [PreviewEventType.UPDATE_PAGE_STRUCTURE]: UpdatePagePayload;
}

type UpdateBrandingPayload = HelpcenterBrandingSettings;
type UpdatePagePayload = {
  pageType: string;
} & Omit<PageStructure<any>, 'type'>;

export const PREVIEW_TICKET_ID = 'preview';

export const createSampleTicketPageModel = (customFields: CustomField[]) => {
  const userWithName = (name: string) => new UserBuilder().withFirstName(name).withLastName('').withFullName(name).build();
  const companyId = 'dummy-company-id';
  const ticketNumber = 123456789;
  const customFieldsMap = customFields.reduce((acc, curr) => ({...acc, [curr.name]: '-'}), {})
  const ticket = new ExtendedTicketBuilder()
    .withSubject('***Sample*** My website isn’t loading')
    .withUser(userWithName('Amy'))
    .withCompanyId(companyId)
    .withCreationDate(new Date(2019, 6, 19, 2, 26).getTime())
    .withAllCcUsers([userWithName('Mojo'), userWithName('Jojo')])
    .withStatus(TicketStatus.PENDING)
    .withPriority(TicketPriority.NORMAL)
    .withContent('Hi,\nI have a problem with my website, it isn’t loading properly.')
    .withAssignedUser(userWithName('Amy'))
    .withCustomFields(customFieldsMap)
    .withTicketNumber(ticketNumber)
    .build();
  const reply = new ReplyBuilder()
    .withContent('Hi Amy,\nThanks for reaching out.\nCan you tell me which browser you’re using?')
    .withUser(new UserBuilder().withFullName('David').withFirstName('David').withLastName('').withIsAgent(true).build())
    .withCreationDate(new Date(2019, 6, 19, 2, 26).getTime())
    .build();
  const timelineItems: TimelineReply[] = [{...reply, timelineItemType: TimelineItemType.REPLY}]
  const customerTimeline: TicketTimeline = { ticket, timelineItems };
  const companies: CompanyDisplayInfo[] = [{name: 'Wix Answers', id: companyId, logo: 'bob.com/logo.jpeg'}];

  return { customerTimeline, ticket, companies, customerPortalEnabled: true, customFields };
}

export const createSampleTicketListModel = () => {
  const minute = 60 * 1000;
  const day = 24 * 60 * minute;
  const companyId1 = 'dummy-company-id1';
  const companyId2 = 'dummy-company-id2';
  const companies = [new CompanyBuilder().withName('Wix Answers').withId(companyId1).build(), new CompanyBuilder().withName('Wix Answers').withId(companyId2).build()];
  const ticketNumber = 123456789;
  return {
    tickets: [
      new ExtendedTicketBuilder()
        .withSubject('***Sample*** My website isn’t loading')
        .withUser(new UserBuilder().withFullName('You').build())
        .withStatus(TicketStatus.OPEN)
        .withPriority(TicketPriority.HIGH)
        .withCreationDate(Date.now() - 3 * day)
        .withLastOpenedDate(Date.now() - day)
        .withCompanyId(companyId1)
        .withTicketNumber(ticketNumber)
        .build(),
      new ExtendedTicketBuilder()
        .withSubject('***Sample*** Label not added')
        .withUser(new UserBuilder().withFullName('You').build())
        .withStatus(TicketStatus.PENDING)
        .withPriority(TicketPriority.NORMAL)
        .withLastOpenedDate(0)
        .withCreationDate(Date.now() - 3 * day)
        .withLastAgentReplyDate(Date.now() - 45 * minute)
        .withCompanyId(companyId2)
        .withTicketNumber(ticketNumber)
        .build(),
      new ExtendedTicketBuilder()
        .withSubject('***Sample*** Call Center verification')
        .withUser(new UserBuilder().withFullName('You').build())
        .withStatus(TicketStatus.SOLVED)
        .withPriority(TicketPriority.LOW)
        .withLastOpenedDate(0)
        .withCreationDate(Date.now() - 3 * day)
        .withLastSolvedDate(Date.now() - 2 * day)
        .withCompanyId(companyId1)
        .withTicketNumber(ticketNumber)
        .build(),
    ],
    companies: [new CompanyBuilder().withName('Wix Answers').withId(companyId1).build(), new CompanyBuilder().withName('Wix Answers').withId(companyId2).build()],
    sessionUser: { companies },
    itemsCount: 3,
    page: 1
  }
}
type SampleArticle = Pick<Article, 'id' | 'type' | 'lastUpdateDate' | 'title' | 'uri' | 'resolution'>;
const createSampleFollowedArticle = (title: string, lastUpdateDate: number, type: ArticleType): SampleArticle => {
  const resolution = type === ArticleType.FEATURE_REQUEST ? ArticleResolution.COLLECTING_VOTES :
  type === ArticleType.KNOWN_ISSUE ? ArticleResolution.WORKING_ON_IT : undefined;
  return ({ title, lastUpdateDate, type, id: '', uri: '', resolution })
}

export const createSampleFollowedArticlesModel = () => {
  const followingArticles: SampleArticle[] = [
    createSampleFollowedArticle('Adding Multiple Help Centers Under One Wix Answers Account', new Date(2019, 5, 11).getTime(), ArticleType.FEATURE_REQUEST),
    createSampleFollowedArticle('Log in / Log out issues', new Date(2019, 5, 11).getTime(), ArticleType.KNOWN_ISSUE),
    createSampleFollowedArticle('Accepting a Transferred Premium Site Using SEPA as a Payment Met...', new Date(2018, 10, 17).getTime(), ArticleType.ARTICLE),
  ]
  return { followingArticles, itemsCount: 3, page: 1 };
}

