import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { toBabelLocale } from '@wix/answers-lib';

export default function i18n(locale, userOverrides = {}) {
  i18next
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .use({
      type: 'backend',
      read: (language, namespace, callback) => {
        // We configure how i18next should fetch a translation resource when it
        // needs it: We use Webpack's dynamic imports to fetch resources without
        // increasing our bundle size.
        //
        // See https://webpack.js.org/guides/code-splitting/#dynamic-imports for
        // more information.
        const babelLocale = toBabelLocale(language);
        return import(`../../locales/messages_${babelLocale}.json`)
          .then((translation) => callback(null, translation))
          .then(() => {
            i18next.addResourceBundle(
              locale,
              'translation',
              userOverrides,
              true,
              true,
            );
          })
          .catch((error) => callback(error));
      },
    })
    .init({
      // Initial language
      lng: locale,
      // Fallback language
      fallbackLng: 'en',

      // Don't use a key separator (no support for nested translation objects)
      keySeparator: false,

      // Wait for translation data to be available before rendering a component
      react: {
        useSuspense: true,
      },
    })
    .catch((err) => {
      throw err;
    });
  return i18next;
}
