import { publicCustomerPortalViewerClientEngagement } from '@wix/bi-logger-wix-answers/v2';
import * as React from 'react';
import { BiLogger } from '../../../routes/bi';
import { NavLinkJsx } from '../nav-link-jsx';

export const TicketListNavLink = ({ model, biLogger }: { model: any; biLogger: BiLogger }) => (
  props,
) => {
  const onClick = () => {
    return biLogger.report(publicCustomerPortalViewerClientEngagement({
      data: JSON.stringify({
        id: props.id,
        is_ticket_owner: props.isUserReporter,
        is_ticket_cc: props.isUserCC,
      }),
      engaged_component: 'ticket_page',
      engagement_name: 'click_go_back_to_support_request_list',
      answers_brand_id: model.tenant.brandId,
    }));
  };

  return (
    <NavLinkJsx className={props.className} uri={'/account'} onClick={onClick}>
      {props.children}
    </NavLinkJsx>
  );
};
