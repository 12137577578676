import * as React from 'react';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';
import { AppRenderRoutesAdditionalProps, AppConfig } from '..';
import { HelpcenterStructure, HelpcenterPageType, HelpcenterBrandingSettings } from '@wix/answers-api';
import { ComponentTypes } from '../../common/helpcenter-types';
import { Header } from '../page-components/header';
import { Footer } from '../page-components/footer';
import {
  MaybeAgentToolbar,
  getUserToolbarHeight,
  AgentToolbarContext,
} from '../utils/agent-toolbar';
import { AGENT_DISABLED_NOTIFICATION_HEIGHT, DisabledHelpcenterNotification } from '../utils/disabled-helpcenter-notification';
import { withExperiments, InjectedExperimentsProps } from '@wix/wix-experiments-react';

export interface RootRenderRoutesAdditionalProps
  extends AppRenderRoutesAdditionalProps {
  helpcenterStructure: HelpcenterStructure;
  branding: HelpcenterBrandingSettings;
  appConfig: AppConfig;
  modifiedModel: any;
}

export interface ToolbarActions {
  isExpanded: boolean;
  hasToolbar: boolean;
  onToolbarUpdate (cb: (hasToolbar: boolean, isExpanded: boolean) => void): void;
  onContextDataUpdate (context: AgentToolbarContext): void;
}

export interface RootComposerState {
  headerTopOffset: number;
  isExpanded: boolean;
  hasToolbar: boolean;
  toolbarContext: AgentToolbarContext;
}

class _RootComposer extends React.PureComponent<
  RouteConfigComponentProps & RootRenderRoutesAdditionalProps & InjectedExperimentsProps,
  RootComposerState
> {
  // static whyDidYouRender = true;
  onToolbarUpdateCb:
    | ((hasToolbar: boolean, isExpanded: boolean) => void)
    | undefined;

  state: RootComposerState = {
    isExpanded: true,
    hasToolbar: false,
    headerTopOffset: 0,
    toolbarContext: {
      pageType: HelpcenterPageType.HOMEPAGE,
    },
  };

  componentDidMount() {
    this.setState({headerTopOffset: this.getAgentDisabledNotificationHeight()});
  }

  getAgentDisabledNotificationHeight = () => {
    const { helpCenterSettings } = this.props.initialData.tenant;
    const isTicketingEnabled = this.props.experiments.enabled('specs.EnableCustomerPortal') && helpCenterSettings.ticketsPageEnabled;
    const disabledNotificationHeight = !helpCenterSettings.enabled && !isTicketingEnabled && !!this.props.initialData.isAgent ? AGENT_DISABLED_NOTIFICATION_HEIGHT : 0;
    return disabledNotificationHeight;
  }

  getHeaderOffsite = (isExpanded: boolean) => {
    return this.getAgentDisabledNotificationHeight() + getUserToolbarHeight(isExpanded);
  }

  doToolbarUpdateCb = () => {
    if (this.onToolbarUpdateCb) {
      this.onToolbarUpdateCb(this.state.hasToolbar, this.state.isExpanded);
    }
  };

  onToolbarExpansion = (isExpanded: boolean) => {
    this.setState(
      {
        isExpanded,
        headerTopOffset: this.getHeaderOffsite(isExpanded),
      },
      this.doToolbarUpdateCb,
    );
  };

  setHasToolbar = (hasToolbar: boolean) => {
    this.setState(
      {
        hasToolbar,
        headerTopOffset: hasToolbar
          ? this.getHeaderOffsite(this.state.isExpanded)
          : 0,
      },
      this.doToolbarUpdateCb,
    );
  };

  render () {
    const {
      route,
      templates,
      initialData,
      helpcenterStructure,
      baseModel,
      appConfig,
      modifiedModel,
      branding,
    } = this.props;

    const {
      isExpanded,
      headerTopOffset,
      hasToolbar,
      toolbarContext,
    } = this.state;

    const toolbarActions: ToolbarActions = {
      hasToolbar,
      isExpanded,
      onToolbarUpdate: (
        cb: (hasToolbar: boolean, isExpanded: boolean) => void,
      ) => (this.onToolbarUpdateCb = cb),
      onContextDataUpdate: data => {
        this.setState({ toolbarContext: data });
      },
    };

    const locale =
      initialData.tenant?.currentLocale ||
      initialData.tenant?.primaryLocale ||
      'en';

    const maybePreviewClassname = appConfig.preview ? 'is-preview' : '';
    const isTicketingEnabled = this.props.experiments.enabled('specs.EnableCustomerPortal') && initialData.tenant.helpCenterSettings.ticketsPageEnabled;
    const isAgentDisabledNotification = !initialData.tenant.helpCenterSettings.enabled && !isTicketingEnabled && !!this.props.initialData.isAgent;

    return (
      <div className={`viewer-root-container ${maybePreviewClassname}`}>
        {isAgentDisabledNotification ? <DisabledHelpcenterNotification settingsUrl={initialData.tenant.helpCenterSettings.domain.name} /> : null}
        <MaybeAgentToolbar
          preview={appConfig.preview}
          isExpanded={isExpanded}
          toggleExpansion={this.onToolbarExpansion}
          setHasToolbar={this.setHasToolbar}
          locale={locale}
          context={toolbarContext}
          topOffset={this.getAgentDisabledNotificationHeight()}
        />
        <Header
          template={templates[ComponentTypes.Header]}
          helpcenterStructure={helpcenterStructure}
          topOffset={headerTopOffset}
        />
        {renderRoutes(route?.routes, {
          templates,
          initialData,
          helpcenterStructure,
          toolbarActions,
          baseModel,
          modifiedModel,
          branding,
        })}
        <Footer
          template={templates[ComponentTypes.Footer]}
          helpcenterStructure={helpcenterStructure}
        />
      </div>
    );
  }
}

export const RootComposer = withExperiments(_RootComposer);