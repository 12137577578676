import { LocaleMatch } from '../routes';
import { ClientSafeTenant } from '../../../helpcenter-data';
import { helpcenterApi } from '../../../api';

export const getContactFormFields = (brandId: string, locale: string, baseUrl?: string) =>
  helpcenterApi
    .getContactCustomFields({params: { locale, id: brandId }}, baseUrl)
    .then((contactFormFields) => ({
      contactFormFields,
    }));

const loadDataClient = (
  params: LocaleMatch,
  tenant: ClientSafeTenant,
  location: any,
  _: any,
  baseUrl: string,
) => Promise.all([getContactFormFields(tenant.brandId, params.locale, baseUrl)]);

export default loadDataClient;
