export enum OGPageType {
    ARTICLE = 'Article',
    OTHER = 'Website',
}

export enum SeoContentPage {
    HOME = 'HOME_PAGE',
    SEARCH_RESULTS = 'SEARCH_RESULTS',
    CATEGORY = 'CATEGORY',
    ARTICLE = 'ARTICLE',
    CONTACT = 'CONTACT_PAGE',
}
