import * as React from 'react';

export const stripHtml = (html: string): string => {
  const htmlText =
    html &&
    html.replace &&
    html
      .replace(/([^<>]+)(?=<.+>[^<>]+)/g, '$& ')
      .replace(/<[^>]*?>/g, '')
      .replace(/&quot;/g, '"')
      .replace(/&apos;/g, "'")
      .replace(/&#39;/g, "'")
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&amp;/g, '&');
  return htmlText;
};

export const renderReplyTimestamp = (creationDate: number) => {
  const date = new Date(creationDate);
  return (
    <>
      {date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
      }) + ', '}
      {date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })}
    </>
  );
};
