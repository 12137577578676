import * as React from 'react';
import { UserMenuComp } from './header-user-menu';

export const HeaderUserMenu = ({ model, biLogger }) => (props) => {
  return (
    <UserMenuComp
      myCompanies={model.myCompanies}
      locale={model.tenant.currentLocale}
      user={model.sessionUser}
      biLogger={biLogger}
      tenant={model.tenant}
    >
      {props.children}
    </UserMenuComp>
  );
};
