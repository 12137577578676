import * as React from 'react';
import { LanguageSelectComp } from './language-select';
import { useHistory } from 'react-router';
import { TenantInfo } from '../../../common/tenant-info.i';

export const LanguageSelect = ({ model, biLogger }) => (props) => {
  // tslint:disable-next-line:react-hooks-nesting
  const history = useHistory();  
  return (
    <div>
      {model.tenant.helpCenterSettings.enabledLocales.length > 1 && (
        <LanguageSelectComp
          locales={
            (model.tenant as TenantInfo).helpCenterSettings.enabledLocales
          }
          biLogger={biLogger}
          history={history}
          component={props.component}
          sessionUserId={model.sessionUser?.id}
          tenant={model.tenant}
        />
      )}
    </div>
  );
};
