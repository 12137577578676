import * as React from 'react';
import {
  TicketTimeline,
  TimelineReply,
  TicketAttachment,
  onAttachmentUploadProps,
  Channel,
  ReplyType,
} from '@wix/answers-api';
import { TextArea, RenderHtml } from '@wix/answers-ui-libby';
import { WithTranslation, withTranslation } from 'react-i18next';
import { renderReplyTimestamp, stripHtml } from './utils';
import { HelpcenterContext } from '../../helpcenter-context';
import { classNames } from '@wix/answers-lib';
import { produce } from 'immer';
import { Attachments } from './attachments';
import { getTicketAvatar, TooltipComp } from '..';
import { HelpcenterUser } from '../../../common/helpcenter-types';
import { BiLogger } from '../../routes/bi';
import { publicCustomerPortalViewerClientEngagement, publicPageViewTicket } from '@wix/bi-logger-wix-answers/v2';

const ChatTicketChannels = [Channel.CHAT, Channel.FACEBOOK, Channel.WHATSAPP, Channel.SMS_INBOUND, Channel.SMS_OUTBOUND];
export const ticketPageKey = 'ticket-page-comp';

export interface ReplyGroupMap {
  [id: string]: TimelineReply[];
}

export interface TicketPageProps extends WithTranslation {
  biLogger: BiLogger;
  onUpload: onAttachmentUploadProps;
  onSubmitReply(
    ticketId: string,
    replyContent: string,
    attachments: TicketAttachment[],
    ccUserIds?: string[],
  ): Promise<TimelineReply>;
  timeline: TicketTimeline;
  allowedFileTypes: string[];
  customerPortalEnabled: boolean;
  sessionUser?: HelpcenterUser;
  brandId?: string;
}

export interface TicketPageState {
  replyContent: string;
  attachments: TicketAttachment[];
  isSubmitting: boolean;
  timeline?: TicketTimeline;
}

export class TicketPage extends React.PureComponent<
  TicketPageProps,
  TicketPageState
> {
  static contextType = HelpcenterContext;
  context!: React.ContextType<typeof HelpcenterContext>;

  state: TicketPageState = {
    replyContent: '',
    attachments: [],
    isSubmitting: false,
  };

  async componentDidMount() {
    const { timeline, biLogger } = this.props;
    this.setState({ timeline }, async () => {
      await biLogger.report(publicPageViewTicket({ ticket_id: timeline.ticket.id, answers_brand_id: this.props.brandId }));
    });
  }

  renderInfoTitle = (
    user: HelpcenterUser,
    hasNoContent: boolean,
    channel: Channel,
    attachments?: TicketAttachment[],
    official?: boolean,
  ) => {
    if (official && user.isAgent) {
      return (
        <span className="official-answer">
          {this.props.t('ticket-page.official-answer')}
        </span>
      );
    }

    if (channel === Channel.PHONE_CALLBACK && !hasNoContent) {
      return (
        <span className="callback-title">
          {this.props.t('ticket-page.callback.with-content')}
        </span>
      );
    }

    if (channel === Channel.EMAIL && !hasNoContent) {
      return (
        <span className="email-title">
          {this.props.t('ticket-page.created-from-email.with-content')}
        </span>
      );
    }

    if (hasNoContent && attachments?.length) {
      return attachments.length === 1 ? (
        <span className="singular-attachment-title">
          {this.props.t('ticket-page.attachments.no-content.singular')}
        </span>
      ) : (
        <span className="plural-attachment-title">
          {this.props.t('ticket-page.attachments.no-content.plural', {
            number: attachments.length,
          })}
        </span>
      );
    }
  };

  renderContent = (
    channel: Channel,
    user: HelpcenterUser,
    html?: string,
  ) => {
    const hasNoContent = !html || (!!html && stripHtml(html.trim()) === '');
    if (hasNoContent && channel === Channel.PHONE_CALLBACK) {
      return (
        <RenderHtml
          className="callback-content-placeholder"
          html={this.props.t('ticket-page.callback.no-content', {
            name: user.firstName,
          })}
        />
      );
    }

    if (hasNoContent && channel === Channel.PHONE_OUTBOUND) {
      return (
        <RenderHtml
          className="outbound-call-content-placeholder"
          html={this.props.t('ticket-page.outbound-call.no-content')}
        />
      );
    }

    if (hasNoContent && channel === Channel.EMAIL) {
      return (
        <RenderHtml
          className="email-content-placeholder"
          html={this.props.t('ticket-page.created-from-email.no-content', {
            name: user.firstName,
          })}
        />
      );
    }
    if (hasNoContent && channel === Channel.CHAT) {
      return null;
    }
    return html ? <RenderHtml className="html-content" html={html} /> : null;
  };

  renderTicketContents = () => {
    const ticket = this.state.timeline?.ticket;
    const fullName = ticket?.user.firstName;
    const html = ticket?.content || '';
    const firstMessage: TimelineReply = this.getReplyItems(this.state.timeline)[0];
    const isChatTicketChannel = ticket?.channel && ChatTicketChannels.includes(ticket?.channel);
    const updatedHtml = isChatTicketChannel && this.props.customerPortalEnabled ? firstMessage?.content : html;
    const hasNoContent = !html || (!!html && stripHtml(html.trim()) === '');

    const chatTicketTitle = (
      <div className="chat-content-placeholder">
        <RenderHtml
          html={this.props.t('ticket-page.chat.no-content', {
            userName: ticket?.user.firstName,
            channel: this.props.t(`ticket-page.ticket-channel.${ticket?.channel}`)
          })}
        />
      </div>
    );

    const showChatTicketTitle = hasNoContent && isChatTicketChannel;
    const nonChatTicket = !showChatTicketTitle;
    const showTicketContent = this.props.customerPortalEnabled || nonChatTicket;

    const ticketContents = (
      <>
      {showChatTicketTitle ? chatTicketTitle : null}
      {
        showTicketContent && ticket ? (
          <div className="ticket-content">
            <div className="user-avatar-wrapper">
              {getTicketAvatar(ticket.user)}
            </div>
            <div>
              <div className="user-name">{fullName}</div>
              <div className="user-bubble">
                <div className="reply-info">
                  {this.renderInfoTitle(
                    ticket.user,
                    hasNoContent,
                    ticket.channel,
                    ticket.attachments,
                  )}
                  {renderReplyTimestamp(ticket.creationDate)}
                </div>
                <div className="user-content">
                  {this.renderContent(
                    ticket.channel,
                    ticket.user,
                    updatedHtml,
                  )}
                  <div className="attachments-container">
                    {ticket.attachments
                      ? ticket.attachments.map((ticketAttachment) => this.renderAttachmentBubble(ticketAttachment))
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null
      }
      </>
    )
    return ticketContents;
  };

  renderReply = (reply: TimelineReply, official?: boolean) => {
    const html = reply.content || '';
    const hasNoContent = stripHtml(html.trim()) === '';
    const isAgentReply = reply.type === ReplyType.AGENT;
    return (
      <div
        className={classNames(
          isAgentReply ? 'agent-bubble' : 'user-bubble',
          {
            'no-content': hasNoContent,
          },
        )}
        key={reply.id}
      >
        <div className="reply-info">
          {this.renderInfoTitle(
            reply.user,
            hasNoContent,
            reply.channel,
            reply.attachments,
            official,
          )}
          <span>{renderReplyTimestamp(reply.creationDate)}</span>
        </div>
        <div className={isAgentReply ? 'agent-content' : 'user-content'}>
          {this.renderContent(reply.channel, reply.user, html)}
          <div className="attachments-container">
            {reply.attachments
              ? reply.attachments.map((ticketAttachment) => this.renderAttachmentBubble(ticketAttachment))
              : null}
          </div>
        </div>
      </div>
    );
  };

  renderAttachmentBubble = (
    ticketAttachment: TicketAttachment,
  ) => {
    const url = ticketAttachment.url.replace(/.*wixanswers.com/, '');
    const attachmentBubble = (
      <div className="attachment" key={url}>
          <a
            className="attachment-name link"
            href={url}
            target="_blank"
          >
            {ticketAttachment.name}
          </a>
      </div>
    );
    return attachmentBubble
  };

  renderMessage = (replies: TimelineReply[]) => {
    const fullName = replies[0].user.firstName;

    return (
      <div
        className={
          replies[0].type === ReplyType.AGENT ? 'agent-message' : 'user-message'
        }
        key={replies[0]?.id}
      >
        <div
          className={
            replies[0].type === ReplyType.AGENT
              ? 'agent-avatar-wrapper'
              : 'user-avatar-wrapper'
          }
        >
          {getTicketAvatar(replies[0].user)}
        </div>
        <div className='agent-avatar-wrapper-inner'>
          <div className={replies[0].user.isAgent ? 'agent-name' : 'user-name'}>
            {fullName}
          </div>
          {replies.map((reply, idx) => {
            const official = !idx && reply.user.isAgent;
            return this.renderReply(reply, official);
          })}
        </div>
      </div>
    );
  };

  groupTimelineReplies = (replies: TimelineReply[]) => {
    const repliesGroups: ReplyGroupMap = {};
    return produce(repliesGroups, (draft) => {
      replies.map((reply, idx, repliesArray) => {
        if (draft[reply.user.id]) {
          if (
            repliesArray[idx - 1] &&
            reply.user.id === repliesArray[idx - 1].user.id
          ) {
            draft[reply.user.id] = [...draft[reply.user.id], reply];
          } else {
            draft[idx] = draft[reply.user.id];
            draft[reply.user.id] = [reply];
          }
        } else {
          draft[reply.user.id] = [reply];
        }
      });
    });
  };

  onChangeReplyInput = (input: string) =>
    this.setState({ replyContent: input });

  onSubmitNewReply = async () => {
    const { timeline, replyContent, attachments, isSubmitting } = this.state;
    const canSubmit = replyContent.length > 0;

    const ccUserIds = this.props.customerPortalEnabled
      ? timeline?.ticket.ccUserIds
      : undefined;

    const isUserOwner =
      timeline?.ticket && timeline?.ticket.user.id === this.props.sessionUser?.id;
    const isUserCC = ccUserIds !== undefined
      ? ccUserIds.includes(this.props.sessionUser?.id || '')
      : false;
    if (this.props.customerPortalEnabled) {
      // tslint:disable-next-line: no-floating-promises
      this.props.biLogger.report(publicCustomerPortalViewerClientEngagement({
        engaged_component: 'ticket_page',
        engaged_sub_component: 'reply_area',
        engagement_name: 'click_on_send',
        answers_brand_id: this.props.brandId,
        data: JSON.stringify({
          ticket_id: timeline?.ticket.id,
          is_ticket_owner: isUserOwner,
          is_ticket_cc: isUserCC,
        }),
      }));
    }
    if (!isSubmitting && canSubmit && timeline) {
      this.setState({ isSubmitting: true });

      const newReply = await this.props.onSubmitReply(
        timeline.ticket.id,
        replyContent.replace(/(?:\r\n|\r|\n)/g, '<br>'),
        attachments,
        ccUserIds,
      );

      const timelineItems = [...timeline.timelineItems, newReply];
      this.setState({
        timeline: {
          ...timeline,
          timelineItems,
        },
        replyContent: '',
        attachments: [],
        isSubmitting: false,
      });
    }
  };

  onAttachmentsChange = (attachments: TicketAttachment[]) =>
    this.setState({ attachments });

  getReplyItems = (timeline?: TicketTimeline): TimelineReply[] => {
    const replies: TimelineReply[] = timeline?.timelineItems as TimelineReply[] || [];
    return replies.filter((item) => item.content !== undefined || item.attachments?.length);
  }

  render() {
    const { t, onUpload, customerPortalEnabled } = this.props;
    const { timeline, replyContent, isSubmitting } = this.state;
    const isChatTicketChannel = this.props.timeline?.ticket.channel && ChatTicketChannels.includes(this.props.timeline?.ticket.channel);

    const replyItems = this.getReplyItems(timeline)
    const updatedReplyItems = isChatTicketChannel && customerPortalEnabled ? replyItems.slice(1) : replyItems;
    const hasContent = replyContent.trim().length > 0;

    const groupedReplies = this.groupTimelineReplies(updatedReplyItems);

    const timelineReplies = Object.values(groupedReplies)
      .sort((a, b) => a[0].creationDate - b[0].creationDate)
      .map((replies: TimelineReply[]) => this.renderMessage(replies));

    const submitButton = (
      <button
        className={classNames('submit-button', {
          enabled: hasContent && !isSubmitting,
        })}
        onClick={this.onSubmitNewReply}
      >
        {t('ticket-page.submit-button')}
      </button>
    );

    const ccRecipients = this.props.timeline?.ticket.allCcUsers?.map(
      (recipient) => {
        const tooltipBody = (
          <>
            <div className="tooltip-recipient-avatar">
              {getTicketAvatar(recipient, 'xxsmall')}
            </div>
            <div>
              <div className="tooltip-recipient-name">{recipient.fullName}</div>
              {recipient.hasEmail ? (
                <div className="tooltip-recipient-email">{recipient.email}</div>
              ) : null}
            </div>
          </>
        );
        return (
          <TooltipComp
            body={tooltipBody}
            key={recipient.id}
            className={'cc-recipient'}
          >
            <span className="recipient-name">{recipient.fullName}</span>
          </TooltipComp>
        );
      },
    );

    const replyBoxHeader = (
      <div className="reply-box-header">
        <span className="prefix">
          {t('ticket-page.reply-box-header-prefix')}
        </span>
        <div className="recipients-container">{ccRecipients}</div>
      </div>
    );

    const isSubmitButtonDisabled = !hasContent || isSubmitting;
    const replyInput = (
      <>
        <div className="add-reply-title">
          {t('ticket-page.reply-action-title')}
        </div>
        <div className={'reply-input'}>
          <div className="reply-input-box-container">
            {this.props.customerPortalEnabled &&
              this.props.timeline.ticket.allCcUsers?.length
              ? replyBoxHeader
              : null}
            <TextArea
              value={replyContent}
              onChange={this.onChangeReplyInput}
              placeholder={t('ticket-page.reply-placeholder')}
              autoSize={true}
              maxRows={6} />
          </div>
          <div className="actions-wrapper">
            <Attachments
              attachments={this.state.attachments}
              captchaToken={this.context.captchaKey}
              onChange={this.onAttachmentsChange}
              onUpload={onUpload}
              allowedFileTypes={this.props.allowedFileTypes}
              biLogger={this.props.customerPortalEnabled ? this.props.biLogger : undefined}
              ticketId={this.props.timeline.ticket.id}
              customerPortalEnabled={this.props.customerPortalEnabled}
              brandId={this.props.brandId}
              t={t} />
            {this.props.customerPortalEnabled && isSubmitButtonDisabled ? (
              <TooltipComp
                className="submit-button-tooltip"
                body={t('ticket-page.submit-button.tooltip')}
              >
                {submitButton}
              </TooltipComp>
            ) : (
              submitButton
            )}
          </div>
        </div>
      </>
    );

    const replyInputOld = (
      <>
        <div className="add-reply-title">
            {t('ticket-page.reply-action-title')}
        </div>
        <div className={'reply-input'}>
          <TextArea
            value={replyContent}
            onChange={this.onChangeReplyInput}
            placeholder={t('ticket-page.reply-placeholder')}
            autoSize={true}
            maxRows={6}
          />
          <Attachments
            attachments={this.state.attachments}
            captchaToken={this.context.captchaKey}
            onChange={this.onAttachmentsChange}
            onUpload={onUpload}
            allowedFileTypes={this.props.allowedFileTypes}
            ticketId={this.props.timeline.ticket.id}
            brandId={this.props.brandId}
            biLogger={
              this.props.customerPortalEnabled ? this.props.biLogger : undefined
            }
            customerPortalEnabled={this.props.customerPortalEnabled}
            t={t}
          />
        </div>
        {submitButton}
      </>
    );
    const blockedChannelForReply =
      this.props.timeline?.ticket.channel === Channel.FACEBOOK ||
      this.props.timeline?.ticket.channel === Channel.WHATSAPP ||
      this.props.timeline?.ticket.channel === Channel.SMS_INBOUND ||
      this.props.timeline?.ticket.channel === Channel.SMS_OUTBOUND ||
      this.props.timeline?.ticket.channel === Channel.TWITTER ||
      this.props.timeline?.ticket.channel === Channel.INSTAGRAM;

    const blockedChannelMessage = (
      <span className="blocked-channel-text">
        {t('ticket-page.reply-box-blocked-text')}
        {t(`ticket-page.ticket-channel.${this.props.timeline?.ticket.channel}`)}
      </span>
    );

    const ticketPageWrapper = (
      <div className="ticket-page-content">

        <div className='ticket-body'>
          <div className="ticket-title">{timeline?.ticket.subject}</div>
          {this.renderTicketContents()}
          <div className="replies-header">
            {t('ticket-page.replies-title')}
            <span className="replies-count">({this.props.customerPortalEnabled ? updatedReplyItems.length : replyItems.length})</span>
          </div>
          {timeline ? timelineReplies : <div>loading...</div>}
        </div>

        <div>
          <div className="divider" />
          <div className="reply-footer">
            {this.props.customerPortalEnabled && blockedChannelForReply
              ? blockedChannelMessage
              : this.props.customerPortalEnabled
              ? replyInput
              : replyInputOld}
          </div>
        </div>
      </div>
    );

    return <div className={ticketPageKey}>{ticketPageWrapper}</div>;
  }
}

TicketPage.contextType = HelpcenterContext;
export const TicketPageComp = withTranslation()(TicketPage);
