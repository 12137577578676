import { Collapsible, BaseProps } from '@wix/answers-ui-libby';
import * as React from 'react';
import { classNames } from '@wix/answers-lib';

type CollapsibleProps = {
  title: string;
  itemsCount: number;
} & BaseProps;

export const MaybeCollapsible = (props: CollapsibleProps) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const onToggle = (val: boolean) => setIsOpen(val);
  const itemsCount = Number(props.itemsCount) || 0;
  const titleWithCount = (
    <div className={'maybe-collapsible-title'}>
      {props.title}
      <span className="count">{`(${itemsCount})`}</span>
    </div>
  );
  return itemsCount ? (
    <Collapsible
      className={props.className}
      title={titleWithCount}
      isOpen={isOpen}
      onToggle={onToggle}
    >
      {props.children}
    </Collapsible>
  ) : (
    <div className={classNames('empty-state', props.className)}>
      {titleWithCount}
      {props.children}
    </div>
  );
};
