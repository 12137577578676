import React, { memo } from 'react';
import { useHistory } from 'react-router';
import { renderRoutes } from 'react-router-config';

export const OuterPageContent = memo<any>(
  (props) => {
    const {
      route,
      initialData,
      templates,
      helpcenterStructure,
      toolbarActions,
      baseModel,
      modifiedModel,
      branding,
    } = props;
    const history = useHistory();

    return (
      <div className="outer-page-container">
        <div className="page-wrapper">
          {renderRoutes(route.routes, {
            templates,
            initialData,
            helpcenterStructure,
            baseModel,
            toolbarActions,
            history,
            modifiedModel,
            branding,
          })}
        </div>
      </div>
    );
  },
  () => false,
);
