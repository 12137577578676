import * as React from 'react';
import { ArticleViewComp, Feedback } from './article-view';
import { useHistory } from 'react-router';
import {
  CoreArticleSectionConfig,
  CoreArticleFeatureRequestSectionConfig,
  CoreArticleKnownIssueSectionConfig,
  VoteFeedbackMode,
} from '@wix/answers-api';
import { contactOptionDisabled, getContactOptions } from '../../../common/contact-utils';
import { withExperiments } from '@wix/wix-experiments-react';
import { BiLogger } from '../../routes/bi';

export const ArticleViewer = ({
  model,
  config,
  biLogger,
}: {
  model: any;
  config:
    | CoreArticleSectionConfig
    | CoreArticleFeatureRequestSectionConfig
    | CoreArticleKnownIssueSectionConfig;
  biLogger: BiLogger;
}) => withExperiments((props) => {
  const allowFeedback =
    (config as CoreArticleSectionConfig).allowFeedback ||
    (config as CoreArticleFeatureRequestSectionConfig).allowVote ||
    (config as CoreArticleKnownIssueSectionConfig).allowReport;

  const feedback: Feedback = {
    allowFeedback,
    feedbackMessage: config.feedbackMessage,
    contactActionButtonText: (config as CoreArticleSectionConfig)
      .contactActionButtonText,
    contactMessage: (config as CoreArticleSectionConfig).contactMessage,
    voteFeedbackMode:
      (config as CoreArticleSectionConfig).voteFeedbackMode ||
      VoteFeedbackMode.FEEDBACK_MESSAGE,
  };

  // tslint:disable-next-line:react-hooks-nesting
  const history = useHistory();
  const contactOption = getContactOptions(model.tenant);
  const submitTicketsEnabled =
    model.sessionUser ||
    model.tenant.helpCenterSettings.authentication.enableGuestTickets;
  return (
    <div>
      {model.article && (
        <ArticleViewComp
          tenant={model.tenant}
          article={model.article}
          history={history}
          feedbackConfig={feedback}
          biLogger={biLogger}
          submitTicketsEnabled={submitTicketsEnabled}
          contactOptionDisabled={contactOptionDisabled(contactOption)}
        />
      )}
    </div>
  );
});
