import {
    CategoriesLayout,
    CategoriesSectionData,
    Category,
    CoreCategoriesSectionData,
    HelpcenterSectionType, 
  } from '@wix/answers-api';
  import { helpcenterApi } from '../../../../api';
  const displayedCategoriesLimit = 50;
  export const categoriesSectionsDataLoader = async ({
    locale,
    categoryTree,
    section,
  }: {
    locale: string;
    categoryTree: Category[];
    section: CategoriesSectionData | CoreCategoriesSectionData;
  }) => {
    if (section.config.layout === CategoriesLayout.DETAILED_LIST && section.config.showExtraLevels) {
        const limit = section.type === HelpcenterSectionType.CATEGORIES ? section.config.visibleItemsLimit : displayedCategoriesLimit;
        const subcategories = 
            categoryTree.reduce(((flattenSubcats: Category[], cat, idx) => [...flattenSubcats, ...(idx <= limit && cat.children || [])]), []);
        const subcategoryArticles = await helpcenterApi.getCategoryTopArticles({
            locale,
            categoryIds: subcategories.map((c) => c.id) || [],
        });

        return {
            subcategoryArticles,
        };
    }
  };