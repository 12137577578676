import * as React from 'react';

export const CloseButton = ({
  onClick,
}: {
  onClick(e?: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}) => ({ children }) => {
  return (
    <button onClick={onClick} className={'close-button'}>
      {children}
    </button>
  );
};
