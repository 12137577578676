import { HelpcenterSectionType, BaseSegment } from '@wix/answers-api';
import * as ejs from 'ejs';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  ComponentTypes,
  SectionModel,
  TemplateFromBlueprint,
} from '../../../../common/helpcenter-types';
import { JsxParser } from '../jsx-parser';
import { resolveConfig } from './helpers';
import { TemplateRenderError } from '.';
import { utils } from '../utils';
import { HelpcenterContext } from '../../../helpcenter-context';
import { Logger } from '@wix/answers-bi';

export interface TemplateCompilerProps extends WithTranslation {
  sectionTemplate: TemplateFromBlueprint;
  baseSegment: BaseSegment;
  model: SectionModel;
  segmentConfig: any;
  onLoad?(): void;
  segmentType: HelpcenterSectionType | ComponentTypes;
  strict?: boolean;
  components?: Record<
    string,
    | (({ model, biLogger }: { model: any; biLogger?: Logger }) => JSX.Element)
    | React.ComponentType<any>
  >;
  bindings?: Record<string, Function>;
}

const filterComments = (template: string) => template ? template.replace(/<!--.*?-->/g, '') : template;

export class _TemplateCompiler extends React.PureComponent<
  TemplateCompilerProps
> {
  static contextType = HelpcenterContext;
  context!: React.ContextType<typeof HelpcenterContext>;

  private determineTemplate(): string {
    const { codeOverridesEnabled, codeOverrides } = this.props.baseSegment;
    if (codeOverridesEnabled && codeOverrides?.template) {
      return codeOverrides.template;
    }
    return this.props.sectionTemplate.template;
  }

  render() {
    const {
      onLoad,
      model,
      t,
      i18n,
      segmentConfig,
      segmentType,
      strict = false,
      components,
      bindings
    } = this.props;
    const locale = i18n.language;

    const config = resolveConfig(segmentType, segmentConfig, strict);

    try {
      const renderedTemplate = ejs.render(this.determineTemplate(), {
        config,
        model,
        t,
        locale,
        utils: utils(model, this.context.Parser),
      });

      const realTemplate = filterComments(renderedTemplate);

      return (
        <JsxParser
          model={model}
          jsx={realTemplate}
          onLoad={onLoad}
          strict={strict}
          config={segmentConfig}
          components={components}
          bindings={bindings}
        />
      );
    } catch (error: any) {
      if (strict) {
        throw new TemplateRenderError(error);
      }
      console.log('section compile error', { segmentType }, error);
      return <div className="loading-2">loading...</div>;
    }
  }
}
_TemplateCompiler.contextType = HelpcenterContext;

export const TemplateCompiler = withTranslation()(_TemplateCompiler);
// (TemplateCompiler as any).whyDidYouRender = true;
