import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import DeprecatedSelect, { components, IndicatorComponentType, MultiValueProps, OptionProps, OptionsType } from 'react-select';
import { classNames } from '@wix/answers-lib';
import { Icon, IconType } from '../icons';
import { TooltipComp } from '../tooltip';
import i18next from 'i18next';

export interface Option {
  value: string | number;
  label: string;
}
export interface TicketFilterMultiSelectProps {
  name: string;
  options: Option[];
  value: Option[];
  open: boolean;
  className?: string;
  setOpen (open: boolean): void;
  onChange (option: Option[]): void;
}

export const OPTION_ALL = { value: 'ALL', label: 'All' };

export const ticketFilterMultiSelectKey = 'ticket-filter-multi-select';

const MultiValueComp = (props: MultiValueProps<Option> & { name: string; t: i18next.TFunction }) => {
  const isFirst = props.getValue()[0].value === props.data.value;
  const ref = React.useRef<HTMLSpanElement | null>(null);
  const [ellipsis, setEllipsis] = React.useState(false);
  const updateEllipsis = () => {
    if (ref.current) {
      const { scrollWidth, clientWidth } = ref.current;
      setEllipsis(scrollWidth <= clientWidth);
    }
  };
  const value = props.getValue();
  const allValue = value[0].value === OPTION_ALL.value;
  const valuesPreview = allValue 
    ? props.t(`${ticketFilterMultiSelectKey}.option-all`)
    : value.map(option => option.label).join(', ');
  return isFirst ? (
    <>
      <span className={`${ticketFilterMultiSelectKey}-name`}>{props.name}: </span>
      <TooltipComp body={valuesPreview} relativeToBody={true} forceHidden={ellipsis}>
        <span className={classNames(`${ticketFilterMultiSelectKey}-value`, { 'all-value': allValue })} ref={ref} onMouseEnter={updateEllipsis}>{valuesPreview}</span>
      </TooltipComp>
    </>
  ) : null;
}

const DropdownIndicator: IndicatorComponentType<Option, true> = () => (
    <Icon className={`${ticketFilterMultiSelectKey}-drop-down-icon`} type={IconType.DROP_DOWN_ARROW} />
);

const Option = ({children, ...rest}: OptionProps<Option, true>) => {
  return (
    <components.Option {...rest}>
      <span>{children}</span>
      {rest.isSelected ? <Icon className='selected-option-icon' type={IconType.V_Icon} /> : null}
    </components.Option>
  );
};

const _TicketFilterMultiSelect = (props: TicketFilterMultiSelectProps & WithTranslation) => {

  const onChange = (newOptions: OptionsType<Option>) => {
    if (!newOptions.length) {
      props.onChange([OPTION_ALL]);
      return;
    }
    const oldValues = props.value.map(option => option.value);
    const newValues = newOptions.map(option => option.value);
    const addedValue = newValues.find(value => !oldValues.includes(value));
    if (addedValue === OPTION_ALL.value) {
      props.onChange([OPTION_ALL]);
      return;
    }
    const newOptionsWithoutAll = newOptions.filter(option => option.value !== OPTION_ALL.value);
    props.onChange(newOptionsWithoutAll);
  };

  return (
    <DeprecatedSelect
      className={classNames(ticketFilterMultiSelectKey, props.className)}
      classNamePrefix={ticketFilterMultiSelectKey}
      options={[ { label: props.t(`${ticketFilterMultiSelectKey}.option-all`), value: OPTION_ALL.value }, ...props.options ]}
      value={props.value}
      onChange={onChange}
      isMulti={true}
      isClearable={false}
      openMenuOnFocus={true}
      onMenuOpen={() => props.setOpen(true)}
      onMenuClose={() => props.setOpen(false)}
      defaultMenuIsOpen={props.open}
      autoFocus={props.open}
      closeMenuOnSelect={false}
      components={{
        DropdownIndicator,
        Option,
        MultiValue: multiValueProps => <MultiValueComp {...multiValueProps} name={props.name} t={props.t} />,
      }}
      hideSelectedOptions={false}
      blurInputOnSelect={false}
    />
  );
};

export const TicketFilterMultiSelect = withTranslation()(_TicketFilterMultiSelect);
