import {
  HelpcenterSectionType,
  ArticleListSectionData,
  Article,
} from '@wix/answers-api';
import { helpcenterApi } from '../../../../api';
import { SectionDataLoader } from '../common/types';

export const articleListSectionDataLoader: SectionDataLoader<HelpcenterSectionType.ARTICLE_LIST> = async ({
  section,
  locale,
}: {
  section: ArticleListSectionData;
  locale: string;
}) => {
  const featuredArticles = await Promise.allSettled(
    (section.config.articleIds || []).map((articleId) => {
      return helpcenterApi.getArticleById(articleId, {
        params: { locale },
      });
    }),
  ).then((values) =>
    values
      .filter((item) => item.status === 'fulfilled')
      .map((item) => (item as PromiseFulfilledResult<Article>).value),
  );

  return {
    featuredArticles,
  };
};
