import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// tslint:disable-next-line: no-floating-promises
i18n.use(initReactI18next).init({
  fallbackLng: 'en',

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  react: {
    useSuspense: false,
  },
});
// tslint:disable-next-line: no-floating-promises
i18n.changeLanguage('en');

export default i18n;
