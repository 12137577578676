import * as React from 'react';
import Downshift from 'downshift';
import { Icon, IconType } from '../icons';
import { WithTranslation, withTranslation } from 'react-i18next';
import { HelpcenterContext } from '../../helpcenter-context';
import { classNames, debounce } from '@wix/answers-lib';
import { History } from 'history';
import { Article } from '@wix/answers-api';
import * as logAnalytics from './log-analytics';
import { helpcenterApi } from '../../../api';
import { ContentFilters } from '../../utils/sdk/domain';
import {
  sdkEventListenersEmitter,
  sdkSetContentFiltersEmitter,
} from '../../utils/sdk';
import { useFedopsLogger as getFedopsLogger } from '../../utils/fedops-logger';
import { HelpcenterListenersType } from '../../utils/sdk/helpcenter-event';
import { BiLogger } from '../../routes/bi';
import { publicNoSearchResults, publicSearch, publicSeeAllResults, publicSelectSearchResult } from '@wix/bi-logger-wix-answers/v2';
export const searchKey = 'search-box';

export const encodeClientSearchTerm = (term: string) =>
  btoa(encodeURIComponent(term));
export const decodeClientSearchTerm = (term: string) =>
  decodeURIComponent(atob(term));
export interface SearchProps {
  locale: string;
  resultsCount?: number;
  logger: BiLogger;
  history: History<any>;
  brandId: string;
  sdkContentFilters?: ContentFilters;
}

const DEFAULT_RESULTS_COUNT = 5;
const RESULTS_DEBOUNCE_TIMER = 300;

export interface SearchState {
  filteredArticles?: Article[];
  searchVal?: string;
  contentFilters?: ContentFilters;
}

class Search extends React.PureComponent<
  SearchProps & WithTranslation,
  SearchState
> {
  static contextType = HelpcenterContext;
  context!: React.ContextType<typeof HelpcenterContext>;

  state: SearchState = {};
  downShiftRef = null;

  cleanSdkContentFiltersListener: any = null;

  fedopsLogger = getFedopsLogger();

  resultsCount = this.props.resultsCount || DEFAULT_RESULTS_COUNT;
  componentDidMount() {
    this.setState({ contentFilters: sdkSetContentFiltersEmitter.getLast()});

    this.cleanSdkContentFiltersListener = sdkSetContentFiltersEmitter.addListener(
      (contentFilters) => {
        this.setState({ contentFilters });
      },
    );
  }
  componentWillUnmount() {
    if (this.cleanSdkContentFiltersListener) {
      this.cleanSdkContentFiltersListener();
    }
  }
  getFilteredArticles = async (inputValue: string) => {
    const contentFilters = this.state.contentFilters || {}

    this.fedopsLogger.startInteraction('article_search');
    const articlesResults = await helpcenterApi.searchArticles({
      brandId: this.props.brandId,
      locale: this.props.locale,
      text: inputValue,
      page: 1,
      pageSize: this.resultsCount + 1, //+1 is for the show more button in case there are more then resultCount articles
      spellcheck: true,
      ...contentFilters,
    });
    const articles = articlesResults.data;
    this.fedopsLogger.endInteraction('article_search');
    sdkEventListenersEmitter.publish({
      event: HelpcenterListenersType.SEARCH_ENTERED_VALUE,
      data: {
        searchTerm: inputValue,
        resultsCount: articlesResults.itemsCount,
        locale: this.props.locale,
      },
    });
    this.setState({ filteredArticles: articles });
    if (articles.length === 0) {
      logAnalytics.resultNone(inputValue, this.props.locale);
      sdkEventListenersEmitter.publish({
        event: HelpcenterListenersType.SEARCH_NO_RESULTS,
        data: {
          searchTerm: inputValue,
          locale: this.props.locale,
        },
      });
      return this.props.logger.report(publicNoSearchResults({
        search_term: inputValue,
        answers_brand_id: this.props.brandId,
      }));
    }
    await this.props.logger.report(publicSearch({
      search_term: inputValue,
      result_article_id_1: articles[0] ? articles[0].id : undefined,
      result_article_id_2: articles[1] ? articles[1].id : undefined,
      result_article_id_3: articles[2] ? articles[2].id : undefined,
      results_count: articlesResults.itemsCount,
      answers_brand_id: this.props.brandId,
    }));
  };

  onSelect = (selected: Article) => {
    const { history } = this.props;
    const { filteredArticles = [], searchVal = '' } = this.state;

    //check if selected is show more or real article
    if (
      filteredArticles.length > this.resultsCount &&
      selected.id === filteredArticles[this.resultsCount].id
    ) {
      logAnalytics.resultClickMore(searchVal, this.props.locale);
      sdkEventListenersEmitter.publish({
        event: HelpcenterListenersType.SEARCH_SEE_MORE_CLICKED,
        data: {
          searchTerm: searchVal,
          locale: this.props.locale,
        },
      });
      // tslint:disable: no-floating-promises
      this.props.logger.report(publicSeeAllResults({
        answers_brand_id: this.props.brandId,
        search_term: searchVal,
        result_article_id_1: filteredArticles[0]
          ? filteredArticles[0].id
          : undefined,
        result_article_id_2: filteredArticles[1]
          ? filteredArticles[1].id
          : undefined,
        result_article_id_3: filteredArticles[2]
          ? filteredArticles[2].id
          : undefined,
      }));
      history.push(
        `/${this.props.locale}/search?term=${encodeClientSearchTerm(
          this.state.searchVal || '',
        )}`,
      );
    } else {
      const { uri } = selected;
      const _uri = uri.startsWith('/') ? uri : `/${uri}`;
      const index = filteredArticles.indexOf(selected);

      logAnalytics.resultArticleClicked(
        searchVal,
        selected.id,
        index,
        this.props.locale,
      );

      sdkEventListenersEmitter.publish({
        event: HelpcenterListenersType.SEARCH_RESULT_CLICKED,
        data: {
          searchTerm: searchVal,
          articleId: selected.id,
          index,
          locale: this.props.locale,
        },
      });

      // tslint:disable: no-floating-promises
      this.props.logger.report(publicSelectSearchResult({
        search_term: this.state.searchVal,
        article_id: selected.id,
        category_id: selected.categoryId,
        answer_index: index,
      }));
      history.push(`/${this.props.locale}${_uri}`);
    }
  };

  onInputChange = debounce((inputValue) => {
    inputValue && this.state.searchVal
      ? this.getFilteredArticles(inputValue).catch((e) => null)
      : this.setState({ filteredArticles: undefined });
  }, RESULTS_DEBOUNCE_TIMER);

  onPressEnter = (e: any) => {
    const { history } = this.props;
    if (
      e.key === 'Enter' &&
      this.state.searchVal &&
      this.state.searchVal.trim().length > 0
    ) {
      history.push(
        `/${this.props.locale}/search?term=${encodeClientSearchTerm(
          this.state.searchVal,
        )}`,
      );
      logAnalytics.resultClickMore(this.state.searchVal, this.props.locale);
      sdkEventListenersEmitter.publish({
        event: HelpcenterListenersType.SEARCH_SEE_MORE_CLICKED,
        data: {
          searchTerm: this.state.searchVal,
          locale: this.props.locale,
        },
      });
    }
  };

  onChangeState = (changes) => {
    if (
      changes.type === Downshift.stateChangeTypes.changeInput &&
      changes.inputValue !== this.state.searchVal
    ) {
      this.setState({ searchVal: changes.inputValue }, () => {
        this.onInputChange(changes.inputValue);
      });
    }
  };

  setRef = (e: any) => {
    if (e) {
      this.downShiftRef = e;
    }
  };

  renderMenuItems = (getItemProps: any, highlightedIndex: number | null) => {
    const { filteredArticles = [] } = this.state;
    return filteredArticles.map((item, index) => {
      if (index < this.resultsCount) {
        return (
          <li
            className={classNames('option-item', {
              highlighted: highlightedIndex === index,
            })}
            {...getItemProps({
              key: item.title,
              index,
              item,
            })}
          >
            {item.title}
          </li>
        );
      }
      if (index === this.resultsCount) {
        return (
          <li
            className={classNames('show-more-results', {
              highlighted: highlightedIndex === index,
            })}
            {...getItemProps({
              key: 'show-more',
              index,
              item,
            })}
          >
            {this.props.t('search-box-show-more')}
            <Icon className="arrow-icon" type={IconType.NAV_ARROW} />
          </li>
        );
      }
    });
  };

  onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { searchVal } = this.state;
    e.preventDefault();
    if (searchVal) {
      logAnalytics.resultIgnored(searchVal, this.props.locale);
      sdkEventListenersEmitter.publish({
        event: HelpcenterListenersType.SEARCH_IGNORED_RESULTS,
        data: {
          searchTerm: searchVal,
          locale: this.props.locale,
        },
      });
      if (this.downShiftRef) {
        (this.downShiftRef as any).reset({
          inputValue: searchVal,
        });
      }
      this.setState({ searchVal: undefined });
    }
  };

  renderNoResults = () => {
    const { filteredArticles } = this.state;
    if (filteredArticles) {
      return (
        <li className="no-results">{this.props.t('search-box-no-results')}</li>
      );
    }
    return null;
  };

  render() {
    const { filteredArticles, searchVal = '' } = this.state;
    return (
      <div className={searchKey}>
        <Downshift
          id="search-down-shift"
          ref={this.setRef}
          onChange={(selection) => this.onSelect(selection)}
          onStateChange={this.onChangeState}
          itemToString={() => searchVal}
        >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            isOpen,
            inputValue,
            clearSelection,
            highlightedIndex,
          }) => (
            <div>
              <div className="search-bar">
                <div className="search-icon-wrapper">
                  <Icon type={IconType.LUPA} />
                </div>
                <input
                  {...getInputProps({
                    onBlur: this.onBlur,
                  }) as any}
                  placeholder={this.props.t('search-box-placeholder')}
                  value={inputValue as any}
                  onKeyPress={this.onPressEnter}
                  className="search-box-input"
                />
                {inputValue !== '' ? (
                  <div className="x-wrapper" onClick={() => clearSelection()}>
                    <Icon type={IconType.X_ICON} />
                  </div>
                ) : null}
              </div>
              <ul {...getMenuProps()}>
                {isOpen && filteredArticles && searchVal !== ''
                  ? filteredArticles.length
                    ? this.renderMenuItems(getItemProps, highlightedIndex)
                    : this.renderNoResults()
                  : null}
              </ul>
            </div>
          )}
        </Downshift>
      </div>
    );
  }
}

Search.contextType = HelpcenterContext;
export const SearchComp = withTranslation()(Search);
