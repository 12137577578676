import * as React from 'react';

export const GetTextContent = ({ content }) => {
  const text = content
    .replace(/<[^>]*>?/gm, '')
    .replace(/&quot;/gm, '"')
    .replace(/&apos;/gm, "'")
    .replace(/&#39;/gm, "'")
    .replace(/&lt;/gm, '<')
    .replace(/&gt;/gm, '>')
    .replace(/&amp;/gm, '&')
    .substring(0, 210);
  return <span>{text}</span>;
};
