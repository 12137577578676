import * as React from 'react';
import { deepBranch } from '../../../common/utils';
import { useFedopsLogger as getFedopsLogger } from '../../utils/fedops-logger';
import { NavLinkJsx } from '../../components-for-sections/nav-links';
import { Icon, IconType } from '../../components-for-sections/icons';

export const ParentCategoriesList = ({ model }) => (props: {
  categoryId: string;
}) => {
  const { categoryId } = props;
  const parentsList = deepBranch<any, any>(
    model.categoryTree,
    'children',
    (item) => item.id === categoryId,
  );

  const listOfParents = parentsList.length ? (
    parentsList.map((category) => {
      const { uri = '' } = category;
      const fedopsLogger = getFedopsLogger();
      const startNavigateToCategoryInteraction = () => {
        fedopsLogger.startInteraction('category_navigation');
      };
      return (
        <div className="parent" key={category.id}>
          <NavLinkJsx
            uri={uri}
            state={category.id}
            onClick={startNavigateToCategoryInteraction}
          >
            <h3 className="category-name">{category.name}</h3>
          </NavLinkJsx>
          <Icon className="arrow-icon" type={IconType.SMALL_ARROW} />
        </div>
      );
    })
  ) : (
    <div />
  );
  return <div className="parents-list-container">{listOfParents}</div>;
};
