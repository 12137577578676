import {
  TICKET_PAGE_NAME,
  TICKET_PAGE_TYPE,
} from './../../common/helpcenter-types';
import { RouteConfig } from 'react-router-config';
import { RootComposer } from './root-composer';
import { OuterPageContent } from './outer-page';
import {
  PageContent,
  CategoryPagesRenderer,
  ArticlePageRenderer,
} from './page-content-renderer';
import searchResultsPageDataLoader from './page-data-loaders/search-results-data-loader';
import articlePageDataLoader from './page-data-loaders/article-data-loader';
import getContactFormFields from './page-data-loaders/contact-data-loader';
import categoryPageDataLoader from './page-data-loaders/category-data-loader';
import { ClientSafeTenant } from '../../helpcenter-data';
import { HelpcenterPageType, HelpcenterPageNames, PremiumStatus } from '@wix/answers-api';
import {
  PROFILE_PAGE_TYPE,
  PROFILE_PAGE_NAME,
} from '../../common/helpcenter-types';
import { ContentFilters } from '../utils/sdk/domain';
export interface HelpcenterRouteConfig extends RouteConfig {
  loadDataClient(
    params: LocaleMatch,
    tenant?: ClientSafeTenant,
    location?: any,
    initialData?: any,
    baseUrl?: string,
    sdkContentFilters?: ContentFilters,
  ): Promise<any>;
  requireToken?: boolean;
  routes?: HelpcenterRouteConfig[];
  pageType?: HelpcenterPageType;
  id: 'locale' | 'root' | HelpcenterPageNames;
}
 
const localeMatcher = '/:locale([a-z]{2}|[a-z]{2}-[a-z]{2,4})';

export const getRoutes = (
  options: {
    enableContactPage?: boolean;
    enableCustomerPortal?: boolean;
    isHelpcenterEnabled?: boolean;
    isTicketingEnabled?: boolean;
    premiumData?: PremiumStatus;
  } = {},
): HelpcenterRouteConfig[] => {
  const genericHelpcenterRoutes = [
    {
      id: HelpcenterPageNames.SEARCH_RESULTS,
      path: `${localeMatcher}/search`,
      component: PageContent as any,
      loadDataClient: searchResultsPageDataLoader,
      pageType: HelpcenterPageType.SEARCH_RESULTS,
    },
    {
      id: HelpcenterPageNames.ARTICLE,
      path: `${localeMatcher}/article/:articleUri`,
      component: ArticlePageRenderer,
      loadDataClient: articlePageDataLoader,
      pageType: HelpcenterPageType.ARTICLE,
    },
    {
      id: HelpcenterPageNames.CATEGORIES,
      path: `${localeMatcher}/categories`,
      component: PageContent as any,
      loadDataClient: () => Promise.resolve(),
      pageType: HelpcenterPageType.CATEGORIES,
    },
    {
      id: HelpcenterPageNames.CATEGORY,
      path: `${localeMatcher}/:categoryUri0/:categoryUri1?/:categoryUri2?/:categoryUri3?/:categoryUri4?`,
      component: CategoryPagesRenderer,
      loadDataClient: categoryPageDataLoader,
      pageType: HelpcenterPageType.SUB_CATEGORIES,
    },
  ];
  const genericTicketingRoutes = [
    {
      id: HelpcenterPageNames.REQUEST_LIST,
      path: `${localeMatcher}/account`,
      component: PageContent as any,
      loadDataClient: () => Promise.resolve(),
      pageType: HelpcenterPageType.REQUEST_LIST,
      requireToken: true,
    },
    {
      id: HelpcenterPageNames.REQUEST_PAGE,
      path: `${localeMatcher}/ticket/:ticketId`,
      exact: true,
      component: PageContent as any,
      loadDataClient: () => Promise.resolve(),
      pageType: HelpcenterPageType.REQUEST_PAGE,
      requireToken: true,
    },
  ]
  const onlyHelpcenter = [
    {
      id: HelpcenterPageNames.FOLLOWED_ARTICLES,
      path: `${localeMatcher}/account`,
      component: PageContent as any,
      loadDataClient: () => Promise.resolve(),
      pageType: HelpcenterPageType.FOLLOWED_ARTICLES,
      requireToken: true,
    },
    {
      id: HelpcenterPageNames.FOLLOWED_ARTICLES,
      path: `${localeMatcher}/articles`,
      exact: true,
      component: PageContent as any,
      loadDataClient: () => Promise.resolve(),
      pageType: HelpcenterPageType.FOLLOWED_ARTICLES,
      requireToken: true,
    },
    ...genericHelpcenterRoutes,
    {
      id: HelpcenterPageNames.HOMEPAGE,
      path: localeMatcher,
      component: PageContent as any,
      loadDataClient: () => Promise.resolve(),
      pageType: HelpcenterPageType.HOMEPAGE,
    },
  ];
  const onlyTicketing = [
    ...genericTicketingRoutes,
    {
      id: HelpcenterPageNames.REQUEST_LIST,
      path: localeMatcher,
      component: PageContent as any,
      loadDataClient: () => Promise.resolve(),
      pageType: HelpcenterPageType.REQUEST_LIST,
    },
  ];
  const bothHelpcenterAndTicketing = [
    ...genericTicketingRoutes,
    {
      id: HelpcenterPageNames.FOLLOWED_ARTICLES,
      path: `${localeMatcher}/articles`,
      exact: true,
      component: PageContent as any,
      loadDataClient: () => Promise.resolve(),
      pageType: HelpcenterPageType.FOLLOWED_ARTICLES,
      requireToken: true,
    },
    ...genericHelpcenterRoutes,
    {
      id: HelpcenterPageNames.HOMEPAGE,
      path: localeMatcher,
      component: PageContent as any,
      loadDataClient: () => Promise.resolve(),
      pageType: HelpcenterPageType.HOMEPAGE,
    },
  ];
  const noHelpcenterAndTicketing = [
    {
      id: HelpcenterPageNames.ERROR,
      path: localeMatcher,
      component: PageContent as any,
      loadDataClient: () => Promise.resolve(),
      pageType: HelpcenterPageType.ERROR,
      requireToken: true,
    },
  ];
  const hasPremiumTicketing = options.premiumData?.hasTicketing || (options.premiumData?.trialDays && options.premiumData?.trialDays > 0);
  const hasPremiumKB = options.premiumData?.hasKB || (options.premiumData?.trialDays && options.premiumData?.trialDays > 0);
  const showCustomerPortalTicketing = options.isTicketingEnabled && hasPremiumTicketing;
  const showCustomerPortalKb = options.isHelpcenterEnabled && hasPremiumKB;
  const customerPortalRoutes = showCustomerPortalTicketing && showCustomerPortalKb ? bothHelpcenterAndTicketing :
    showCustomerPortalKb ? onlyHelpcenter :
    showCustomerPortalTicketing ? onlyTicketing : noHelpcenterAndTicketing;
  const oldRoutes = [
    {
      id: PROFILE_PAGE_NAME,
      path: `${localeMatcher}/account`,
      exact: true,
      component: PageContent as any,
      loadDataClient: () => Promise.resolve(),
      pageType: PROFILE_PAGE_TYPE,
      requireToken: true,
    },
    {
      id: TICKET_PAGE_NAME,
      path: `${localeMatcher}/ticket/:ticketId`,
      exact: true,
      component: PageContent as any,
      loadDataClient: () => Promise.resolve(),
      pageType: TICKET_PAGE_TYPE,
      requireToken: true,
    },
    ...genericHelpcenterRoutes,
    {
      id: HelpcenterPageNames.HOMEPAGE,
      path: localeMatcher,
      component: PageContent as any,
      loadDataClient: () => Promise.resolve(),
      pageType: HelpcenterPageType.HOMEPAGE,
    },
  ];
  const contactPageRoute = [
    {
      id: HelpcenterPageNames.CONTACT,
      path: [
        `${localeMatcher}/contact`,
        `${localeMatcher}/contact?linkedArticleId=:id`
        ],
      exact: true,
      component: PageContent as any,
      loadDataClient: getContactFormFields,
      pageType: HelpcenterPageType.CONTACT,
    },
  ];
  return [
    {
      id: 'root',
      component: RootComposer as any,
      loadDataClient: () => Promise.resolve(),
      routes: [
        {
          id: 'locale',
          path: localeMatcher,
          component: OuterPageContent,
          loadDataClient: () => Promise.resolve(),
          routes: [
            ...(options.enableContactPage ? contactPageRoute : []),
            ...(options.enableCustomerPortal ? customerPortalRoutes : oldRoutes),
          ],
        },
      ],
    },
  ]
};

export interface LocaleMatch {
  locale: string;
}

export interface ArticleMatch extends LocaleMatch {
  articleUri: string;
}

export interface CategoryMatch extends LocaleMatch {
  categoryUri0: string;
  categoryUri1?: string;
  categoryUri2?: string;
  categoryUri3?: string;
  categoryUri4?: string;
}

export interface SearchMatch extends LocaleMatch { }
export interface SearchQuery {
  term: string;
  page?: string;
}
