import {
  BaseSectionData,
  HelpcenterHeader,
  HelpcenterFooter,
  helpcenterSectionBuilder,
  HelpcenterSectionType,
  helpcenterHeaderBuilder,
  helpcenterFooterBuilder,
} from '@wix/answers-api';
import produce from 'immer';
import { ComponentTypes } from '../../../../common/helpcenter-types';
import { MissingConfigPropsError } from '../utils/missing-config-props-error';

export function resolveConfig(
  segmentType: HelpcenterSectionType | ComponentTypes,
  segmentConfig: any,
  strict: boolean = false,
): Record<string, any> {
  let segmentDefaults:
    | BaseSectionData<any>
    | HelpcenterHeader
    | HelpcenterFooter
    | null = null;

  switch (typeof segmentType) {
    case 'number':
      segmentDefaults = helpcenterSectionBuilder(
        segmentType as HelpcenterSectionType,
      );
      break;
    case 'string':
      switch (segmentType) {
        case ComponentTypes.Header:
          segmentDefaults = helpcenterHeaderBuilder();
          break;
        case ComponentTypes.Footer:
          segmentDefaults = helpcenterFooterBuilder();
          break;
        default:
      }
      break;
    default:
  }

  const defaultConfig = segmentDefaults ? segmentDefaults.config : {};
  const segmentResolvedConfig = maybeSpecialConfig(segmentType, segmentConfig);
  const missingProps = getConfigDiffs(segmentResolvedConfig, defaultConfig);

  if (missingProps.length) {
    const error = new MissingConfigPropsError(missingProps);
    if (strict) {
      throw error;
    }
  }

  return { ...defaultConfig, ...segmentResolvedConfig };
}

function getConfigDiffs(
  supplied: Record<string, any>,
  expected: Record<string, any> = {},
): string[] {
  return Object.keys(expected).filter(
    (x: string) => !Object.keys(supplied).includes(x),
  );
}

function maybeSpecialConfig (
  segmentType: HelpcenterSectionType | ComponentTypes,
  segmentConfig: any,
) {
  return produce(segmentConfig, (draft) => {
    // need to keep categoryImageScale without default for existing tenants in order to keep images style (until they save scale option)
    if ((segmentType === HelpcenterSectionType.CATEGORIES || segmentType === HelpcenterSectionType.CORE_CATEGORIES)
        && !segmentConfig.categoryImageScale) {
      draft.categoryImageScale = undefined;
    }
  })
}
