import { LocaleMatch } from '../routes';
import { ClientSafeTenant } from '../../../helpcenter-data';
import { helpcenterApi } from '../../../api';
import { decodeClientSearchTerm } from '../../components-for-sections/search-input/search-input';
import { ContentFilters } from '../../utils/sdk/domain';
import { SEARCH_RES_PAGE_SIZE } from '../../section-rendering/specific-section-renderer';

export const searchArticleResults = (
  brandId: string,
  locale: string,
  searchTerm: string,
  page: number,
  sdkContentFilters?: ContentFilters,
) => {
  const maybeContentFilters = sdkContentFilters || {};
  return helpcenterApi
    .searchArticles(
      { 
        brandId,
        locale,
        text: searchTerm,
        page: page > 0 ? page: 1,
        pageSize: SEARCH_RES_PAGE_SIZE,
        spellcheck: true,
        ...maybeContentFilters
      },
    )
    .then((articleList) => ({
      articles: articleList.data,
      itemsCount: articleList.itemsCount,
      search: {
        term: searchTerm,
        page: page
      },
      page: page > 0 ? page: 1
    }));
}
const loadDataClient = (
  params: LocaleMatch,
  tenant: ClientSafeTenant,
  location: any,
  initialData: any,
  baseUrl?: string,
  sdkContentFilters?: ContentFilters
) =>
  Promise.all([
    searchArticleResults(
      tenant.brandId,
      params.locale,
      decodeClientSearchTerm(new URLSearchParams(location.search).get('term') || ''),
      Number(new URLSearchParams(location.search).get('page') || 1),
      sdkContentFilters,
    ),
  ]);

export default loadDataClient;
