import * as React from 'react';
import { Icon } from '@wix/answers-ui-libby';
import {
  onAttachmentUploadProps,
  FileUploadLimitations,
} from '@wix/answers-api';
import { AnsViewComp, TranslateFn } from '@wix/answers-lib';
import { keyDefinitions } from '@unidriver/core/dist/puppeteer-us-keyboard-layout';
import { BiLogger } from '../../../routes/bi';
import { publicCustomerPortalViewerClientEngagement } from '@wix/bi-logger-wix-answers/v2';

const close = `
<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11"><path d="M5.5 4.086L1.707.293.293 1.707 4.086 5.5.29 9.294l1.415 1.415L5.5 6.914l3.793 3.793 1.414-1.414L6.914 5.5l3.793-3.793L9.293.293 5.5 4.086z"/></svg>
`;

const plusIcon = `
<svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 0.5H5V5.5H0V6.5H5V11.5H6V6.5H11V5.5H6V0.5Z" fill="#0061FF"/></svg>
`;

export const ENGAGED_COMPONENT = 'ticket_page';
export const ENGAGED_SUB_COMPONENT = 'reply_area';
export const ENGAGEMENT_NAME = 'click_on_add_file';

export const attachmentsKey = 'attachments';

export interface TicketAttachment {
  name: string;
  url: string;
}

export interface AttachmentsProps {
  attachments: TicketAttachment[];
  onUpload: onAttachmentUploadProps;
  captchaToken: string;
  onChange(newVal: TicketAttachment[]): void;
  execCaptcha?(callback: () => void): void;
  allowedFileTypes: string[];
  biLogger?: BiLogger;
  ticketId: string;
  customerPortalEnabled: boolean;
  brandId?: string;
}

export interface AttachmentsState {
  error: { fileName: string; message: string } | null;
  progress: { name: string } | null;
  uploadProgress: number;
}

const translationPrefix = `ticket-page.${attachmentsKey}`;
const maxFileSizeMb = FileUploadLimitations.uploadLimitInBytes / 1000000;
export class Attachments extends AnsViewComp<
  AttachmentsProps,
  AttachmentsState
> {
  inputRef: any;
  cancel: boolean = false;

  state: AttachmentsState = {
    error: null,
    progress: null,
    uploadProgress: 0,
  };

  tp: TranslateFn = (key, params) => {
    return this.props.t(`${translationPrefix}.${key}`, params);
  };

  fileInputRef = (ref: any) => (this.inputRef = ref);

  handleFileInput = (e: any) => {
    const files = Array.prototype.slice.call(e.target.files, 0);
    files.forEach(this.maybeUploadFile);
    e.target.value = null;
  };

  maybeUploadFile = (file: File) => {
    if (this.props.execCaptcha) {
      const uploadCallback = () => this.uploadFile(file);
      this.props.execCaptcha(uploadCallback);
    } else {
      this.uploadFile(file);
    }
  };

  uploadFile = (file: File) => {
    const { onUpload, captchaToken, allowedFileTypes } = this.props;
    const { uploadLimitInBytes } = FileUploadLimitations;
    const supportedFileTypes = allowedFileTypes.map((type) => `.${type}`);
    const isSizeOK = file.size < uploadLimitInBytes;
    const isTypeOK = supportedFileTypes.indexOf(
        `.${(file.name.split('.').pop() || '').toLowerCase()}`,
      ) !== -1;

    if (isSizeOK && isTypeOK) {
      const fileReader = new FileReader();
      this.setState({ progress: { name: file.name }, error: null });

      fileReader.addEventListener('error', () => {
        const message = this.tp('read-error');
        this.setState({
          progress: null,
          error: { fileName: file.name, message },
          uploadProgress: 0,
        });
        this.cancel = false;
      });

      fileReader.addEventListener('load', async () => {
        try {
          const attachment = await onUpload(
            fileReader.result as any,
            captchaToken,
            this.onProgress,
          );
          if (this.cancel) {
            this.cancel = false;
          } else {
            this.setState({ progress: null, uploadProgress: 0 });
            this.props.onChange([
              ...this.props.attachments,
              { name: file.name, url: attachment.url },
            ]);
          }
        } catch (e) {
          this.setState({
            progress: null,
            error: { fileName: file.name, message: this.tp('upload-error') },
          });
        }
      });

      fileReader.readAsDataURL(file);
    } else if (isTypeOK) {
      this.setState({
        error: { fileName: file.name, message: this.tp('size-error', { maxFileSizeMb }) },
      });
    } else {
      this.setState({
        error: {
          fileName: file.name,
          message: `${this.tp('type-error')} ${supportedFileTypes.join(
            ', ',
          )}`,
        },
      });
    }
  };

  closeError = () => {
    this.setState({ error: null, uploadProgress: 0 });
  };

  onProgress = (progress: number) => {
    this.setState({ uploadProgress: progress });
  };

  insertFile = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (this.state.progress === null) {
      this.inputRef.click();
    }
  };

  onCancel = () => {
    this.cancel = true;
    this.setState({ progress: null, uploadProgress: 0 });
  };

  removeAttachment = (fileUrl: string) => {
    const { attachments, onChange } = this.props;
    onChange(attachments.filter((file) => file.url !== fileUrl));
  };

  attachRef = (elem: HTMLElement | null) => {
    if (elem) {
      elem.addEventListener('keyup', (e) => {
        if (
          e.key === keyDefinitions.Enter.key ||
          e.key === keyDefinitions.Space.key
        ) {
          this.inputRef.click();
        }
      });
    }
  };

  removeRef = (file: string) => (elem: HTMLElement | null) => {
    if (elem) {
      elem.addEventListener('keyup', (e) => {
        if (
          e.key === keyDefinitions.Enter.key ||
          e.key === keyDefinitions.Space.key
        ) {
          this.removeAttachment(file);
        }
      });
    }
  };

  onClickAddFileButton = (e?: any) => {
    this.insertFile(e);
    if (this.props.biLogger) {
      // tslint:disable-next-line: no-floating-promises
      this.props.biLogger.report(publicCustomerPortalViewerClientEngagement({
        data: this.props.ticketId,
        engaged_component: ENGAGED_COMPONENT,
        engaged_sub_component: ENGAGED_SUB_COMPONENT,
        engagement_name: ENGAGEMENT_NAME,
        answers_brand_id: this.props.brandId,
      }));
    }
  };

  render() {
    const { state } = this;
    const { attachments } = this.props;

    const attachmentsList = attachments.map((file, idx) => {
      const removeAttachment = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        this.removeAttachment(file.url);
      };

      return (
        <div className="attachment" key={idx}>
          <a className="attachment-name" href={file.url} target="_blank">
            {file.name}
          </a>
          <span
            ref={this.removeRef(file.url)}
            tabIndex={0}
            className="attachment-btn"
          >
            <button tabIndex={-1} onClick={removeAttachment} className="remove">
              <Icon icon={close} />
            </button>
          </span>
        </div>
      );
    });

    const progressComp = state.progress && (
      <div key="progress" className="file-progress">
        <span className="file-name">{state.progress.name}</span>
        <span className="attachment-btn">
          <button onClick={this.onCancel} className="cancel">
            <Icon icon={close} />
          </button>
        </span>
        <div className="progress-bar">
          <div
            className="progress-bar-inner"
            style={{ width: `${state.uploadProgress}%` }}
          />
        </div>
      </div>
    );

    const uploadErrorComp = state.error && (
      <div key="error" className="file error">
        <button onClick={this.closeError} className="close-error-cta">
          <Icon icon={close} />
        </button>
        <div>{state.error.fileName}</div>
        <div className="error-message">{state.error.message}</div>
      </div>
    );


    return (
      <div className="attachments-container">
        <div className={`attachment-list-wrapper ${this.props.customerPortalEnabled ? 'cp-attachments-wrapper' : null}`}>{attachmentsList}</div>
        {progressComp}
        {uploadErrorComp}
        <div tabIndex={0} ref={this.attachRef} className="attach-wrapper">
          <button
            className="add-file-button"
            disabled={state.progress !== null}
            onClick={this.onClickAddFileButton}
          >
            {
              <div className="add-file-text">
                <Icon icon={plusIcon}/>
                <span className="underlined-text">
                  {this.props.t('ticket-page.add-file-button')}
                </span>
              </div>
            }
          </button>
        </div>
        <input
          type="file"
          ref={this.fileInputRef}
          onChange={this.handleFileInput}
          style={{ display: 'none' }}
        />
      </div>
    );
  }
}
