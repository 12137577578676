import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { classNames } from '@wix/answers-lib';
import { Icon, IconType } from '../../../viewer/components-for-sections/icons';
import { UserToolbarHeight } from '../../utils/agent-toolbar';
import { AGENT_DISABLED_NOTIFICATION_HEIGHT } from '../../utils/disabled-helpcenter-notification';

export const breadcrumbsKey = 'breadcrumbs';

export interface BreadcrumbItem {
  name: string;
  uri: string;
  id?: string;
}

export interface BreadcrumbsProps {
  breadcrumbsList: BreadcrumbItem[];
  locale: string;
  isArticlePage: boolean;
  isToolbarExpanded: boolean;
  hasToolbar: boolean;
  hasSidebarButton: boolean;
  onSidebarButtonClick(): void;
  onToolbarUpdate(cb: (hasToolbar: boolean, isExpanded: boolean) => void): void;
  t(key: string): string;
}

export interface BreadcrumbsState {
  top: number;
}

export class Breadcrumbs extends React.PureComponent<
  BreadcrumbsProps,
  BreadcrumbsState
> {
  calcTop = (toolbarEnabled: boolean, isToolbarExpanded: boolean) => {
    const header =
      document.querySelector('.header') ||
      document.querySelector('.ans-header');
    const top = header ? header.clientHeight : 0;
    const disabledNotificationAddition = document.querySelector('.disabled-helpcneter-agent-notification') ? AGENT_DISABLED_NOTIFICATION_HEIGHT : 0;
    const toolbarAddition = toolbarEnabled
      ? isToolbarExpanded
        ? UserToolbarHeight.EXPANDED
        : UserToolbarHeight.COLLAPSED
      : 0;
    return top + toolbarAddition + disabledNotificationAddition;
  };

  state: BreadcrumbsState = {
    top: 0,
  };

  componentDidMount() {
    const { isToolbarExpanded, hasToolbar, onToolbarUpdate } = this.props;
    this.setState({ top: this.calcTop(hasToolbar, isToolbarExpanded) });

    onToolbarUpdate((toolbarEnabled, isExpanded) => {
      this.setState({
        top: this.calcTop(toolbarEnabled, isExpanded),
      });
    });
  }

  renderBreadCrumbs = () => {
    const { isArticlePage, breadcrumbsList } = this.props;
    return breadcrumbsList.map((crumb, idx) => {
      const isCurrent = idx === breadcrumbsList.length - 1 && !isArticlePage;
      const pathname =
        `/${this.props.locale}` +
        (crumb.uri.startsWith('/') ? crumb.uri : `/${crumb.uri}`);
      const state = crumb.id ? crumb.id : '';
      const name = crumb.name.replace(/\s+$/, '');

      return (
        <div className="breadcrumbs-item" key={idx}>
          <NavLink
            to={{ pathname, state }}
            className={classNames('nav-link', 'crumb-title-container', {
              current: isCurrent,
            })}
          >
            <span>{name}</span>
          </NavLink>
          <Icon className="arrow-icon" type={IconType.SMALL_ARROW} />
        </div>
      );
    });
  };

  render() {
    return (
      <div className={breadcrumbsKey} style={{ top: `${this.state.top}px` }}>
        {this.props.hasSidebarButton ? (
          <div
            className="breadcrumb-sidebar"
            onClick={this.props.onSidebarButtonClick}
          >
            <Icon className="sidebar-icon" type={IconType.SIDEBAR} />
          </div>
        ) : null}
        <div className="breadcrumbs-container">{this.renderBreadCrumbs()}</div>
      </div>
    );
  }
}
