import { CompanyDisplayInfo, HelpcenterStructure } from '@wix/answers-api';
import * as React from 'react';
import {
  TemplateFromBlueprint,
  ComponentTypes,
} from '../../../common/helpcenter-types';
import { TemplateCompiler } from '../../../viewer/section-rendering/run-time/template-compiler';
import { HelpcenterContext } from '../../helpcenter-context';
import {
  withExperiments,
  InjectedExperimentsProps,
} from '@wix/wix-experiments-react';
import { helpcenterApi } from '../../../api';

type headerProps = {
  template: TemplateFromBlueprint;
  helpcenterStructure: HelpcenterStructure;
  topOffset: number;
} & InjectedExperimentsProps;

export const _Header = React.memo((props: headerProps) => {
  const {
    tenant,
    helpcenter: { sessionUser },
  } = React.useContext(HelpcenterContext);

  const { header } = props.helpcenterStructure;
  const { topOffset } = props;

  const locales = tenant && tenant.helpCenterSettings.enabledLocales;
  const submitTicketsEnabled =
    !!sessionUser ||
    tenant.helpCenterSettings.authentication.enableGuestTickets;
  const ctaDisabled =
    !(header.config.showLanguagePicker && locales.length > 1) &&
    (!header.config.showContactButton || !submitTicketsEnabled) &&
    !header.config.showLoginButton;

  const customerPortalEnabled = props.experiments.enabled(
    'specs.EnableCustomerPortal',
  );
  const [myCompanies, setMyCompanies] = React.useState<CompanyDisplayInfo[]>([]);

  React.useEffect(() => {
    const hasSessionUser = !!sessionUser;
    if (customerPortalEnabled && hasSessionUser) {
      // tslint:disable-next-line: no-floating-promises
      helpcenterApi.getMyComapnies().then(setMyCompanies);
    }
  }, []);

  const templateModel = React.useMemo(
    () => ({
      tenant,
      sessionUser,
      ctaDisabled,
      submitTicketsEnabled,
      customerPortalEnabled,
      myCompanies,
      locale: 'en'
    }),
    [tenant, sessionUser, ctaDisabled, submitTicketsEnabled, myCompanies],
  );

  return (
    //TODO: fix inline css
    <div className='header hc-header' style={{top: topOffset}}>
      <TemplateCompiler
        sectionTemplate={props.template}
        baseSegment={header}
        model={templateModel}
        segmentConfig={header.config}
        segmentType={ComponentTypes.Header}
      />
    </div>
  );
});
export const Header = withExperiments(_Header);
