import { helpcenterApi } from '../../../api';
import {
  ArticleContext,
  CategoryContext,
} from '@wix/answers-common-components';
import { Article, Category } from '@wix/answers-api';

const getFormattedDate = (date: number) =>
  new Date(date).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

export const getArticleContextData = async (
  article: Article,
  locale: string,
): Promise<ArticleContext> => {
  const [counters] = await Promise.all([
    helpcenterApi.getArticleCounters({
      id: article.id,
      locale,
    }),
  ]);

  return {
    article,
    counters,
    updated: getFormattedDate(article.lastUpdateDate),
    created: getFormattedDate(article.creationDate),
  };
};

export const getCategoryContextData = async (
  category: Category,
  locale: string,
): Promise<CategoryContext> => {
  return {};
};
