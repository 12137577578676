import { User } from '@wix/answers-api';
import { classNames } from '@wix/answers-lib';
import * as React from 'react';
import { SmallUserAvatar } from '../avatar';

export const enum UserDetailsType {
	REPORTER = 'reporter',
	CC = 'cc',
	ASSIGNED = 'assigned',
}

export const userDetailsKey = 'user-details';

export const UserDetails = ({ model }) => (props: { userType: UserDetailsType }) => {
	const ticket = model.customerTimeline.ticket;
	let users: User[];
	switch (props.userType) {
		case UserDetailsType.REPORTER:
			users = [ticket.user];
			break;
		case UserDetailsType.CC:
			users = ticket.allCcUsers;
			break;
		case UserDetailsType.ASSIGNED:
		default:
			users = [ticket.assignedUser];
	}
	return <div className={userDetailsKey}>
		{users.map((user, idx) => (
			<div className={classNames('specific-user-details', props.userType, idx.toString())} key={idx}>
				<SmallUserAvatar user={user} />
				<div className='first-name'>{user.firstName}</div>
			</div>
		))}
	</div>
};