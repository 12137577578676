import { Category } from '@wix/answers-api';
import i18next from 'i18next';
import * as React from 'react';
import { deepSearch } from '../../../common/utils';
import { NavLinkJsx } from '../../components-for-sections/nav-links/nav-link-jsx';

export interface BackLinkOnClickOptions {
  preventNavigation: boolean;
}

export enum BackButtonStates {
  HOME = 'home',
  ALL_TOPICS = 'all-topics',
}

export const BackLink = ({
  model,
  onClick,
  selectedCategory,
  t,
}: {
  model: any;
  selectedCategory?: Category;
  onClick(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    selectedCategoryOrState: BackButtonStates | Category,
  ): Partial<BackLinkOnClickOptions> | undefined;
  t: i18next.TFunction;
}) => ({ children }) => {
  const { categoryTree } = model;

  const categoryParent = deepSearch<Category, any>(
    categoryTree,
    'children',
    (item) => item.id === selectedCategory?.parentId,
  );

  const resolveBackButtonCategoryOrState = (): BackButtonStates | Category => {
    if (selectedCategory) {
      if (categoryParent) {
        return categoryParent;
      }
      return BackButtonStates.ALL_TOPICS;
    }
    return BackButtonStates.HOME;
  };

  const buttonCategoryOrState = resolveBackButtonCategoryOrState();

  const handleOnClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    if (onClick(e, buttonCategoryOrState)?.preventNavigation) {
      return e.preventDefault();
    }
  };

  const getButtonTitle = (): string => {
    switch (buttonCategoryOrState) {
      case BackButtonStates.HOME:
        return t(BackButtonStates.HOME);
      case BackButtonStates.ALL_TOPICS:
        return t(BackButtonStates.ALL_TOPICS);
      default:
        return buttonCategoryOrState.name;
    }
  };

  const isCategory = (
    state: BackButtonStates | Category,
  ): state is Category => {
    return state && !!(state as Category).id;
  };

  return (
    <NavLinkJsx
      uri={isCategory(buttonCategoryOrState) ? buttonCategoryOrState.uri : ''}
      state={
        isCategory(buttonCategoryOrState) ? buttonCategoryOrState.id : undefined
      }
      onClick={handleOnClick}
      className={'nav-back'}
    >
      <>
        {children}
        <span>{getButtonTitle()}</span>
      </>
    </NavLinkJsx>
  );
};
