import * as React from 'react';
import { Tooltip } from '@wix/answers-ui-libby';
import { PopperDirection } from '@wix/answers-ui-libby/dist/common/popper-engine';
import { classNames } from '@wix/answers-lib';

// tslint:disable-next-line:interface-over-type-literal
export type TooltipProps = {
  body: JSX.Element | string;
  relativeToBody?: boolean;
  forceVisible?: boolean;
  forceHidden?: boolean;
  direction?: PopperDirection;
  align?: 'left' | 'center' | 'right';
  offset?: {
    x?: number;
    y?: number;
  };
  className?: string;
  children?: any;
};

export const TooltipComp = (props: TooltipProps) => {
  return (
    <Tooltip
      body={props.body}
      relativeToBody={props.relativeToBody}
      forceHidden={props.forceHidden}
      forceVisible={props.forceVisible}
      direction={props.direction}
      align={props.align}
      offset={props.offset}
      className={classNames(props.className, 'tooltip-comp')}
    >
      {props.children}
    </Tooltip>
  );
};
