import * as React from 'react';
import { GenericNavLink } from '../generic-nav-link';
import { ContactMethodType } from '@wix/answers-api';
import { getContactOptions } from '../../../../common/contact-utils';
import { BiLogger } from '../../../routes/bi';
import { publicClickContactUsFooter } from '@wix/bi-logger-wix-answers/v2';

export const contactUrl = (contactOption) =>
  contactOption?.contactMethod?.type
    ? contactOption.contactMethod.type === ContactMethodType.EXTERNAL
      ? contactOption.contactMethod.externalUrl
      : contactOption.contactMethod.type === ContactMethodType.EMAIL
      ? `mailto:${contactOption.contactMethod.email}`
      : '/contact'
    : '/';

export const ContactLink = ({ model, biLogger }: {model: any, biLogger: BiLogger}) => (props) => {
  const { children, className, component } = props;
  const { tenant } = model;
  const onClick = () => {
    return biLogger.report(publicClickContactUsFooter({
      location_identifier: component,
      source_type: 'customer_portal',
      source_name: 'customer_portal',
      answers_brand_id: model.tenant.brandId,
    }));
  };
  const contactOption = getContactOptions(tenant);
  return (
    <GenericNavLink
      className={className}
      uri={contactUrl(contactOption)}
      onClick={onClick}
    >
      {children}
    </GenericNavLink>
  );
};
