import { helpcenterApi } from '../../../../api';
import { ErrorType } from '../../../../common/errors/error-type';
import { RecoverableError } from '../../../../common/errors/recoverable-error';
import { createSampleTicketPageModel, PREVIEW_TICKET_ID } from '../../../preview-types';

export const customerPortalTicketSectionDataLoader = async ({ id }: { id: string }) => {
  try {
    const customFields = await helpcenterApi.getCustomFields();
    if (id === PREVIEW_TICKET_ID) {
      return createSampleTicketPageModel(customFields);
    }
    const customerTimeline = await helpcenterApi.getTicketCustomerTimeline(id);
    const companies = await helpcenterApi.getMyComapnies();
    return {
      customerTimeline,
      ticket: customerTimeline.ticket,
      companies,
      customFields,
      customerPortalEnabled: true,
    };
  } catch (err:any) {
    if (Object.values(ErrorType).includes(err.response?.status)) {
      throw err;
    }
    if (err.response.status === 400) {
      const error = {...err, response: {status: ErrorType.PAGE_NOT_FOUND}}
      throw error;
    }
    throw new RecoverableError(
      'failed to load user data, redirecting to homepage',
      'REDIRECT',
      '',
    );
  }
};
