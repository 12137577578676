import { useRef, useEffect } from 'react';

type MaybeCleanUpFn = void | (() => void);

function useCustomCompareMemo<T>(
  value: T,
  equal: (prev: T, curr: T) => boolean,
): T {
  const ref = useRef<T>(value);

  if (!equal(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useCustomCompareEffect<T>(
  create: () => MaybeCleanUpFn,
  input: T,
  equal: (prev: T, curr: T) => boolean,
  skipFirst: boolean = true,
) {
  const firstUpdate = useRef(true);
  const func = () => {
    if (skipFirst && firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      create();
    }
  };

  useEffect(func, [useCustomCompareMemo(input, equal)]);
}

export { useCustomCompareEffect };
