import { BaseSegment, ExtendedTicket, HelpcenterSectionType, TicketStatus, CoreRequestListSectionConfig, TsUtils, CustomerPortalFieldType, TicketSortType } from '@wix/answers-api';
import * as React from 'react';
import { TemplateCompiler } from '../../run-time/template-compiler';
import { ComponentTypes, SectionModel, TemplateFromBlueprint } from '../../../../common/helpcenter-types';
import { helpcenterApi } from '../../../../api';
import { TicketFilterMultiSelect, TicketSearchInput, Option, Pagination as PaginationComp, Icon, IconType, OPTION_ALL } from '../../../components-for-sections';
import { debounce } from '@wix/answers-lib';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RequestListFilters, saveRequestListFilters } from '../../../../common/utils';
import { processTicketSearchDto } from '@wix/answers-common-components';

export interface CoreRequestListTemplateCompilerProps {
  sectionTemplate: TemplateFromBlueprint;
  model: SectionModel;
  baseSegment: BaseSegment;
  segmentConfig: CoreRequestListSectionConfig;
  segmentType: HelpcenterSectionType | ComponentTypes;
  savedFilters: RequestListFilters;
}

const RESULTS_DEBOUNCE_TIMER = 300;
const isTicketStatus = (value: string | number) => TsUtils.enumToArray(TicketStatus).includes(value);

const _CoreRequestListTemplateCompiler = (props: CoreRequestListTemplateCompilerProps & WithTranslation) => {

  const [searchFilterValue, setSearchFilterValue] = React.useState(props.savedFilters.text || '');
  const [statusFilterValue, setStatusFilterValue] = React.useState<Option[]>(props.savedFilters.status || [OPTION_ALL]);
  const [companyFilterValue, setCompanyFilterValue] = React.useState<Option[]>(props.savedFilters.company || [OPTION_ALL]);
  const [reporterFilterValue, setReporterFilterValue] = React.useState<Option[]>(props.savedFilters.reporter || [OPTION_ALL]);
  const [languageFilterValue, setLanguageFilterValue] = React.useState<Option[]>(props.savedFilters.language || [OPTION_ALL]);
  const [statusFilterOpen, setStatusFilterOpen] = React.useState(false);
  const [companyFilterOpen, setCompanyFilterOpen] = React.useState(false);
  const [reporterFilterOpen, setReporterFilterOpen] = React.useState(false);
  const [languageFilterOpen, setLanguageFilterOpen] = React.useState(false);
  const [tickets, setTickets] = React.useState<ExtendedTicket[]>(props.model?.tickets || []);
  const [currentPage, setCurrentPage] = React.useState(props.savedFilters.page || 1);
  const [numPages, setNumPages] = React.useState(1);
  const [itemsCount, setItemsCount] = React.useState(props.model?.itemsCount || 0);
  const [loading, setLoading] = React.useState(false);
  const [sortType, setSortType] = React.useState<TicketSortType>(props.savedFilters.sortType || TicketSortType.CREATION_DATE_DESC);

  const isFieldEnabled = (field: CustomerPortalFieldType) => {
    return props.segmentConfig.ticketTableFields.find(tableField => tableField.fieldType === field)?.enabled;
  };

  const onFiltersChanged = React.useCallback(debounce((query: string, statuses: TicketStatus[], companies: string[], allReporters: boolean, locales: string[], page: number, ticketSortType: TicketSortType) => {
    const dto = {
      text: query,
      filters: {
        statuses,
        companyIds: companies,
      },
      locale: locales[0],
      companyCustomerId: allReporters ? undefined : props.model.sessionUser?.id,
      page,
      pageSize: 15,
      sortType: ticketSortType,
    };
    helpcenterApi.customerTicketsSearch(processTicketSearchDto(dto) as any)
    .then(result => {
      setTickets(result.items);
      setNumPages(result.numPages || 1);
      setItemsCount(result.itemsCount);
      setLoading(false);
    })
    .catch(e => e);
  }, RESULTS_DEBOUNCE_TIMER), []);

  const saveFilters = () => {
    saveRequestListFilters({
      sortType,
      company: companyFilterValue,
      language: languageFilterValue,
      page: currentPage,
      reporter: reporterFilterValue,
      status: statusFilterValue,
      text: searchFilterValue,
    });
  };

  React.useEffect(() => {
    setCurrentPage(1);
  }, [searchFilterValue, statusFilterValue, companyFilterValue, reporterFilterValue, languageFilterValue, sortType]);

  React.useEffect(() => {
    if (props.model.tickets) {
      // this is only passed through the help center preview, in that case, new tickets should not be fetched
      return;
    }
    setLoading(true);
    const statuses = statusFilterValue.map(option => option.value).filter(isTicketStatus) as TicketStatus[];
    const companies = companyFilterValue.map(option => option.value).filter(value => value !== OPTION_ALL.value) as string[];
    const locales = languageFilterValue.map(option => option.value).filter(value => value !== OPTION_ALL.value) as string[];
    const allReporters = reporterFilterValue[0].value === OPTION_ALL.value;
    if (statuses.includes(TicketStatus.OPEN)) {
      statuses.push(TicketStatus.INVESTIGATING);
    }
    saveFilters();
    onFiltersChanged(searchFilterValue, statuses, companies, allReporters, locales, currentPage, sortType).catch(e => e);
  }, [searchFilterValue, statusFilterValue, companyFilterValue, reporterFilterValue, languageFilterValue, currentPage, sortType]);

  const StatusFilter = () => {
    if (!isFieldEnabled(CustomerPortalFieldType.STATUS)) {
      return null;
    }
    const options = [TicketStatus.OPEN, TicketStatus.PENDING, TicketStatus.SOLVED, TicketStatus.CLOSED].map(value => ({ value, label: props.t(`ticket-list.status-filter-option.${value}`) }));
    return <TicketFilterMultiSelect open={statusFilterOpen} setOpen={setStatusFilterOpen} name={props.t('ticket-list.filter-name-status')} options={options} value={statusFilterValue} onChange={setStatusFilterValue} className='status-filter' />;
  };
  
  const CompanyFilter = () => {
    if (!isFieldEnabled(CustomerPortalFieldType.COMPANY)) {
      return null;
    }
    const companies = props.model.sessionUser?.companies;
    if (!companies || companies.length <= 1) {
      return null;
    }
    const options = props.model.sessionUser?.companies?.map(({ id, name }) => ({ value: id, label: name })) || [];
    return <TicketFilterMultiSelect open={companyFilterOpen} setOpen={setCompanyFilterOpen} name={props.t('ticket-list.filter-name-company')} options={options} value={companyFilterValue} onChange={setCompanyFilterValue} className='company-filter' />;
  };

  const ReporterFilter = () => {
    if (!isFieldEnabled(CustomerPortalFieldType.REPORTER)) {
      return null;
    }
    const options = [{ value: 'ME', label: props.t('ticket-list.reporter-filter-option.me') }];
    return <TicketFilterMultiSelect open={reporterFilterOpen} setOpen={setReporterFilterOpen} name={props.t('ticket-list.filter-name-reporter')} options={options} value={reporterFilterValue} onChange={setReporterFilterValue} className='reporter-filter' />;
  };

  const LanguageFilter = () => {
    if (!isFieldEnabled(CustomerPortalFieldType.LOCALE)) {
      return null;
    }
    const locales = props.model.tenant?.locales;
    if (!locales || locales.length <= 1) {
      return null;
    }
    const setLanguageState = (newOptions: Option[]) => {
      // max of one locale allowed, api endpoint limitation
      if (newOptions.length > 1) {
        setLanguageFilterValue([newOptions[newOptions.length - 1]]);
        return;
      }
      setLanguageFilterValue(newOptions);
      setLanguageFilterOpen(false);
    }
    const options = locales.map(locale => ({ value: locale, label: props.t(`language-${locale}`) }));
    return <TicketFilterMultiSelect open={languageFilterOpen} setOpen={setLanguageFilterOpen} name={props.t('ticket-list.filter-name-language')} options={options} value={languageFilterValue} onChange={setLanguageState} className='language-filter' />;
  };

  const Pagination = () => {
    const onClickPagination = (page: number) => {
      setCurrentPage(page);
      window.scrollTo({ top: 0 });
    };
    return <PaginationComp numPages={numPages} page={currentPage} onClick={onClickPagination} />;
  };

  const isFiltersApplied = () => {
    const statuses = statusFilterValue.map(option => option.value).filter(isTicketStatus) as TicketStatus[];
    const companies = companyFilterValue.map(option => option.value).filter(value => value !== OPTION_ALL.value) as string[];
    const locales = languageFilterValue.map(option => option.value).filter(value => value !== OPTION_ALL.value) as string[];
    const allReporters = reporterFilterValue[0].value === OPTION_ALL.value;
    if (statuses.includes(TicketStatus.OPEN)) {
      statuses.push(TicketStatus.INVESTIGATING);
    }
    return statuses.length || companies.length || locales.length || !allReporters || searchFilterValue;
  };

  const SortableColumn = (sortableColumnProps: { children: JSX.Element; type: CustomerPortalFieldType }) => {
    type SortableCustomerPortalFieldType = CustomerPortalFieldType.SUBJECT | CustomerPortalFieldType.CREATION_DATE | CustomerPortalFieldType.LAST_UPDATE_DATE | CustomerPortalFieldType.LOCALE | CustomerPortalFieldType.PRIORITY | CustomerPortalFieldType.TICKET_NUMBER | CustomerPortalFieldType.STATUS;
    const filedTypeToSortTypesMap: Record<SortableCustomerPortalFieldType, [TicketSortType, TicketSortType]> = {
      [CustomerPortalFieldType.SUBJECT]: [TicketSortType.SUBJECT_DESC, TicketSortType.SUBJECT_ASC],
      [CustomerPortalFieldType.CREATION_DATE]: [TicketSortType.CREATION_DATE_ASC, TicketSortType.CREATION_DATE_DESC],
      [CustomerPortalFieldType.LAST_UPDATE_DATE]: [TicketSortType.LAST_UPDATE_DATE_ASC, TicketSortType.LAST_UPDATE_DATE_DESC],
      [CustomerPortalFieldType.LOCALE]: [TicketSortType.LANG_DESC, TicketSortType.LANG_ASC],
      [CustomerPortalFieldType.PRIORITY]: [TicketSortType.PRIORITY_DESC, TicketSortType.PRIORITY_ASC],
      [CustomerPortalFieldType.TICKET_NUMBER]: [TicketSortType.TICKET_NUMBER_ASC, TicketSortType.TICKET_NUMBER_DESC],
      [CustomerPortalFieldType.STATUS]: [TicketSortType.STATUS_ASC, TicketSortType.STATUS_DESC],
    };
    if (filedTypeToSortTypesMap[sortableColumnProps.type]) {
      const [ ascStrategy, descStrategy ] = filedTypeToSortTypesMap[sortableColumnProps.type];
      const changeSortType = () => {
        setSortType((currentSortType) => currentSortType === ascStrategy ? descStrategy : ascStrategy);
      };
      const sortIcon = [ascStrategy, descStrategy].includes(sortType)
        ? <Icon className={ ascStrategy === sortType ? 'asc' : 'desc' } type={IconType.DROP_DOWN_ARROW} />
        : null;
      return (
        <th className="ticket-list-header-cell sortable-ticket-list-header-cell" onClick={changeSortType}>
          <div className="sortable-header-cell-content">
            {sortableColumnProps.children}
            {sortIcon}
          </div>
        </th>
      );
    }
    return <th className="ticket-list-header-cell">{sortableColumnProps.children}</th>;
  };

  return (
    <TemplateCompiler
      sectionTemplate={props.sectionTemplate}
      baseSegment={props.baseSegment}
      model={{ ...props.model, filtersApplied: isFiltersApplied(), tickets, searchQuery: searchFilterValue, itemsCount, loading } as any}
      components={{
        StatusFilter,
        CompanyFilter,
        ReporterFilter,
        LanguageFilter,
        Pagination,
        SortableColumn,
      }}
      bindings={{ onTicketSearchInputChange: setSearchFilterValue }}
      segmentConfig={props.segmentConfig}
      segmentType={props.segmentType}
    />
  );
}

export const CoreRequestListTemplateCompiler = withTranslation()(_CoreRequestListTemplateCompiler);