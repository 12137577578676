export class RecoverableError extends Error {
  recoverable: boolean;
  recoverType: 'REDIRECT' | 'NOT_FOUND';
  data?: any;
  constructor(
    message: string,
    recoverType: 'REDIRECT' | 'NOT_FOUND',
    data?: any,
  ) {
    super(message);
    Object.setPrototypeOf(this, RecoverableError.prototype);
    this.recoverable = true;
    this.recoverType = recoverType;
    this.data = data;
  }
}
