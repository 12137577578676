import { ClientSafeTenant, HelpcenterData } from '../../../helpcenter-data';
import { helpcenterApi } from '../../../api';
import { ArticleType, ArticleSortType, Category } from '@wix/answers-api';
import { deepSearch, SEARCH_ARTICLES_PAGE_SIZE } from '../../../common/utils';
import { createCategoryUri } from '../../../api/utils';
import { RecoverableError } from '../../../common/errors/recoverable-error';
import { CategoryMatch } from '../routes';
import { ContentFilters } from '../../utils/sdk/domain';

export const searchCategoryArticles = async (
  brandId: string,
  locale: string,
  categoryId: string,
  baseUrl?: string,
  sdkContentFilters?: ContentFilters
) => {
  const contentFilters = {...sdkContentFilters, categoryIds: [categoryId]};
  const articlesList = (
    await helpcenterApi.searchArticles(
      {
        brandId,
        locale,
        ...contentFilters,
        page: 1,
        pageSize: SEARCH_ARTICLES_PAGE_SIZE,
        sortType: ArticleSortType.POSITION,
        summarize: true,
        types: [
          ArticleType.ARTICLE,
          ArticleType.FEATURE_REQUEST,
          ArticleType.KNOWN_ISSUE,
        ],
      },
      baseUrl,
    )
  ).data;
  return {
    articles: articlesList.filter(
      (article) => article.type === ArticleType.ARTICLE,
    ),
    featureRequests: articlesList.filter(
      (article) => article.type === ArticleType.FEATURE_REQUEST,
    ),
    knownIssues: articlesList.filter(
      (article) => article.type === ArticleType.KNOWN_ISSUE,
    ),
  };
};

const loadDataClient = (
  params: CategoryMatch,
  tenant: ClientSafeTenant,
  location: any,
  initialData: HelpcenterData,
  baseUrl?: string,
  sdkContentFilters?: ContentFilters
) => {
  return Promise.all([
    helpcenterApi.getCategoryByUri(tenant.brandId, params).then(async (category) => {
      if (category.uri !== `/${createCategoryUri(params)}`) {
        throw new RecoverableError(
          'category redirect',
          'REDIRECT',
          `/${params.locale}${category.uri}`,
        );
      }

      const categoryTree = initialData.helpcenter.categoryTree;
      const categoryFromTree = deepSearch<Category, any>(
        categoryTree,
        'children',
        (item) => item.uri === `/${params.categoryUri0}`,
      );
      const maybeCategoryChildrenArticles = categoryFromTree?.children?.length ? 
      { categoryArticles: await helpcenterApi.getCategoryTopArticles({
        locale: params.locale,
        categoryIds: categoryFromTree?.children?.map((c) => c.id) || []},
      )} : {};

      return {
        category,
        ...maybeCategoryChildrenArticles,
      };
    }),
    searchCategoryArticles(tenant.brandId, params.locale, location.state, undefined, sdkContentFilters),
    new Promise((resolve, reject) => {
      const categoryTree = initialData.helpcenter.categoryTree;
      const category = deepSearch<any, any>(
        categoryTree,
        'children',
        (item) => item.uri === `/${params.categoryUri0}`,
      );
      resolve({
        subcategories: category && category.children,
      });
    }),
  ]);
};

export default loadDataClient;
