import * as React from 'react';
import { classNames } from '@wix/answers-lib';
import { WithTranslation, withTranslation } from 'react-i18next';
import { GenericNavLink } from '../nav-links/generic-nav-link';
import { Icon, IconType } from '../icons';

interface withChildren {
  children?: any;
}

export interface ProfileMenuProps extends WithTranslation, withChildren {
  locale: string;
}

export const ProfileMenu: React.FC<ProfileMenuProps> = (
  props: ProfileMenuProps,
) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const menuBodyElem = React.useRef<HTMLDivElement>(null);
  const menuButtonElem = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    document.addEventListener('click', handleOuterClick);
    return () => document.removeEventListener('click', handleOuterClick);
  }, []);

  const onToggle = () => setIsOpen((prevVal) => !prevVal);

  const closeMenu = () => setIsOpen(false);

  const handleOuterClick = (event: any) => {
    if (event.target && !menuButtonElem.current?.contains(event.target)) {
      closeMenu();
    }
  };
  const renderMenu = isOpen && (
    <div
      className={classNames('profile-menu-body', { open: isOpen })}
      ref={menuBodyElem}
    >
      <div className="menu-item">
        <GenericNavLink uri={'/account'} className="profile-link">
          {props.t('header.profile-menu.my-profile')}
        </GenericNavLink>
      </div>
      <div className="menu-item">
        <a
          target="_self"
          className="logout-link"
          href={`/account/logout?redirectUrl=${encodeURIComponent(
            window.location.href,
          )}&locale=${props.locale}`}
        >
          {props.t('header.profile-menu.logout')}
        </a>
      </div>
    </div>
  );
  return (
    <div className="profile-menu-wrapper">
      <div
        className={classNames('profile-menu-button', { open: isOpen })}
        onClick={onToggle}
        ref={menuButtonElem}
      >
        {props.children}
        <span className="profile-menu-button-title">
          {props.t('header.profile-menu.profile-button')}
        </span>
        <Icon
          type={IconType.SMALL_ARROW}
          className={classNames('open-icon', {
            open: isOpen,
          })}
        />
      </div>
      {renderMenu}
    </div>
  );
};
export const ProfileMenuComp = withTranslation()(ProfileMenu);
