import { Article, BaseSegment, HelpcenterSectionType } from '@wix/answers-api';
import * as React from 'react';
import { TemplateCompiler } from '../../run-time/template-compiler';
import { HelpcenterContext } from '../../../helpcenter-context';
import {
  ComponentTypes,
  SectionModel,
  TemplateFromBlueprint,
} from '../../../../common/helpcenter-types';
import { helpcenterApi } from '../../../../api';
import { createLogger } from '../../../routes/bi';
import { Pagination as PaginationComp } from '../../../components-for-sections';
import { publicCustomerPortalViewerClientEngagement } from '@wix/bi-logger-wix-answers/v2';

export interface FollowedArticlesTemplateCompilerProps {
  sectionTemplate: TemplateFromBlueprint;
  model: SectionModel;
  baseSegment: BaseSegment;
  segmentConfig: any;
  segmentType: HelpcenterSectionType | ComponentTypes;
}

export interface FollowedArticlesTemplateCompilerState {
  followingArticles?: Article[];
  numPages: number;
  currentPage: number;
  loading: boolean;
  itemsCount: number;
}

export const DEFAULT_PAGE_SIZE = 15;
export class FollowedArticlesTemplateCompiler extends React.PureComponent<
  FollowedArticlesTemplateCompilerProps,
  FollowedArticlesTemplateCompilerState
> {
  static contextType = HelpcenterContext;
  context!: React.ContextType<typeof HelpcenterContext>;

  state: FollowedArticlesTemplateCompilerState = {
    followingArticles: this.props.model?.followingArticles,
    numPages: this.props.model?.numPages || 1,
    currentPage: 1,
    loading: false,
    itemsCount: this.props.model?.itemsCount || 0,
  };

  sendBiEvent = async (id: string) => {
    const { locale } = this.context;
    const tenantId = this.props.model.tenant?.id || '';
    const userId = this.props.model.sessionUser?.id;
    const logger = createLogger(tenantId, locale, userId, true);
    await logger.report(publicCustomerPortalViewerClientEngagement({
      data: id,
      engaged_component: 'followed_articles_list',
      engagement_name: 'click_on_unfollow',
      answers_brand_id: this.props.model.tenant?.brandId,
    }));
  };

  onUnfollow = (id: string) => {
    // tslint:disable-next-line: no-floating-promises
    this.sendBiEvent(id);
    return helpcenterApi
      .unfollowArticle({ id, locale: this.props.model.locale })
      .then(() => {
        const followingArticles = this.state.followingArticles?.filter(
          (a) => a.id !== id,
        );
        const itemsCount = this.state.itemsCount - 1;
        const numPages = Math.ceil(itemsCount / DEFAULT_PAGE_SIZE);
        this.setState({
          followingArticles,
          itemsCount,
          numPages,
        });
      });
  };

  Unfollow = () => ({ children, id, className, stopPropagation }) => {
    const onClickUnfollow = (e) => {
      if (stopPropagation) {
        e.stopPropagation();
        e.preventDefault();
      }
      return this.onUnfollow(id);
    };
    return (
      <button
        onClick={onClickUnfollow}
        className={`unfollow-btn ${className || ''}`}
      >
        <>{children}</>
      </button>
    );
  };

  onPageChange = (page: number) => {
    window.scrollTo({ top: 0 });
    this.setState({ loading: true }, () => {
      return helpcenterApi
        .getFollowingArticles({
          page,
          pageSize: DEFAULT_PAGE_SIZE,
          locale: this.context.locale,
        })
        .then((result) => {
          this.setState({
            followingArticles: result.items,
            currentPage: page,
            loading: false,
          });
        });
    });
  };

  render() {
    const Pagination = () => {
      return (
        <PaginationComp
          numPages={this.state.numPages}
          page={this.state.currentPage}
          onClick={this.onPageChange}
        />
      );
    };
    return (
      <TemplateCompiler
        sectionTemplate={this.props.sectionTemplate}
        baseSegment={this.props.baseSegment}
        model={{
          ...this.props.model,
          followingArticles: this.state.followingArticles,
          loading: this.state.loading,
          itemsCount: this.state.itemsCount,
          numPages: this.state.numPages,
        }}
        components={{
          Unfollow: this.Unfollow(),
          Pagination,
        }}
        segmentConfig={this.props.segmentConfig}
        segmentType={this.props.segmentType}
      />
    );
  }
}
