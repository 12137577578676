import * as React from 'react';
import { HelpfulState, Feedback } from '../article-view';
import { ArticleType, VoteFeedbackMode } from '@wix/answers-api';
import { classNames } from '@wix/answers-lib';
import { GenericNavLink } from '../..';

export const articleFeedbackKey = 'article-feedback';

export interface ArticleFeedbackProps {
  articleId: string;
  value: HelpfulState;
  articleType: ArticleType;
  onChange(value: HelpfulState): void;
  t(key: string): string;
  config: Feedback;
  contactUrl: string;
  submitTicketsEnabled: boolean;
  contactOptionDisabled: boolean;
  isContactMethodInternal: boolean;
}

export interface ArticleFeedbackState {}

export class ArticleFeedback extends React.PureComponent<
  ArticleFeedbackProps,
  ArticleFeedbackState
> {
  state: ArticleFeedbackState = {};

  onReportVoteChange = (newFeedback: HelpfulState) => {
    const updatedFeedback =
      this.props.value !== newFeedback
        ? HelpfulState.HELPFUL
        : HelpfulState.NONE;
    this.props.onChange(updatedFeedback);
  };
  getReportVoteSection = (articleType: ArticleType) => {
    const { value, t } = this.props;
    const typeName =
      articleType === ArticleType.FEATURE_REQUEST
        ? 'feature-request'
        : 'known-issue';
    return (
      <div>
        <span className="title">
          {t(`article-page.feedback-title-${typeName}`)}
        </span>
        <div className={`feedback-buttons-container ${typeName}`}>
          <div
            onClick={() => this.onReportVoteChange(HelpfulState.HELPFUL)}
            className={classNames('feedback-option', {
              chosen: value !== HelpfulState.NONE,
            })}
          >
            {t(`article-page.feedback-option-${typeName}`)}
          </div>
        </div>
        {value !== HelpfulState.NONE ? (
          <div className="feedback-message">
            {this.props.config.feedbackMessage}
          </div>
        ) : null}
      </div>
    );
  };

  getHelpfulSection = () => {
    const { value, onChange, t } = this.props;
    return (
      <div className="helpful-feedback">
        <span className="title">{t(`article-page.feedback-title`)}</span>
        <div className="feedback-buttons-container">
          <div
            onClick={() => onChange(HelpfulState.HELPFUL)}
            className={classNames('feedback-option yes', {
              chosen: value === HelpfulState.HELPFUL,
            })}
          >
            {t(`article-page.feedback-option-yes`)}
          </div>
          <span className="answers-divider" />
          <div
            onClick={() => onChange(HelpfulState.NOT_HELPFUL)}
            className={classNames('feedback-option no', {
              chosen: value === HelpfulState.NOT_HELPFUL,
            })}
          >
            {t(`article-page.feedback-option-no`)}
          </div>
        </div>
      </div>
    );
  };

  getArticleFeedbackByType = () =>  {
    switch (this.props.articleType) {
      case ArticleType.ARTICLE:
        return this.getHelpfulSection();
      case ArticleType.FEATURE_REQUEST:
        return this.getReportVoteSection(ArticleType.FEATURE_REQUEST);
      case ArticleType.KNOWN_ISSUE:
        return this.getReportVoteSection(ArticleType.KNOWN_ISSUE);
      default:
        return;
    }
  };

  renderFeedbackMessage = () => {
    const { value, t } = this.props;
    const feedbackMessageContent =
      value === HelpfulState.NOT_HELPFUL
        ? this.props.config.feedbackMessage
        : t(`article-page.feedback-option-yes.message`);
    return (
      value !== HelpfulState.NONE && (
        <div className="feedback-message">{feedbackMessageContent}</div>
      )
    );
  };

  renderContactUsMessage = () => {
    const {
      value,
      t,
      contactUrl,
      contactOptionDisabled,
      submitTicketsEnabled,
      articleId,
      isContactMethodInternal
    } = this.props;
    
    const contactUrlMaybeWithArticleLink = isContactMethodInternal ? `${contactUrl}?linkedArticleId=${articleId}` : contactUrl;
    if (contactOptionDisabled || !submitTicketsEnabled ) {
      return this.renderFeedbackMessage();
    }
    if (value === HelpfulState.HELPFUL) {
      return (
        <div className="feedback-message">
          {t(`article-page.feedback-option-yes.message`)}
        </div>
      );
    }
    if (value === HelpfulState.NOT_HELPFUL) {
      return (
        <div className="contact-us">
          <div className="contact-message">
            {this.props.config.contactMessage}
          </div>
          <GenericNavLink className="contact-button" uri={contactUrlMaybeWithArticleLink}>
            {this.props.config.contactActionButtonText}
          </GenericNavLink>
        </div>
      );
    }
  };

  renderResponse = () => {
    const {
      voteFeedbackMode = VoteFeedbackMode.FEEDBACK_MESSAGE,
    } = this.props.config;
    switch (voteFeedbackMode) {
      case VoteFeedbackMode.FEEDBACK_MESSAGE:
        return this.renderFeedbackMessage();
      case VoteFeedbackMode.CONTACT_MESSAGE:
        return this.renderContactUsMessage();
      default:
    }
  };

  render() {
    const isArticleType = this.props.articleType === ArticleType.ARTICLE;
    return (
      <div className={articleFeedbackKey}>
        {this.getArticleFeedbackByType()}
        {isArticleType && this.renderResponse()}
      </div>
    );
  }
}
