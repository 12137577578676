import { HelpcenterStructure } from '@wix/answers-api';
import * as React from 'react';
import {
  TemplateFromBlueprint,
  ComponentTypes,
} from '../../../common/helpcenter-types';
import { TemplateCompiler } from '../../../viewer/section-rendering/run-time/template-compiler';
import { HelpcenterContext } from '../../helpcenter-context';
import { classNames } from '@wix/answers-lib';
import { InjectedExperimentsProps, withExperiments } from '@wix/wix-experiments-react';

export const _Footer = React.memo(
  (props: {
    template: TemplateFromBlueprint;
    helpcenterStructure: HelpcenterStructure;
  } & InjectedExperimentsProps)  => {
    const {
      tenant,
      helpcenter: { sessionUser },
    } = React.useContext(HelpcenterContext);

    const { footer } = props.helpcenterStructure;
    const footerElem = React.useRef<HTMLDivElement>(null);

    //case when banner is the only CTA enabled in Footer
    const bannerOnly =
      footer.config.showAnswersBanner &&
      (!footer.config.showLanguagePicker || tenant.locales.length < 1) &&
      !footer.config.showContactButton &&
      !footer.config.showLogo;

    //set height for footer without/with only banner + if 'showLogo' is false footer should be hidden or include only banner if enabled
    const setHeight = () => {
      const extraClasses = bannerOnly
        ? 'banner-only'
        : !footer.config.showAnswersBanner
        ? `no-banner ${!footer.config.showLogo && 'mobile-hidden'}`
        : `${!footer.config.showLogo && 'mobile-banner-only'}`;

      footerElem.current?.setAttribute('class', `footer ${extraClasses}`);
    };
    const customerPortalEnabled = props.experiments.enabled(
      'specs.EnableCustomerPortal',
    );
    React.useEffect(() => {
      if(!isCustomFooter) {
        setHeight();
      }
    });
    const submitTicketsEnabled =
      sessionUser ||
      tenant.helpCenterSettings.authentication.enableGuestTickets;
    const ctaEnabled =
      (footer.config.showLanguagePicker && tenant.locales.length > 1) ||
      (footer.config.showContactButton && submitTicketsEnabled) ||
      footer.config.showAnswersBanner ||
      footer.config.showLogo;
    const isCustomFooter =
      footer.codeOverridesEnabled &&
      footer.codeOverrides &&
      (footer.codeOverrides.template || footer.codeOverrides.style);
    const isFooterShown = ctaEnabled || isCustomFooter;

    const templateModel = React.useMemo(
      () => ({
        tenant,
        locale: 'en',
        submitTicketsEnabled,
        customerPortalEnabled,
      }),
      [tenant, submitTicketsEnabled],
    );

    return isFooterShown ? (
      <div ref={footerElem} className={classNames('footer')}>
        <TemplateCompiler
          sectionTemplate={props.template}
          baseSegment={footer}
          model={templateModel}
          segmentConfig={footer.config}
          segmentType={ComponentTypes.Footer}
        />
      </div>
    ) : null;
  },
);

export const Footer = withExperiments(_Footer);
