import * as React from 'react';
import { SearchComp } from './search-input';
import { useHistory } from 'react-router';
import { BiLogger } from '../../routes/bi';

export const Search = ({
  model,
  biLogger,
}: {
  model: any;
  biLogger: BiLogger;
}) => () => {
  // tslint:disable-next-line:react-hooks-nesting
  const history = useHistory();

  return (
    <div className="search-container">
      <SearchComp
        resultsCount={5}
        history={history}
        locale={model.locale}
        logger={biLogger}
        brandId={model.tenant.brandId}
      />
    </div>
  );
};
