import { HelpcenterPageType } from '@wix/answers-api';
import { publicSelectCategoryOrSubcategory } from '@wix/bi-logger-wix-answers/v2';
import * as React from 'react';
import { deepSearch } from '../../../../common/utils';
import { BiLogger } from '../../../routes/bi';
import { useFedopsLogger as getFedopsLogger } from '../../../utils/fedops-logger';
import { NavLinkJsx } from '../nav-link-jsx';

export const CategoryNavLink = ({
  model,
  biLogger,
}: {
  model: any;
  biLogger: BiLogger;
}) => (props) => {
  const { id: categoryId, children, page } = props;
  const { categoryTree } = model;
  const category = deepSearch<any, any>(
    categoryTree,
    'children',
    (item) => item.id === categoryId,
  );
  const { uri = '', name } = category;
  const fedopsLogger = getFedopsLogger();

  const onCategoryClick = () => {
    fedopsLogger.startInteraction('category_navigation');
    if (page === `${HelpcenterPageType.HOMEPAGE}`) {
      return biLogger.report(publicSelectCategoryOrSubcategory({
        category_id: categoryId,
        category_name: name,
        answers_brand_id: model.tenant.brandId,
      }));
    }
  };

  return (
    <NavLinkJsx uri={uri} state={categoryId} onClick={onCategoryClick}>
      {children}
    </NavLinkJsx>
  );
};
