import * as React from 'react';
import { NavLinkJsx } from './nav-link-jsx';

export const GenericNavLink: React.FunctionComponent<{
  uri: string;
  className?: string;
  newTab?: boolean;
  onClick?(): void;
}> = (props) => {
  const { uri, children, className, onClick } = props;
  return /^https?:\/\/|^mailto/.test(props.uri) ? (
    <a
      className={className}
      href={uri}
      target={props.newTab ? '_blank' : undefined}
      onClick={onClick}
    >
      {children}
    </a>
  ) : (
    // tslint:disable-next-line:no-string-literal
    <NavLinkJsx uri={uri} className={className} onClick={onClick}>
      {children}
    </NavLinkJsx>
  );
};
