import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { HelpcenterPageType, Article, Category } from '@wix/answers-api';
import { getArticleContextData, getCategoryContextData } from './api';

import {
  UserToolbar,
  ToolbarContextSections,
  ContextData,
} from '@wix/answers-common-components';
import { HelpcenterContext } from '../../helpcenter-context';

export enum UserToolbarHeight {
  EXPANDED = 42,
  COLLAPSED = 10,
}

export const getUserToolbarHeight = (isExpanded: boolean) =>
  isExpanded ? UserToolbarHeight.EXPANDED : UserToolbarHeight.COLLAPSED;

const visibleContextMap = {
  [HelpcenterPageType.ARTICLE]: ToolbarContextSections.ARTICLE,
  [HelpcenterPageType.ARTICLE_FEATURE_REQUEST]: ToolbarContextSections.ARTICLE,
  [HelpcenterPageType.ARTICLE_KNOWN_ISSUE]: ToolbarContextSections.ARTICLE,
  [HelpcenterPageType.CATEGORY]: ToolbarContextSections.CATEGORY,
};

export interface AgentToolbarContext {
  pageType: HelpcenterPageType;
  article?: Article;
  category?: Category;
}

export interface MaybeAgentToolbarProps extends WithTranslation {
  locale: string;
  isExpanded: boolean;
  toggleExpansion(isExpanded: boolean): void;
  setHasToolbar(hasToolbar: boolean): void;
  preview?: boolean;
  context: AgentToolbarContext;
  topOffset: number;
}

export const _MaybeAgentToolbar: React.FC<MaybeAgentToolbarProps> = (
  props: MaybeAgentToolbarProps,
) => {
  const {
    isAgent
  } = React.useContext(HelpcenterContext);
  const [contextData, setContextData] = React.useState<ContextData>({});

  // listen to context change and fetch data accordingly
  React.useEffect(() => {
    if (props.context.article && isAgent) {
      getArticleContextData(
        props.context.article,
        props.locale
      )
        .then(articleContext => {
          setContextData({ articleContext });
        })
        .catch(e => {
          console.warn(
            'Issue with getting article context data for Agent Toolbar',
            e,
          );
        });
    } else if (props.context.category && isAgent) {
      getCategoryContextData(
        props.context.category,
        props.locale
      )
        .then(categoryContext => {
          setContextData({ categoryContext });
        })
        .catch(e => {
          console.warn(
            'Issue with getting category context data for Agent Toolbar',
            e,
          );
        });
    }
  }, [props.context, isAgent]);

  // Catch the use case of agent check finishes before preview SDK load
  React.useEffect(() => {
    if (props.preview) {
      props.setHasToolbar(false);
    }
  }, [props.preview]);

  React.useEffect(() => {
	  props.setHasToolbar(isAgent ?? false)
  }, [isAgent]);

  const toggleExpansion = () => props.toggleExpansion(!props.isExpanded);
  const visibleContext =
    visibleContextMap[props.context.pageType] || ToolbarContextSections.NONE;

  if (isAgent && !props.preview) {
    return (
      <div className='viewer-user-toolbar-container' style={{top: props.topOffset}}>
        <UserToolbar
          isExpanded={props.isExpanded}
          toggleExpansion={toggleExpansion}
          translate={props.t}
          visibleContext={visibleContext}
          contextData={contextData}
          translationPrefix="user-toolbar"
        />
      </div>
    );
  }

  return null;
};

export const MaybeAgentToolbar = withTranslation()(_MaybeAgentToolbar);
