import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { TicketStatus } from '@wix/answers-api';


export interface StatusLabelProps {
  status: TicketStatus;
}

export const labelKey = 'status-label';

const _StatusLabel = (props: StatusLabelProps & WithTranslation) => {
  return (
    <div
      data-current-status={props.status}
      className={`${labelKey} ${TicketStatus[props.status].toLowerCase()}`}
    >
      {props.t(`${labelKey}.${props.status}`)}
    </div>
  );
};

export const StatusLabel = withTranslation()(_StatusLabel);
