import * as React from 'react';
import { TicketPageComp } from './ticket-page';
import { FileUploadResponse, TicketAttachment } from '@wix/answers-api';
import { helpcenterApi } from '../../../api';
import { BiLogger } from '../../routes/bi';

const uploadBase64File = (
  base64file: any,
  captcha: string,
  uploadProgress?: (progress: number) => void,
  secured = true,
): Promise<FileUploadResponse> => {
  const onUploadProgress = ({
    loaded,
    total,
  }: {
    loaded: number;
    total: number;
  }) => {
    const percent = Math.ceil((loaded / total) * 100);
    if (uploadProgress) {
      uploadProgress(percent);
    }
  };
  return helpcenterApi.uploadFile(
    { base64file, captcha, useInvisible: true, secured },
    onUploadProgress,
  );
};

const onAttachmentUpload = (
  file: any,
  captchaToken: string = '',
  progressCb?: (precentage: number) => void,
) => {
  return uploadBase64File(file, captchaToken, progressCb).then((data: any) => ({
    name: data.filename,
    url: data.filelink,
  }));
};

const onSubmitReply = (
  ticketId: string,
  replyContent: string,
  attachments: TicketAttachment[],
  ccUserIds?: string[],
) => {
  return helpcenterApi.replyToTicket(ticketId, replyContent, attachments, ccUserIds);
};

export const TicketPageWrapper = ({
  model,
  biLogger,
}: {
  model: any;
  biLogger: BiLogger;
}) => () => {
  const timeline = model.customerPortalEnabled ? model.customerTimeline : model.timeline;
  return (
    <div className="ticket-page-wrapper">
      <TicketPageComp
        biLogger={biLogger}
        onUpload={onAttachmentUpload}
        onSubmitReply={onSubmitReply}
        timeline={timeline}
        allowedFileTypes={model.tenant.allowedFileTypes}
        customerPortalEnabled={model.customerPortalEnabled}
        sessionUser={model.sessionUser}
        brandId={model.tenant.brandId}
      />
    </div>
  );
};
