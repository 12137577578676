import { helpcenterApi } from '../../../../api';
import { ErrorType } from '../../../../common/errors/error-type';
import { RecoverableError } from '../../../../common/errors/recoverable-error';
import { createSampleTicketListModel } from '../../../preview-types';

export const ticketListSectionDataLoader = async ({
  locale,
  page,
  preview
}: {
  locale: string;
  page: number;
  preview: boolean;
}) => {
  if (preview) {
    return createSampleTicketListModel();
  }
  try {
    const companies = await helpcenterApi.getMyComapnies();

    return {
      companies,
      page,
    };
  } catch (err:any) {
    if (!Object.values(ErrorType).includes(err.response?.status)) {
      throw new RecoverableError(
        'failed to load user data, redirecting to homepage',
        'REDIRECT',
        '',
      );
    }
    throw err;
  }
};
