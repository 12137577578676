import * as React from 'react';
import ReactJsxParser from 'react-jsx-parser';
import { ParentCategoriesList } from '../../../blueprints/search-results/parents-category-list';
import {
  ArticleNavLink,
  ArticleViewer,
  CategoryNavLink,
  ContactFormWrapper,
  GenericNavLink,
  GetTextContent,
  Icon,
  LanguageSelect,
  TicketFilterMultiSelect,
  HeaderMobileMenu,
  HeaderProfileMenu,
  HeaderUserMenu,
  Search,
  LoginLink,
  ContactLink,
  MaybeCollapsible,
  Avatar,
  UserDetails,
  ArticleAuthorAvatar,
  TicketPageWrapper,
  FollowedArticles,
  StatusLabel,
  Pagination,
  TooltipComp,
  CustomerPortalTabsWrapper,
  Loader,
  TicketSearchInput,
} from '../../../components-for-sections';
import { BiLogger, createLogger } from '../../../routes/bi';
import { HelpcenterContext } from '../../../helpcenter-context';
import { TicketListNavLink } from '../../../components-for-sections/nav-links/ticket-list-nav-link';
import { TicketNavLink } from '../../../components-for-sections/nav-links/ticket-nav-link';
import { devGeneralAnswersFedEvent } from '@wix/bi-logger-wix-answers/v2';

export interface JsxParserProps {
  model: any;
  jsx: string;
  onLoad?(): void;
  strict?: boolean;
  config: any;
  components?: Record<
    string,
    | (({ model, biLogger }: { model: any; biLogger?: BiLogger }) => JSX.Element)
    | React.ComponentType<any>
  >;
  bindings?: Record<string, Function>;
}

export class JsxParser extends React.PureComponent<JsxParserProps> {
  static contextType = HelpcenterContext;
  context!: React.ContextType<typeof HelpcenterContext>;

  componentDidMount() {
    this.props.onLoad && this.props.onLoad();
  }

  TicketSearchInput = TicketSearchInput(this.props);

  render() {
    const { jsx, model, strict, config, components } = this.props;
    const { locale } = this.context;
    const tenantId = model.tenant?.id;

    const biLogger = createLogger(tenantId, locale, model.sessionUser?.id, model.customerPortalEnabled);

    // todo: filter these components per page
    const _components: Record<
      string,
      | (({ model, biLogger }: { model: any; biLogger: BiLogger }) => JSX.Element)
      | React.ComponentType<any>
    > = {
      ArticleViewer: ArticleViewer({ model, config, biLogger }),
      ArticleNavLink: ArticleNavLink({ model, biLogger }),
      CategoryNavLink: CategoryNavLink({ model, biLogger }),
      TicketNavLink: TicketNavLink({ model, biLogger }),
      GenericNavLink,
      TicketListNavLink: TicketListNavLink({ model, biLogger }),
      Search: Search({ biLogger, model }),
      Icon,
      contactFormComp: ContactFormWrapper({
        customFields: model.contactFormFields || [],
        config,
        biLogger,
        tenant: model?.tenant,
        model,
      }),
      GetTextContent,
      ParentCategoriesList: ParentCategoriesList({ model }),
      LanguageSelect: LanguageSelect({ model, biLogger }),
      HeaderMobileMenu: HeaderMobileMenu({ model, config, biLogger }),
      HeaderProfileMenu: HeaderProfileMenu({ locale }),
      HeaderUserMenu: HeaderUserMenu({ model, biLogger }),
      LoginLink: LoginLink({ locale }),
      ContactLink: ContactLink({ model, biLogger }),
      Avatar: Avatar({ model }),
      ArticleAuthorAvatar: ArticleAuthorAvatar({ model }),
      TicketPage: TicketPageWrapper({ model, biLogger }),
      MaybeCollapsible,
      FollowedArticles: FollowedArticles({ model, locale }),
      StatusLabel,
      CustomerPortalTabs: CustomerPortalTabsWrapper({ model, biLogger }),
      Pagination,
      UserDetails: UserDetails({ model }),
      Tooltip: TooltipComp,
      TicketFilterMultiSelect,
      TicketSearchInput: this.TicketSearchInput,
      Loader,
      ...components,
    };

    return (
      <ReactJsxParser
        renderInWrapper={false}
        components={_components}
        jsx={jsx}
        // the following must be true, or else the library uses Document which fails on server.
        allowUnknownElements={true}
        blacklistedAttrs={[]}
        bindings={{
          onCustomClickHandler: (event) => {
            (window as any)?.onCustomClickHandler(event);
          },
        }}
        disableKeyGeneration={true}
        onError={(err: Error) => {
          if (strict) {
            throw err;
          }
          console.error(err);
          biLogger.report(devGeneralAnswersFedEvent({ data: JSON.stringify({ jsx }), type: 'jsx-parser-error', client_id: model.sessionUser?.id , tenant_id: tenantId }))
            .catch(() => {});
        }}
      />
    );
  }
}

JsxParser.contextType = HelpcenterContext;

