import * as React from 'react';
import { classNames } from '@wix/answers-lib';
import { WithTranslation, withTranslation } from 'react-i18next';
import { HelpcenterContext } from '../../helpcenter-context';
import { LanguageSelectComp } from '../language-select/language-select';
import { GenericNavLink } from '../nav-links/generic-nav-link';
import { History } from 'history';
import { Icon, IconType } from '../icons';
import { CompanyDisplayInfo, HelpcenterHeader } from '@wix/answers-api';
import { UserInfoComp } from '../header-user-info/user-info';
import { HelpcenterUser } from '../../../common/helpcenter-types';
import { TenantInfo } from '../../../common/tenant-info.i';
import { BiLogger } from '../../routes/bi';
export const mobileMenuKey = 'header-mobile-menu';

export interface MobileMenuProps {
  config: HelpcenterHeader['config'];
  locales: string[];
  locale: string;
  history: History;
  sessionUser?: HelpcenterUser;
  contactOptionDisabled: boolean;
  contactButtonUrl: string;
  submitTicketsEnabled: boolean;
  biLogger: BiLogger;
  customerPortalEnabled: boolean;
  companies: CompanyDisplayInfo[];
  tenant: TenantInfo;
}

interface MobileMenuState {
  isMenuOpen: boolean;
  isProfileOpen: boolean;
}

class MobileMenu extends React.PureComponent<
  MobileMenuProps & WithTranslation,
  MobileMenuState
> {
  state: MobileMenuState = {
    isMenuOpen: false,
    isProfileOpen: false,
  };
  buttonRef!: HTMLButtonElement;
  langRef!: HTMLElement;
  myProfileRef!: HTMLDivElement;

  componentDidMount() {
    if (this.buttonRef) {
      this.buttonRef.addEventListener('click', this.removeFocusFromButton);
    }
    document.addEventListener('click', this.handleOuterClick);
  }
  componentWillUnmount = () => {
    if (this.buttonRef) {
      this.buttonRef.removeEventListener('click', this.removeFocusFromButton);
    }
    document.removeEventListener('click', this.handleOuterClick);
  };

  handleOuterClick = (event: any) => {
    if (
      event.target &&
      this.buttonRef &&
      !this.langRef &&
      (!this.props.customerPortalEnabled || !this.myProfileRef) &&
      !this.buttonRef.contains(event.target)
    ) {
      this.closeMenu();
    }

    if (
      (!this.props.customerPortalEnabled || this.myProfileRef) &&
      this.langRef &&
      this.buttonRef &&
      (!this.props.customerPortalEnabled || !this.myProfileRef.contains(event.target)) &&
      !this.langRef.contains(event.target) &&
      !this.buttonRef.contains(event.target)
    ) {
      this.closeMenu();
    }
  };

  removeFocusFromButton = () => {
    if (this.buttonRef && this.buttonRef.blur) {
      this.buttonRef.blur();
    }
  };

  closeMenu = () => {
    this.setState({ isMenuOpen: false });
  };

  toggleMenu = () => {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  };

  toggleProfile = () => {
    this.setState({ isProfileOpen: !this.state.isProfileOpen });
  };

  setButtonRef = (elem: HTMLButtonElement) => {
    this.buttonRef = elem;
  };

  setLangRef = (elem: HTMLElement) => {
    this.langRef = elem;
  };

  setMyProfileRef = (elem: HTMLDivElement) => {
    this.myProfileRef = elem;
  };

  setMenuSize = (elem: HTMLElement) => {
    if (elem) {
      const pageWidth =
        document.body.getBoundingClientRect().width || window.innerWidth;
      const pageHeight = window.innerHeight;
      const side = pageWidth - 22 - this.buttonRef.clientWidth;

      elem.style.height = pageHeight.toString() + 'px';
      elem.style.width = pageWidth.toString() + 'px';
      elem.style.left = `-${side}px`;
      elem.style.right = `-${side}px`;
    }
  };

  profileBody = () => this.props.sessionUser ? (
    <div
      className="my-profile-menu-wrapper"
      ref={this.setMyProfileRef}
      onClick={this.toggleProfile}
    >
      <span className="button-title">
        {this.props.t(`header-mobile-menu-profile-button`)}
        {<Icon className={classNames('profile-arrow-icon', { open: this.state.isProfileOpen })} type={IconType.SMALL_ARROW}/>}
      </span>
      {this.state.isProfileOpen ? (
        <UserInfoComp
          biLogger={this.props.biLogger}
          user={this.props.sessionUser}
          locale={this.props.locale}
          myCompanies={this.props.companies}
        />
      ) : null}
    </div>
  ) : null;

  MenuBody = () => {
    const {
      config,
      sessionUser,
      contactOptionDisabled,
      contactButtonUrl,
      submitTicketsEnabled,
      customerPortalEnabled,
      t,
    } = this.props;

    const helpcenterLink =
      config.showTitle && config.title ? (
        <GenericNavLink className="mobile-title-target" uri="/">
          <span className="helpcenter-url-title">{config.title}</span>
        </GenericNavLink>
      ) : null;
    const contactUs =
      config.showContactButton &&
      !contactOptionDisabled ?
      submitTicketsEnabled ? (
        <GenericNavLink
          className="link-button-container mobile-contact-us"
          uri={contactButtonUrl}
        >
          <div className="contact-us-button">
            <span className="button-title">
              {this.props.t('header-mobile-menu-contact-us-button')}
            </span>
          </div>
        </GenericNavLink>
      ) : (
        <a
        target="_self"
        className="contact-us-login-button"
        href={`/account/signin?redirectUrl=${encodeURIComponent(
          window.location.href,
        )}&locale=${this.props.locale}`}
      >
          <div className="contact-us-button">
            <span className="button-title">
              {this.props.t('header-mobile-menu-contact-us-button')}
            </span>
          </div>
      </a>
      ) : null;
    const langPicker =
      config.showLanguagePicker && this.props.locales.length > 1 ? (
        <div ref={this.setLangRef as any} className="mobile-lang-picker">
          <LanguageSelectComp
            mobile={true}
            locales={this.props.locales}
            history={this.props.history}
            biLogger={this.props.biLogger}
            component={'header'}
            tenant={this.props.tenant}
          />
          <Icon className="arrow-icon" type={IconType.SMALL_ARROW} />
        </div>
      ) : null;

    const loginButton = config.showLoginButton ? (
      sessionUser ? (
        <div className="mobile-login-button">
          <a
            target="_self"
            className="logout-link"
            href={`/account/logout?redirectUrl=${encodeURIComponent(
              window.location.href,
            )}&locale=${this.props.locale}`}
          >
            {this.props.t('header-logout-button-title')}
          </a>
        </div>
      ) : (
        <div className="mobile-login-button">
          <a
            className="login-link"
            href={`/account/signin?redirectUrl=${encodeURIComponent(
              window.location.href,
            )}&locale=${this.props.locale}`}
          >
            {this.props.t('header-login-button-title')}
          </a>
        </div>
      )
    ) : null;
    const customerPortalButton = sessionUser ? (
      <GenericNavLink
        className="link-button-container mobile-customer-portal"
        uri={'/account'}
      >
        <div className="customer-portal-button">
          <span className="button-title">
            {this.props.t(`header-mobile-menu-customer-portal-button`)}
          </span>
        </div>
      </GenericNavLink>
    ) : null;
    const profileButton = sessionUser ? (
      <GenericNavLink
        className="link-button-container mobile-my-profile"
        uri={'/account'}
      >
        <div className="profile-button">
          <span className="button-title">
            {this.props.t(`header-mobile-menu-profile-button`)}
          </span>
        </div>
      </GenericNavLink>
    ) : null;
    return (
      <div ref={this.setMenuSize as any} className="menu-container">
        {customerPortalEnabled ? this.profileBody() : null}
        {helpcenterLink}
        {contactUs}
        {customerPortalEnabled && customerPortalButton}
        {config.showLanguagePicker || config.showLoginButton ? (
          <div className="divider" />
        ) : null}
        {!customerPortalEnabled && profileButton}
        {langPicker}
        {loginButton}
      </div>
    );
  };

  render() {
    const { state } = this;

    return (
      <div className={mobileMenuKey}>
        <button
          ref={this.setButtonRef}
          className={classNames('mobile-menu-button', {
            close: !state.isMenuOpen,
            open: state.isMenuOpen,
          })}
          onClick={this.toggleMenu}
        >
          <span className="button-title">
            {state.isMenuOpen ? (
              <Icon className="close-menu-icon" type={IconType.X_ICON} />
            ) : (
              this.props.t(`header-menu-button-title`)
            )}
          </span>
        </button>
        {state.isMenuOpen ? this.MenuBody() : null}
      </div>
    );
  }
}

MobileMenu.contextType = HelpcenterContext;
export const HeaderMobileMenuComp = withTranslation()(MobileMenu);
