export enum HelpcenterListenersType {
  SEARCH_ENTERED_VALUE = 'search_entered_value',
  SEARCH_SEE_MORE_CLICKED = 'search_see_more_clicked',
  SEARCH_RESULT_CLICKED = 'search_result_clicked',
  SEARCH_IGNORED_RESULTS = 'search_user_ignored_results',
  SEARCH_NO_RESULTS = 'search_no_results',
}

export interface SdkOnUserEnteredSearchValueEventData {
  searchTerm: string;
  resultsCount: number;
  locale: string;
}
export interface SdkOnUserClickedOnSearchResultEventData {
  searchTerm: string;
  articleId: string;
  locale: string;
  index: number;
}
export interface SdkGeneralSearchResultsEventData {
  searchTerm: string;
  locale: string;
}
export interface UserEnteredSearchValueEvent {
  event: HelpcenterListenersType.SEARCH_ENTERED_VALUE;
  data: SdkOnUserEnteredSearchValueEventData;
}

export interface UserClickedOnSearchResultEvent {
  event: HelpcenterListenersType.SEARCH_RESULT_CLICKED;
  data: SdkOnUserClickedOnSearchResultEventData;
}

export interface UserIgnoredSearchResults {
  event: HelpcenterListenersType.SEARCH_IGNORED_RESULTS;
  data: SdkGeneralSearchResultsEventData;
}

export interface noSearchResultsEvent {
  event: HelpcenterListenersType.SEARCH_NO_RESULTS;
  data: SdkGeneralSearchResultsEventData;
}

export interface UserClickedSeeMoreSearchResultsEvent {
  event: HelpcenterListenersType.SEARCH_SEE_MORE_CLICKED;
  data: SdkGeneralSearchResultsEventData;
}

export type HelpcenterEvent =
  | UserEnteredSearchValueEvent
  | UserClickedOnSearchResultEvent
  | UserClickedSeeMoreSearchResultsEvent
  | UserIgnoredSearchResults
  | noSearchResultsEvent;
