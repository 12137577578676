import webBiLogger from '@wix/web-bi-logger';
import { publicCustomerPortalViewerPageLoadSuccess, publicPageView, publicPageViewArticle, publicPageViewCategory, publicPageViewError } from '@wix/bi-logger-wix-answers/v2';
import { ensureAnonId } from '@wix/answers-bi';
import { Category, HelpcenterPageType, Article, ArticleType, SourceType, AnalyticsLogger } from '@wix/answers-api';
import { deepSearch, getApiUrl } from '../../common/utils';
import { httpClientPost } from '../../api';

const safelyGetReferrerName = () => {
  try {
    return /referral=([^&]+)/.exec(window.location.href);
  } catch (e) {
    return null;
  }
};

const safelyGetClientId = () => {
  try {
    return ensureAnonId();
  } catch (e) {
    return 'n/a';
  }
};

const safelyGetReferrer = () => {
  try {
    return document.referrer;
  } catch (e) {
    return '';
  }
};

export const createLogger = (
  tenantId: string,
  locale: string,
  userId?: string,
  isCustomerPortalEnabled?: boolean,
) => {
  const loggerFactory = webBiLogger.factory();
  const capturedReferral = safelyGetReferrerName();

  loggerFactory.updateDefaults({
    tenant_id: tenantId,
    client_id: safelyGetClientId(),
    locale,
    user_id: userId,
    source_name: 'hc-2',
    source_type: 'hc-2',
    referral_name:
      capturedReferral && capturedReferral[1] ? capturedReferral[1] : null,
    referral_url: safelyGetReferrer(),
  });

  return loggerFactory.logger();
};

export const reportClientPageView = (
  pageType: HelpcenterPageType,
  tenantId: string,
  locale: string,
  userId?: string,
  isCustomerPortalEnabled?: boolean,
  brandId?: string,
) => {
  const logger = createLogger(tenantId, locale, userId, isCustomerPortalEnabled);
  // tslint:disable-next-line:no-floating-promises
  logger.report(publicPageView({
    page_type: pageType.toString(),
    answers_brand_id: brandId,
  }));
};
export const reportCustomerPortalPageView = (
  pageType: HelpcenterPageType,
  tenantId: string,
  locale: string,
  userId?: string,
  brandId?: string,
) => {
  const logger = createLogger(tenantId, locale, userId, true);
  // tslint:disable-next-line:no-floating-promises
  logger.report(publicCustomerPortalViewerPageLoadSuccess({
    answers_brand_id: brandId,
    section_key:
      pageType === HelpcenterPageType.REQUEST_LIST
        ? 'support_requests_list'
        : pageType === HelpcenterPageType.REQUEST_PAGE
        ? 'support_request_page'
        : 'articles_list',
  }));
};

const getCategoryById = (categoryId: string, categoryTree: Category[]) =>
  deepSearch<any, any>(
    categoryTree,
    'children',
    (cat) => cat.id === categoryId,
  );

export const reportPageViewArticle = (
  tenantId: string,
  locale: string,
  userId?: string,
  categoryTree?: Category[],
  article?: Article,
  isCustomerPortalEnabled?: boolean,
  brandId?: string
) => {
  if (article && categoryTree?.length) {
    const { id, title, type, categoryId } = article;
    const category = getCategoryById(categoryId, categoryTree);

    if (category) {
      // Report Answers BI
      AnalyticsLogger.logPageView(
        httpClientPost,
        getApiUrl,
        article.locale,
        SourceType.HELP_CENTER,
        null,
        article.id,
      );

      // Report Wix BI
      const parentCategory = getCategoryById(category.parentId, categoryTree);
      const isSubcategory = parentCategory && (!category?.children || !category?.children?.length);

      const articleBiData = {
        category_id: isSubcategory ? category.parentId : category.id,
        category_name: isSubcategory ? parentCategory.name : category.name,
        sub_category_id: isSubcategory ? category.id : undefined,
        sub_category_name: isSubcategory ? category.name : undefined,
        article_id: id,
        article_name: title,
        article_type: ArticleType[type],
        answers_brand_id: brandId,
      };

      const logger = createLogger(tenantId, locale, userId, isCustomerPortalEnabled);
      return logger.report(publicPageViewArticle(articleBiData));
    }
  }
};

export const reportCategoryPageView = (
  tenantId: string,
  locale: string,
  category?: Category,
  categoryTree?: any,
  userId?: string,
  isCustomerPortalEnabled?: boolean,
  brandId?: string,
) => {
  if (category) {
    const parentCategory = deepSearch<any, any>(
      categoryTree,
      'children',
      (cat) => cat.id === category?.parentId,
    );
    const isSubcategory =
      parentCategory && (!category?.children || !category?.children?.length);
    const categoryBiData = {
      category_id: isSubcategory ? category.parentId : category.id,
      category_name: isSubcategory ? parentCategory.name : category.name,
      sub_category_id: isSubcategory ? category.id : undefined,
      sub_category_name: isSubcategory ? category.name : undefined,
      answers_brand_id: brandId,
    };
    const logger = createLogger(tenantId, locale, userId, isCustomerPortalEnabled);
    return logger.report(publicPageViewCategory(categoryBiData));
  }
};

export const reportClientPageViewError = (
  pageType: HelpcenterPageType,
  tenantId: string,
  locale: string,
  errorReason: string,
  userId?: string,
  isCustomerPortalEnabled?: boolean,
  brandId?: string,
) => {
  const logger = createLogger(tenantId, locale, userId, isCustomerPortalEnabled);
  return logger.report(publicPageViewError({
    page_type: pageType.toString(),
    answers_brand_id: brandId,
    errorReason,
  }));
};

export type BiLogger = ReturnType<typeof createLogger>;