import { ArticleSortType } from '@wix/answers-api';
import { helpcenterApi } from '../../../api';
import { RecoverableError } from '../../../common/errors/recoverable-error';
import { SEARCH_ARTICLES_PAGE_SIZE } from '../../../common/utils';
import { ClientSafeTenant, HelpcenterData } from '../../../helpcenter-data';
import { ContentFilters } from '../../utils/sdk/domain';
import { ArticleMatch } from '../routes';

export const loadArticleData = async (
  params: ArticleMatch,
  initialData: HelpcenterData,
  sdkContentFilters?: ContentFilters
) => {
  const article = await helpcenterApi.getArticleByUri(
    params.articleUri,
    {
      params: { locale: params.locale, brandId: initialData.tenant.brandId },
    }
  );
    
  if (article.uri !== `/article/${params.articleUri}`) {
    throw new RecoverableError(
      'article redirect',
      'REDIRECT',
      `${article.uri}`,
    );
  }
  const relatedArticles = await helpcenterApi.getArticleRelationsById(
    article.id,
    {
      params: { locale: params.locale },
    }
  );
  const contentFilters = {...sdkContentFilters, categoryIds: [article.categoryId]};
  const parentCategoryArticles = (
    await helpcenterApi.searchArticles({
      brandId: initialData.tenant.brandId,
      locale: params.locale,
      ...contentFilters,
      page: 1,
      pageSize: SEARCH_ARTICLES_PAGE_SIZE,
      sortType: ArticleSortType.POSITION,
      summarize: true,
    })
  ).data;
  return {
    article,
    relatedArticles,
    parentCategoryArticles,
  };
};

const loadDataClient = (params: ArticleMatch, tenant?: ClientSafeTenant, location?: any, initialData?: any, baseUrl?: string, sdkContentFilters?: ContentFilters): Promise<any> =>
  Promise.all([loadArticleData(params, initialData, sdkContentFilters)]);

export default loadDataClient;
