import * as React from 'react';
import { classNames } from '@wix/answers-lib';
import { withTranslation, WithTranslation } from 'react-i18next';
import { GenericNavLink } from '..';
import { CompanyDisplayInfo, User } from '@wix/answers-api';
import { UserInfoComp } from '../header-user-info/user-info';
import { TenantInfo } from '../../../common/tenant-info.i';
import { BiLogger } from '../../routes/bi';
import { publicCustomerPortalViewerClientEngagement } from '@wix/bi-logger-wix-answers/v2';

interface withChildren {
  children?: any;
}

export interface UserMenuProps extends WithTranslation, withChildren {
  locale: string;
  user: User;
  myCompanies: CompanyDisplayInfo[];
  biLogger: BiLogger;
  tenant: TenantInfo;
}

export const UserMenu: React.FC<UserMenuProps> = (props: UserMenuProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const menuBodyElem = React.useRef<HTMLDivElement>(null);
  const menuButtonElem = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    document.addEventListener('click', handleOuterClick);
    return () => document.removeEventListener('click', handleOuterClick);
  }, []);

  const sendBiEvent = async () => {
    return props.biLogger.report(publicCustomerPortalViewerClientEngagement({
      engaged_component: 'header',
      engagement_name: 'click_on_user_menu',
      answers_brand_id: props.tenant.brandId,
    }));
  };

  const onToggle = async () => {
    setIsOpen(!isOpen);
    await sendBiEvent();
  };

  const closeMenu = () => setIsOpen(false);

  const handleOuterClick = (event: any) => {
    if (
      event.target &&
      !menuBodyElem.current?.contains(event.target) &&
      !menuButtonElem.current?.contains(event.target)
    ) {
      closeMenu();
    }
  };

  const menuLinks = isOpen && (
    <div className="links-wrapper">
      <div className="menu-item" onClick={closeMenu}>
        <GenericNavLink uri={'/account'} className="customer-portal-link">
          {props.t('header.user-menu.customer-portal')}
        </GenericNavLink>
      </div>
      <div className="menu-item">
        <a
          target="_self"
          className="logout-link"
          href={`/account/logout?redirectUrl=${encodeURIComponent(
            window.location.href,
          )}&locale=${props.locale}`}
        >
          {props.t('header.user-menu.logout')}
        </a>
      </div>
    </div>
  )

  const renderMenu = isOpen ? (
    <div
      className={classNames('user-menu-body', { open: isOpen })}
      ref={menuBodyElem}
    >
      <UserInfoComp
        locale={props.locale}
        biLogger={props.biLogger}
        myCompanies={props.myCompanies}
        user={props.user}
      />
      {menuLinks}
    </div>
  ) : null;
  return (
    <div className="user-menu-wrapper">
      <div
        className={classNames('user-menu-button', { open: isOpen })}
        onClick={onToggle}
        ref={menuButtonElem}
      >
        {props.children}
      </div>
      {renderMenu}
    </div>
  );
};
export const UserMenuComp = withTranslation()(UserMenu);
