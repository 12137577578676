import { TenantInfo } from './tenant-info.i';
import { HelpcenterContactSettings, ContactMethodType } from '@wix/answers-api';
import { HelpcenterUser } from './helpcenter-types';

export const getContactOptions = (
  tenant: TenantInfo,
): HelpcenterContactSettings => tenant.contactSettings;

export const contactOptionDisabled = (
  contactOption: HelpcenterContactSettings,
): boolean => !!contactOption && contactOption.contactButtonEnabled === false;

export const hasContactPage = (
  tenant: TenantInfo,
): boolean => {
  const contactOption = getContactOptions(tenant);
  return (
    !contactOptionDisabled(contactOption) &&
    contactOption.contactMethod.type === ContactMethodType.INTERNAL
  );
};
