import * as React from 'react';
import { classNames } from '@wix/answers-lib';
import { Icon, IconType } from '../icons';
import { getRequestListFilters } from '../../../common/utils';

// tslint:disable-next-line:interface-over-type-literal
export type TicketSearchInputProps = {
  onChange?(value: string): void;
  className?: string;
  placeholder?: string;
  bindings?: Record<string, Function>;
};

const _TicketSearchInput = (props: TicketSearchInputProps) => {
  const [value, setValue] = React.useState<string>(getRequestListFilters().text || '');
  const [focused, setFocused] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  const onValueChanged: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value: newValue } = e.target;
    setValue(newValue);
    if (props.bindings?.onTicketSearchInputChange) {
      props.bindings.onTicketSearchInputChange(newValue);
    }
  };
  const clearInput: React.MouseEventHandler<HTMLDivElement> = event => {
    setValue('');
    if (props.bindings?.onTicketSearchInputChange) {
      props.bindings.onTicketSearchInputChange('');
    }
    event.stopPropagation();
  };

  const focusInput = () => inputRef.current?.focus();

  return (
    <div className={classNames('ticket-search-input', props.className, { focused })} onClick={focusInput}>
      <div className='search-icon-wrapper'>
        <Icon type={IconType.LUPA} />
      </div>
      <input
        className='filter-input'
        placeholder={props.placeholder}
        value={value}
        onChange={onValueChanged}
        ref={inputRef}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {value ? (
        <div className='clear-input' onClick={clearInput}>
          <Icon className='clear-input-icon' type={IconType.X_ICON} />
        </div>
      ) : null}
    </div>
  );
};

export const TicketSearchInput = ({ bindings }: { bindings?: Record<string, Function> }) => (
  (props: TicketSearchInputProps) => <_TicketSearchInput bindings={bindings} {...props} />
)
