import * as React from 'react';

export const LoginLink = ({
  locale,
}: {
  locale: string;
}): React.FunctionComponent<{
  className?: string;
  logout?: boolean;
}> => (props) => {
  // tslint:disable-next-line:react-hooks-nesting
  const [currentUrl, setCurrentUrl] = React.useState('');
  const { children, className = '', logout } = props;

  // tslint:disable-next-line:react-hooks-nesting
  React.useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);
  const uri = `/account/${
    logout ? 'logout' : 'signin'
  }?redirectUrl=${encodeURIComponent(currentUrl)}&locale=${locale}`;

  return (
    <a className={className} href={uri}>
      {children}
    </a>
  );
};
