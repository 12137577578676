import { classNames } from '@wix/answers-lib';
import * as React from 'react';
import { useScrollPosition } from './useScrollPosition';

const _LinkList = ({ children, onRef }) => {
  const linksRef = React.useRef<any>(null);
  const [isScroll, setIsScroll] = React.useState(false);

  React.useEffect(() => {
    onRef(linksRef);
  }, []);

  useScrollPosition(
    ({ currPos }) => {
      setIsScroll(currPos.y > 0);
    },
    [isScroll],
    linksRef,
    false,
    undefined,
    linksRef,
  );

  return (
    <div
      ref={linksRef}
      className={classNames(['link-list'], { scrolled: isScroll })}
    >
      {children}
    </div>
  );
};

export const LinkList = (
  onRef: (ref: React.MutableRefObject<HTMLDivElement>) => void,
) => (props) => _LinkList({ onRef, ...props });
