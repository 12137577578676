import { classNames } from '@wix/answers-lib';
import { publicCustomerPortalViewerClientEngagement } from '@wix/bi-logger-wix-answers/v2';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { TenantInfo } from '../../../common/tenant-info.i';
import { BiLogger } from '../../routes/bi';
import { GenericNavLink } from '../nav-links';

export interface CustomerPortalTabsProps {
  selected: 'tickets' | 'articles';
  biLogger: BiLogger;
  tenant: TenantInfo
}

export const customerPortalTabsKey = 'customer-portal-tabs';

const _CustomerPortalTabs = (
  props: CustomerPortalTabsProps & WithTranslation,
) => {
  const sendBiEvent = (tab: 'support_requests' | 'followed_articles') => async () => {
    await props.biLogger.report(publicCustomerPortalViewerClientEngagement({
      engaged_component: 'Portal_navigation_tabs',
      engagement_name: `click_on_${tab}`,
      answers_brand_id: props.tenant.brandId,
    }));
  };
  return (
    <div className={customerPortalTabsKey}>
      <div className="tabs-inner">
        <GenericNavLink
          className={classNames('tab', {
            selected: props.selected === 'tickets',
          })}
          uri="/account"
          onClick={sendBiEvent('support_requests')}
        >
          {props.t('customer-portal.tabs.tickets')}
        </GenericNavLink>
        <GenericNavLink
          className={classNames('tab', {
            selected: props.selected === 'articles',
          })}
          uri="/articles"
          onClick={sendBiEvent('followed_articles')}
        >
          {props.t('customer-portal.tabs.articles')}
        </GenericNavLink>
      </div>
    </div>
  );
};

export const CustomerPortalTabsWrapper = ({
  model,
  biLogger,
}: {
  model: any;
  biLogger: BiLogger;
}) => (props) => {
  return <CustomerPortalTabs tenant={model.tenant} selected={props.selected} biLogger={biLogger} />;
};
export const CustomerPortalTabs = withTranslation()(_CustomerPortalTabs);
