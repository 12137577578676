import { Article, SearchResult } from '@wix/answers-api';
import { helpcenterApi } from '../../../../api';
import { ErrorType } from '../../../../common/errors/error-type';
import { RecoverableError } from '../../../../common/errors/recoverable-error';
import { createSampleFollowedArticlesModel } from '../../../preview-types';

export const followedArticlesSectionDataLoader = async ({
  locale,
  preview,
}: {
  locale: string;
  preview?: boolean;
}) => {
  if (preview) {
    return createSampleFollowedArticlesModel();
  }
  try {
    const articlesResults: SearchResult<Article> = await helpcenterApi.getFollowingArticles(
      {
        locale,
        page: 1,
        pageSize: 15,
      },
    );

    return {
      followingArticles: articlesResults.items,
      numPages: articlesResults.numPages,
      itemsCount: articlesResults.itemsCount,
    };
  } catch (err:any) {
    if (!Object.values(ErrorType).includes(err.response?.status)) {
      throw new RecoverableError(
        'failed to load user data, redirecting to homepage',
        'REDIRECT',
        '',
      );
    }
    throw err;
  }
};
