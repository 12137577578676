import * as React from 'react';
import { classNames } from '@wix/answers-lib';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CompanyDisplayInfo, User } from '@wix/answers-api';
import { SmallUserAvatar } from '../avatar';
import { BiLogger } from '../../routes/bi';

export interface UserInfoProps extends WithTranslation {
  locale: string;
  user: User;
  myCompanies: CompanyDisplayInfo[];
  biLogger: BiLogger;
}

export const UserInfo: React.FC<UserInfoProps> = (props: UserInfoProps) => {
  const [isShowMoreCompanies, setIsShowMoreCompanies] = React.useState(false);
  const [isShowMorePhones, setIsShowMorePhones] = React.useState(false);

  const toggleShowMoreCompanies = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setIsShowMoreCompanies(!isShowMoreCompanies);
  };
  const toggleShowMorePhones = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setIsShowMorePhones(!isShowMorePhones);
  };

  const renderUserInfo = () => {
    const allUserPhoneNumbers = props.user.phoneNumbers || [];
    const displayedPhones = isShowMorePhones
      ? allUserPhoneNumbers
      : allUserPhoneNumbers.slice(0, 5);
    const displayedCompanies = isShowMoreCompanies
      ? props.myCompanies
      : props.myCompanies.slice(0, 5);
    return (
      <div className="user-info-wrapper">
        {props.user.hasEmail && (
          <div className={classNames('info-row', 'email')}>
            <div className={classNames('label', 'email')}>
              {props.t('header.user-menu.email')}
            </div>
            <div className={classNames('content', 'email')}>
              {props.user.email}
            </div>
          </div>
        )}
        {allUserPhoneNumbers?.length ? (
          <div className={classNames('info-row', 'phone')}>
            <div className={classNames('label', 'phone')}>
            {props.t('header.user-menu.phone')}
            </div>
            <div className={classNames('content', 'phones')}>
              <div className="phone-list">
                {displayedPhones.map((p) => (
                <div key={p.number} className={classNames('content', 'phone')}>
                  {`+${p.countryCode}-${p.number}`}
                </div>
              ))}
              </div>
              {allUserPhoneNumbers.length > 5 && (
                <button className="show-more-btn phones" onClick={toggleShowMorePhones}>
                  {isShowMorePhones
                    ? props.t('header.user-menu.show-less')
                    : props.t(`header.user-menu.show-more`, {
                        count: allUserPhoneNumbers.length - 5,
                      })}
                </button>
              )}
            </div>
          </div>
        ) : null}

        {props.myCompanies.length ? (
          <div className={classNames('info-row', 'companies')}>
            <div className={classNames('label', 'companies')}>
              {props.t('header.user-menu.companies')}
            </div>
            <div className={classNames('content', 'companies')}>
              <div className="companies-list">
                {displayedCompanies.map((c) => (
                  <div className="company-name" key={c.id}>
                    {c.name}
                  </div>
                ))}
              </div>
              {props.myCompanies.length > 5 && (
                <button className="show-more-btn companies" onClick={toggleShowMoreCompanies}>
                  {isShowMoreCompanies
                    ? props.t('header.user-menu.show-less')
                    : props.t(`header.user-menu.show-more`, {
                        count: props.myCompanies.length - 5,
                      })}
                </button>
              )}
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const renderUserAvatar = () => (
    <div className="user-info-title">
      <SmallUserAvatar user={props.user} />
      <div className="user-name"> {props.user.fullName} </div>
    </div>
  );

  return (
    <div className="user-info">
      {renderUserAvatar()}
      {renderUserInfo()}
    </div>
  );
};
export const UserInfoComp = withTranslation()(UserInfo);
