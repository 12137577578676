import { HelpcenterPageType } from '@wix/answers-api';
import { TenantInfo } from '../../../common/tenant-info.i';
import { SeoContentPage } from '../../../server/og-types';

const pageTypePlaceholderKeys = {
  home: {
    COMPANY_NAME: 'COMPANY_NAME',
    CANONICAL_URL: 'CANONICAL_URL',
    BRAND_NAME: 'BRAND_NAME'
  },
  search: {
    COMPANY_NAME: 'COMPANY_NAME',
    CANONICAL_URL: 'CANONICAL_URL',
    BRAND_NAME: 'BRAND_NAME',
    SEARCH_TERM: 'SEARCH_TERM'
  },
  article: {
    COMPANY_NAME: 'COMPANY_NAME',
    CANONICAL_URL: 'CANONICAL_URL',
    BRAND_NAME: 'BRAND_NAME',
    ARTICLE_TITLE: 'ARTICLE_TITLE',
    ARTICLE_PREVIEW: 'ARTICLE_PREVIEW',
    CATEGORY_TITLE: 'CATEGORY_TITLE',
    PARENT_CATEGORY_TITLE: 'PARENT_CATEGORY_TITLE'
  },
  categories: {
    COMPANY_NAME: 'COMPANY_NAME',
    CANONICAL_URL: 'CANONICAL_URL',
    BRAND_NAME: 'BRAND_NAME',
    CATEGORY_TITLE: 'CATEGORY_TITLE',
    PARENT_CATEGORY_TITLE: 'PARENT_CATEGORY_TITLE'
  },
  contact: {
    COMPANY_NAME: 'COMPANY_NAME',
    CANONICAL_URL: 'CANONICAL_URL',
    BRAND_NAME: 'BRAND_NAME',
  },
};

const pagePlaceholderKeys = {
  [HelpcenterPageType.HOMEPAGE]: pageTypePlaceholderKeys.home,
  [HelpcenterPageType.SEARCH_RESULTS]: pageTypePlaceholderKeys.search,
  [HelpcenterPageType.ARTICLE]: pageTypePlaceholderKeys.article,
  [HelpcenterPageType.ARTICLE_FEATURE_REQUEST]: pageTypePlaceholderKeys.article,
  [HelpcenterPageType.ARTICLE_KNOWN_ISSUE]: pageTypePlaceholderKeys.article,
  [HelpcenterPageType.CATEGORY]: pageTypePlaceholderKeys.categories,
  [HelpcenterPageType.SUB_CATEGORIES]: pageTypePlaceholderKeys.categories,
  [HelpcenterPageType.CATEGORIES]: pageTypePlaceholderKeys.categories,
  [HelpcenterPageType.CONTACT]: pageTypePlaceholderKeys.contact,
};

type Placeholders = Record<string, string>;

interface SeoManagerProps {
    page: HelpcenterPageType;
    tenant: TenantInfo;
    placeholders?: Placeholders;
    defaultTitle?: string;
    defaultDescription?: string;
}
const resolveSeoContentPageTags = (props: SeoManagerProps) => {
    const emptyPlaceholders = {
        COMPANY_NAME: props.tenant.title,
        CANONICAL_URL: '',
        ARTICLE_TITLE: '',
        ARTICLE_PREVIEW: '',
        CATEGORY_TITLE: '',
        PARENT_CATEGORY_TITLE: '',
        SEARCH_TERM: '',
        BRAND_NAME: props.tenant.brandName
    }
    const pagePlaceholdersValues = props.placeholders ? {...emptyPlaceholders, ...props.placeholders} : emptyPlaceholders;
    const pageKeys = pagePlaceholderKeys[props.page] ? Object.keys(pagePlaceholderKeys[props.page]) : [];

    const replacePlaceholders = (str: string, key: string, val: string) => {
        return str.replace(new RegExp('\\$' + key, 'g'), val);
    };

    const replacePagePlaceholders = (stringWithPlaceholders?: string) => {
      let stringPlaceholders = stringWithPlaceholders || '';
      stringWithPlaceholders && pageKeys.forEach(key => stringPlaceholders = replacePlaceholders(stringPlaceholders, key, pagePlaceholdersValues[key]));
      return stringPlaceholders;
    };

    const pageSeoContent = (() => {
      switch(props.page) {
        case HelpcenterPageType.HOMEPAGE:
          return props.tenant.seoContent[SeoContentPage.HOME];
        case HelpcenterPageType.CATEGORY:
          return props.tenant.seoContent[SeoContentPage.CATEGORY];
        case HelpcenterPageType.SUB_CATEGORIES:
          return props.tenant.seoContent[SeoContentPage.CATEGORY];
        case HelpcenterPageType.CATEGORIES:
          return props.tenant.seoContent[SeoContentPage.CATEGORY];
        case HelpcenterPageType.ARTICLE:
          return props.tenant.seoContent[SeoContentPage.ARTICLE];
        case HelpcenterPageType.ARTICLE_FEATURE_REQUEST:
          return props.tenant.seoContent[SeoContentPage.ARTICLE];
        case HelpcenterPageType.ARTICLE_KNOWN_ISSUE:
          return props.tenant.seoContent[SeoContentPage.ARTICLE];
        case HelpcenterPageType.SEARCH_RESULTS:
          return props.tenant.seoContent[SeoContentPage.SEARCH_RESULTS];
        case HelpcenterPageType.CONTACT:
          return props.tenant.seoContent[SeoContentPage.CONTACT];
        default:
          return null;
      }
    })();
    const robotsString = pageSeoContent &&
        Object.keys(pageSeoContent?.robots)
        .filter(r => pageSeoContent?.robots[r])
        .map((r) => r.toLowerCase())
        .toString();
    return {
      title: replacePagePlaceholders(pageSeoContent?.title) || props.defaultTitle || '',
      description: replacePagePlaceholders(pageSeoContent?.description) || props.defaultDescription || '',
      robots: robotsString || '',
    }
  }

class PageSeoManager {
  tenant?: TenantInfo;
  page?: HelpcenterPageType;
  placeholders?: Placeholders;
  defaultTitle?: string;
  defaultDescription?: string;

  setTenant(tenant: TenantInfo){
    this.tenant = tenant;
    return this;
  }
  setPage(page: HelpcenterPageType){
    this.page = page;
    return this;
  }
  setPlaceholders(placeholders: Placeholders){
    this.placeholders = placeholders;
    return this;
  }
  setDefaultTitle(defaultTitle: string){
    this.defaultTitle = defaultTitle;
    return this;
  }
  setDefaultDescription(defaultDescription: string){
    this.defaultDescription = defaultDescription;
    return this;
  }
  getTags() {
    if (this.tenant && this.page !== undefined){
      const metaTags = resolveSeoContentPageTags({page: this.page, tenant: this.tenant, placeholders: this.placeholders, defaultTitle: this.defaultTitle, defaultDescription: this.defaultDescription});
      return { metaTitle: metaTags.title, metaDescription: metaTags.description, metaRobots: metaTags.robots };
    }
    throw new Error('Failed to set seo meta-tags : tenant or page are not defined')
  }
}

const seoManager = new PageSeoManager();

export { seoManager };
