import { classNames } from '@wix/answers-lib';
import * as React from 'react';
import { IconType, Icon } from '../icons';

export const paginationKey = 'pagination';

export interface PaginationProps {
  page: number;
  numPages: number;
  onClick(page: number): void;
}

export class _Pagination extends React.PureComponent<PaginationProps> {
  setGetVisiblePages = () => {
    const page = this.props.page;
    const numPages = this.props.numPages;
    const lowerBound = page - 2;
    const upperBound: number = page + 2;
    const rightPadding = lowerBound < 1 ? Math.abs(lowerBound) + 1 : 0;
    const leftPadding = upperBound > numPages ? upperBound - numPages : 0;

    const visiblePages = [] as number[];

    for (
      let i = lowerBound - leftPadding;
      i <= upperBound + rightPadding;
      i++
    ) {
      if (i > 0 && i <= numPages) {
        visiblePages.push(i);
      }
    }
    return visiblePages;
  };

  goToNextPage = () => {
    const page = this.props.page;
    const numPages = this.props.numPages;
    if (page < numPages) {
      return this.props.onClick(page + 1);
    }
    return this.props.onClick(page);
  };

  goToPreviousPage = () => {
    const page = this.props.page;
    if (page > 1) {
      return this.props.onClick(page - 1);
    }
    return this.props.onClick(page);
  };

  getPageNumCompByPageNum = (page: number) => {
    const selectedPage = this.props.page;

    const onClick = () => {
      return this.props.onClick(page);
    };
    return (
      <span
        key={page}
        onClick={onClick}
        className={classNames(`page page-${page}`, {
          current: selectedPage === page,
        })}
      >
        {page}
      </span>
    );
  };

  render() {
    const numPages = this.props.numPages;
    const currentPage = this.props.page;

    const visiblePages = this.setGetVisiblePages();
    const forceShowFirstPage = visiblePages.indexOf(1) === -1;
    const forceShowLastPage = visiblePages.indexOf(numPages) === -1;
    let paginationComp;
    if (numPages > 1) {
      const paginationFirstPage = forceShowFirstPage
        ? this.getPageNumCompByPageNum(1)
        : '';
      const firstDivider =
        forceShowFirstPage && visiblePages[0] - 1 !== 1 ? (
          <div className="divider">. . .</div>
        ) : (
          ''
        );
      const lastDivider =
        forceShowLastPage &&
        visiblePages[visiblePages.length - 1] + 1 !== numPages ? (
          <div className="divider">. . .</div>
        ) : (
          ''
        );
      const paginationLastPage = forceShowLastPage
        ? this.getPageNumCompByPageNum(numPages)
        : '';
      const paginationPages = visiblePages.map((p) => {
        return this.getPageNumCompByPageNum(p);
      });

      const prevArrowClass = classNames('arrow-wrapper prev-arrow-wrapper');
      const nextArrowClass = classNames('arrow-wrapper next-arrow-wrapper');
      
      const prevArrow= (
        <div className={prevArrowClass} onClick={currentPage > 1 ? this.goToPreviousPage : undefined}>
          <Icon className='prev-arrow' type={IconType.ARROW} />
        </div>
      );
      const nextArrow= (
        <div className={nextArrowClass} onClick={currentPage < numPages ? this.goToNextPage : undefined}>
          <Icon className='next-arrow' type={IconType.ARROW} />
        </div>
      );

      const maybePrevArrow = currentPage !== 1 ? prevArrow : null;
      const maybeNextArrow = currentPage !== numPages ? nextArrow : null;

      paginationComp = (
        <section className={'pagination'}>
          {maybePrevArrow}
          {paginationFirstPage}
          {firstDivider}
          {paginationPages}
          {lastDivider}
          {paginationLastPage}
          {maybeNextArrow}
        </section>
      );
    } else {
      paginationComp = null;
    }

    return paginationComp;
  }
}

export const Pagination = _Pagination;
