import * as React from 'react';

export const Loader = (props: { className: string }) => {
	const classNames = `loader ${props.className}`;

	return (
		<div className={classNames}>
			<div className='bounce1' />
			<div className='bounce2' />
			<div className='bounce3' />
		</div>
	);
};
