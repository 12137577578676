import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Icon, IconType } from '../icons';
import { Article } from '@wix/answers-api';
import { GenericNavLink } from '../nav-links';
import { FollowDto } from '../../../api/dto';
import { helpcenterApi } from '../../../api';
import { formatDate } from '../../section-rendering/run-time/utils/utils';
import { MaybeCollapsible } from '../collapsible';

export const followedArticlesKey = 'followed-articles-container';

export interface FollowedArticlesProps extends WithTranslation {
  articles: Article[];
  locale: string;
}
export const FollowedArticles: React.FC<FollowedArticlesProps> = (
  props: FollowedArticlesProps,
) => {
  const [articles, setArticles] = React.useState(props.articles);
  const onUnfollow = (articleId: string) => async () => {
    const dto: FollowDto = {
      id: articleId,
      locale: props.locale,
    };
    setArticles(prevArticles => prevArticles.filter(a => a.id !== articleId));
    await helpcenterApi.unfollowArticle(dto).catch(e => {
      console.warn('Issue with unfollowing article', e);
      setArticles(props.articles);
    });
  };
  const followedArticlesList = articles.map(article => (
    <div className="article-item" key={article.id}>
      <GenericNavLink uri={article.uri} className="article-item-link">
        <div className="article-container">
          <div className="article-info">
            {(article.type === 110 || article.type === 120) && (
              <div className="article-type">
                <span
                  className={`type-container type-${article.type} resolution-${article.resolution}`}
                >
                  {props.t(
                    `profile-page.followed-articles.article-type-${article.type}`,
                  )}
                </span>
              </div>
            )}
            <div className="article-title">{article.title}</div>
          </div>
          <div className="last-update-date" data-date={article.lastUpdateDate}>
            {props.t('profile-page.followed-articles.article-last-update', {
              date: formatDate(article.lastUpdateDate, props.locale),
            })}
          </div>
        </div>
      </GenericNavLink>
      <div className="cta-article">
        <button
          className="unfollow-article-button"
          onClick={onUnfollow(article.id)}
        >
          {props.t('profile-page.followed-articles.unfollow')}
        </button>
        <GenericNavLink uri={article.uri}>
          <button className="view-article-button">
            <div className="view">
              {props.t('profile-page.followed-articles.view-article')}
            </div>
            <div className="link-arrow">
              <Icon className="arrow-icon" type={IconType.NAV_ARROW} />
            </div>
          </button>
        </GenericNavLink>
      </div>
    </div>
  ));
  const renderFollowedArticles = articles.length ? (
    <div>{followedArticlesList}</div>
  ) : (
    <div className="followed-articles-empty-state">
      {props.t('profile-page.followed-articles.empty-state')}
    </div>
  );
  return (
    <MaybeCollapsible
      title={props.t('profile-page.followed-articles.title')}
      className={followedArticlesKey}
      itemsCount={articles ? articles.length : 0}
    >
      {renderFollowedArticles}
    </MaybeCollapsible>
  );
};

export const FollowedArticlesComp = withTranslation()(FollowedArticles);
