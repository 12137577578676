import { UserAvatar, AvatarUser, AvatarSizes } from '@wix/answers-ui-libby';
import * as React from 'react';
import { Article, ExtendedTicket, User } from '@wix/answers-api';
import { classNames } from '@wix/answers-lib';

const SYSTEM_USER = 'System User';
export const avatarKey = 'avatar';

const hash = (str: string) => {
	let hash = 1;
	for (var i = 0; i < str.length; i++) {
		var char = str.charCodeAt(i);
		hash = ((hash << 5) - hash) + char;
		hash = hash & hash;
	}
	return Math.abs(hash);
}

const getUserInitials = (user: AvatarUser, tenantTitle?: string) => {
  const firstName = user.firstName
    ? user.firstName === SYSTEM_USER && tenantTitle
    ? tenantTitle
    : user.firstName
    : user.fullName
    ? user.fullName.split(' ')[0]
    : '';
  const lastName = user.lastName
    ? user.lastName
    : user.fullName
    ? user.fullName.split(' ')[1]
    : '';

  let firstLetter = '';
  let secondLetter = '';
  if (firstName) {
    firstLetter = firstName.slice(0, 1).toUpperCase();
    secondLetter =
      (lastName ? lastName.slice(0, 1).toUpperCase() : '') ||
      '';
  }
  return firstLetter ? firstLetter + secondLetter : '?';
};

const getAvatarColor = (id: string): string => {
  const colors = [
    '#204C7E',
    '#FF8A65',
    '#E35351',
    '#00796B',
    '#FFA000',
    '#7C4DFF',
    '#536DFE',
    '#32D1AD',
  ];
  const colorIndex = hash(id) % colors.length;
  return colors[colorIndex];
};

const getAvatar = ({user, tenantTitle, size}: {user: AvatarUser; tenantTitle?: string; size?: AvatarSizes}) => (
  <div className={avatarKey}>
      {user.profileImage ? (
        <UserAvatar user={user} isAgent={true} size={size} />
      ) : (
        <div
          className={classNames('avatar-initials', size)}
          style={{ backgroundColor: getAvatarColor(user.id) }}
        >
          <div className={'initials'}>{getUserInitials(user, tenantTitle)}</div>
        </div>
      )}
    </div>
);
export interface AvatarFactoryProps {
  model: { sessionUser: AvatarUser };
}

export const Avatar = ({ model }: AvatarFactoryProps): React.FC => () => {
  const user = model.sessionUser;
  return getAvatar({user});
};
export interface ArticleAuthorAvatarFactoryProps {
  model: { article: Article; tenant: { title: string} };
}

export const ArticleAuthorAvatar = ({ model }: ArticleAuthorAvatarFactoryProps): React.FC => () => {
  const author = {...model.article.author, lastName: '', fullName: ''} as AvatarUser;
  return getAvatar({user: author, tenantTitle: model.tenant.title});
};

export const getTicketAvatar = (user: User, size?: AvatarSizes): JSX.Element => {
  return getAvatar({ user, size });
}

export const SmallUserAvatar = (props: {user: AvatarUser}) => {
  return getAvatar({user: props.user, size: 'xxsmall'})
};
