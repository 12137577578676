import {
  Article,
  baseSegmentBuilder,
  Category,
  HelpcenterStructure,
  rootCategoryId,
} from '@wix/answers-api';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  ComponentTypes,
  TemplateFromBlueprint,
} from '../../../common/helpcenter-types';
import { deepSearch } from '../../../common/utils';
import { CategoryArticles } from '../../../server/rpc/types/response-dto';
import { TemplateCompiler } from '../../../viewer/section-rendering/run-time/template-compiler';
import { ToolbarActions } from '../../routes/root-composer';
import { UserToolbarHeight } from '../../utils/agent-toolbar';
import { BackButtonStates, BackLink, BackLinkOnClickOptions } from './BackLink';
import { LinkList } from './LinkList';
import { CloseButton } from './close-button';
import { AGENT_DISABLED_NOTIFICATION_HEIGHT } from '../../utils/disabled-helpcenter-notification';

type NavBarProps = {
  template: TemplateFromBlueprint;
  helpcenterStructure: HelpcenterStructure;
  model: any;
  toolbarActions?: ToolbarActions;
  isOpen: boolean;
  isMobile: boolean;
  onCloseClick(): void;
} & WithTranslation;

function useWindowSize() {
  const isSSR = typeof window === 'undefined';

  const [windowSize, setWindowSize] = React.useState({
    width: isSSR ? 1200 : window.innerWidth,
    height: isSSR ? 800 : window.innerHeight,
  });

  function changeWindowSize() {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  }

  React.useEffect(() => {
    window.addEventListener('resize', changeWindowSize);

    return () => {
      window.removeEventListener('resize', changeWindowSize);
    };
  }, []);

  return windowSize;
}

export const _NavBar = React.memo((props: NavBarProps) => {
  const { categoryTree, category: categoryInPage, article } = props.model;
  const { isOpen, onCloseClick, isMobile } = props;

  const findCategory = (parentId?: string) => {
    if (parentId && parentId !== rootCategoryId) {
      return deepSearch<Category, any>(
        categoryTree,
        'children',
        (item) => item.id === parentId,
      );
    }
  };

  const [selectedCategory, setSelectedCategory] = React.useState<
    Category | undefined
  >(findCategory(article?.categoryId || categoryInPage?.parentId));

  const getSelectedCategoryArticles = () => {
    if (
      selectedCategory &&
      (!selectedCategory.children || selectedCategory.children.length === 0)
    ) {
      const categoriesArticles = props.model
        .parentCategoryArticles as CategoryArticles[];
      return categoriesArticles;
    }
  };

  React.useEffect(() => {
    setSelectedCategory(
      findCategory(article?.categoryId || categoryInPage?.parentId),
    );
  }, [categoryInPage, article]);

  const windowSize = useWindowSize();

  const fixHeight = (linksRef: React.MutableRefObject<HTMLDivElement>) => {
    const header =
      document.querySelector('.header') ||
      document.querySelector('.ans-header');
    const breadcrumbs = document.querySelector('.breadcrumbs');
    const isFloating = windowSize.width < 1170;
    const breadCrumbsHeight =
      !isMobile && !isFloating && breadcrumbs ? breadcrumbs.clientHeight : 0;
    const headerHeight = header ? header.clientHeight : 0;
    const disabledAgentNotification = document.querySelector('.disabled-helpcneter-agent-notification') ? AGENT_DISABLED_NOTIFICATION_HEIGHT : 0;
    const toolbarAddition = props.toolbarActions?.hasToolbar
      ? props.toolbarActions.isExpanded
        ? UserToolbarHeight.EXPANDED
        : UserToolbarHeight.COLLAPSED
      : 0;

    const navElem = document.querySelector<HTMLDivElement>(
      '.navigation-sidebar',
    );

    const linksFromTop = linksRef.current.offsetTop;

    if (navElem) {
      navElem.style.top = `${
        breadCrumbsHeight + headerHeight + toolbarAddition + disabledAgentNotification
      }px`;

      navElem.style.height = `calc(100vh - ${
        headerHeight + toolbarAddition + breadCrumbsHeight + disabledAgentNotification
      }px)`;

      if (isMobile) {
        navElem.style.position = 'fixed';
        navElem.style.zIndex = '20';
      }
    }

    if (linksRef.current) {
      linksRef.current.style.maxHeight = `${
        windowSize.height -
        (breadCrumbsHeight + (!isMobile ? headerHeight : 0) + linksFromTop + toolbarAddition + disabledAgentNotification)
      }px`;
    }
  };

  const onBackClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    selectedCategoryOrState: BackButtonStates | Category,
  ): Partial<BackLinkOnClickOptions> | undefined => {
    switch (selectedCategoryOrState) {
      case BackButtonStates.HOME:
        return { preventNavigation: false };
      case BackButtonStates.ALL_TOPICS:
        setSelectedCategory(undefined);
        break;
      default:
        setSelectedCategory(findCategory(selectedCategoryOrState.id));
    }
    return { preventNavigation: true };
  };

  let selectedCategoryParent: Category | undefined;
  if (selectedCategory) {
    selectedCategoryParent = findCategory(selectedCategory.parentId);
  }

  return isOpen ? (
    <TemplateCompiler
      sectionTemplate={props.template}
      baseSegment={baseSegmentBuilder()}
      model={{
        ...props.model,
        categories: selectedCategory?.children || categoryTree,
        selectedCategory,
        selectedCategoryArticles: getSelectedCategoryArticles(),
      }}
      segmentConfig={{}}
      segmentType={ComponentTypes.NavSidebar}
      components={{
        BackLink: BackLink({
          selectedCategory,
          model: { ...props.model },
          onClick: onBackClick,
          t: (s) => props.t(`sidebar-navigation.${s}`),
        }),
        LinkList: LinkList(fixHeight),
        CloseButton: CloseButton({ onClick: onCloseClick }),
      }}
    />
  ) : null;
});

export const NavSidebar = withTranslation()(_NavBar);
