import { Article, ArticleType } from '@wix/answers-api';
import { CategoryMatch } from '../viewer/routes/routes';

export const createCategoryUri = (categoryMatch: CategoryMatch) =>
  [
    categoryMatch.categoryUri0,
    categoryMatch.categoryUri1,
    categoryMatch.categoryUri2,
    categoryMatch.categoryUri3,
    categoryMatch.categoryUri4,
  ]
    .filter((i) => !!i)
    .join('/');

export const groupByArticleType = (
  articlesList: Article[],
): {
  articles: Article[];
  featureRequests: Article[];
  knownIssues: Article[];
} => {
  return {
    articles: articlesList
      .filter((article) => article.type === ArticleType.ARTICLE)
      .sort(sortByPosition),
    featureRequests: articlesList
      .filter((article) => article.type === ArticleType.FEATURE_REQUEST)
      .sort(sortByPosition),
    knownIssues: articlesList
      .filter((article) => article.type === ArticleType.KNOWN_ISSUE)
      .sort(sortByPosition),
  };
};

const sortByPosition = (i, j) => i.position - j.position;
