import { helpcenterApi } from '../../../../api';
import { ErrorType } from '../../../../common/errors/error-type';
import { RecoverableError } from '../../../../common/errors/recoverable-error';

export const ticketSectionDataLoader = async ({ id }: { id: string }) => {
  try {
    const ticket = await helpcenterApi.getTicket(id);
    const timeline = await helpcenterApi.getTicketUserTimeline(id);
    const companies = await helpcenterApi.getMyComapnies();
    return {
      ticket,
      timeline,
      companies,
      customerPortalEnabled: false,
    };
  } catch (err:any) {
    if (Object.values(ErrorType).includes(err.response?.status)) {
      throw err;
    }
    if (err.response.status === 400) {
      const error = {...err, response: {status: ErrorType.PAGE_NOT_FOUND}}
      throw error;
    }
    throw new RecoverableError(
      'failed to load user data, redirecting to homepage',
      'REDIRECT',
      '',
    );
  }
};
