import { helpcenterApi } from '../../../../api';
import { ErrorType } from '../../../../common/errors/error-type';
import { RecoverableError } from '../../../../common/errors/recoverable-error';

export const profileSectionDataLoader = async ({
  locale,
}: {
  locale: string;
}) => {
  try {
    const { items: tickets } = await helpcenterApi.getMyTickets({
      locale,
      page: 1,
      pageSize: 100, // will need to handle past 100 tickets
    });
    const articlesResults = await helpcenterApi.getFollowingArticles({
      locale,
      page: 1,
      pageSize: 100, // will need to handle past 100 articles
    });

    return {
      tickets,
      followingArticles: articlesResults.items,
    };
  } catch (err) {
    if (!Object.values(ErrorType).includes((err as any).response?.status)) {
      throw new RecoverableError(
        'failed to load user data, redirecting to homepage',
        'REDIRECT',
        '',
      );
    }
    throw err;
  }
};
